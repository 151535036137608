.form-select {
    position: relative;
    width: 100%;
}

.form-select-arrow-svg {
    min-width: 16px;
    min-height: 16px;
    fill: #000;
    transition: transform 0.2s ease;

    .dropdown--opened .form-select {
        transform: rotate(180deg);
    }
}

.form-select__content {
    margin-top: 5px;
    background-color: $white;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
    border-radius: 12px;
    border: 1px solid $border-color;
    outline: none;
    box-shadow: inset 100px 100px 100px 100px $white;
}

.form-select-item {
    padding: 12px 24px;
    cursor: pointer;
    color: #000;
    width: 100%;
    text-align: left;

    @media screen and (max-width: 1600px) {
        padding: 8px 16px;
    }
}

.form-select__list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.form-select-btn-text {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 290px;
    white-space: nowrap;

    @media screen and (max-width: 1600px) {
        max-width: 190px;
    }
}

.form-select-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    border-radius: 12px;
    padding: 24px;
    border: 1px solid $border-color;
    outline: none;
    box-shadow: inset 100px 100px 100px 100px $white;
    text-align: left;
    gap: 10px;
    transition:
        border-color 0.3s ease,
        opacity 0.3s ease;
    text-overflow: ellipsis;
    overflow: hidden;
    opacity: 1;

    @media screen and (max-width: 1600px) {
        padding: 16px;
    }

    @include hover {
        border-color: $dark;
    }

    &:focus {
        border-color: $dark;
    }

    @include hover {
        border-color: #000;
    }

    &:focus {
        outline: none;
        border-color: #000;
    }

    &.not-selected {
        opacity: 0.5;
    }
}

.form-select__content {
    overflow: hidden;
}

.form-select__content-wrapper {
    max-height: 350px;
    overflow-y: auto;
    @include small-scrollbar();

    @include mobile {
        max-height: 200px;
    }
}

.form-select__list-item {
    transition: background-color 0.3s ease;

    @include hover {
        background-color: $gray-3;
    }

    &:first-child {
        padding-top: 12px;

        @media screen and (max-width: 1600px) {
            padding-top: 8px;
        }
    }

    &:last-child {
        padding-bottom: 12px;

        @media screen and (max-width: 1600px) {
            padding-bottom: 8px;
        }
    }
}
