.text-with-image__section {
    background-color: #deeffa;
    padding-block: var(--padding-section);
}

.text-with-image__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include tablet {
        flex-direction: column;
    }
}

.text-with-image__block {
    display: flex;
    flex: 1 1 50%;
}

.text-with-image__block-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include tablet {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.text-with-image__block-left__wrapper {
    max-width: 90%;
    max-height: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include tablet {
        max-width: 100%;
        max-height: 100%;
        margin-bottom: 80px;
        display: flex;
        flex-direction: row;
        gap: 30px;
    }

    @include mobile {
        flex-direction: column;
        gap: 0;
        margin-bottom: 44px;
    }
}

.text-with-image__block-top {
    @include tablet {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 30px;
    }

    @include mobile {
        flex-direction: column;
        gap: 0;
    }
}

.text-with-image__block-bottom {
    @include tablet {
        margin-top: auto;
        margin-left: auto;
        width: 50%;
        padding-right: 10%;
    }

    @include mobile {
        @include recalculate-grid(4);
        @include grid-columns(3);
        padding-right: 5%;
        margin-left: 0;
        margin-top: 25px;
    }
}

.text-with-image__title {
    margin-bottom: 60px;

    @include small-laptop {
        margin-bottom: 48px;
    }

    @include tablet {
        width: 100%;
        margin-bottom: 0;
    }

    @include mobile {
        margin-bottom: 54px;
        width: 100%;
        height: 100%;
    }
}

.text-with-image__text {
    @include grid-columns(4);

    @include tablet {
        width: 100%;
    }

    @include mobile {
        width: 100%;
        padding-right: 0;
    }
}

.text-with-image__description {
    color: $primary;
    @include grid-columns(3);

    @include tablet {
        width: 100%;
    }
}

.text-with-image__block--right {
    border-radius: 18px;
    overflow: hidden;
}

.text-with-image__img-wrapper {
    --aspect-ratio: calc(960 / 580);
}

.text-with-image__block-bottom {
    @include tablet {
        width: 100%;
    }
}
