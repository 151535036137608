.team__item {
    display: flex;
    gap: var(--grid-gap);
    @include grid-columns(4);

    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

.team__item-pic {
    border-radius: 18px;
    overflow: hidden;
    position: relative;
    width: 210px;
    height: 210px;
    flex-shrink: 0;
    background: linear-gradient(180deg, #f2f2f2 21.59%, #dfe1e3 100%);

    @media screen and (max-width: 1366px) {
        border-radius: 12px;
    }

    @include tablet {
        width: 140px;
        height: 140px;
    }
}

.team__item-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.team__item-info__block {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.team__item-position,
.team__item-title {
    opacity: 0.4;
}
