@mixin pagination-dark {
    color: $dark;

    .slider-next-btn,
    .slider-prev-btn {
        border-color: $dark;

        path {
            transition: fill 0.3s ease;

            fill: $dark;
        }

        @include hover {
            background-color: $dark;

            path {
                fill: $white;
            }

            &:active {
                transform: scale(0.95);
            }
        }
    }
}

@mixin pagination-light {
    color: $white;

    .slider-next-btn,
    .slider-prev-btn {
        border-color: $white;

        path {
            transition: fill 0.3s ease;

            fill: $white;
        }

        @include hover {
            background-color: $white;

            path {
                fill: $dark;
            }

            &:active {
                transform: scale(0.95);
            }
        }
    }
}

.pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 27px;

    @include mobile {
        gap: 10px;
        justify-content: space-between;
    }
}

.dots {
    overflow: hidden;
    position: relative;
    width: 300px;
    height: 10px;

    @media screen and (max-width: 1366px) {
        width: 230px;
    }

    @include mobile {
        width: 220px;
    }
}

.dot {
    position: absolute;
    left: 49%;
    top: 50%;
    transform: translateX(-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: currentColor;
    transition:
        transform 0.65s easeInOutCubic,
        opacity 0.3s ease-out;
}

.slider-next-btn,
.slider-prev-btn {
    --size: 48px;
    width: var(--size);
    height: var(--size);
    border: 1px solid currentColor;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:
        background-color 0.3s ease,
        transform 0.2s ease;
    cursor: pointer;

    @include tablet {
        --size: 30px;

        svg {
            width: 12px;
        }
    }

    @include hover {
        background-color: $white;

        path {
            fill: $dark-blue;
        }

        &:active {
            transform: scale(0.95);
        }
    }

    path {
        transition: fill 0.3s ease;

        fill: currentColor;
    }

    &.swiper-button-disabled {
        pointer-events: none;
        opacity: 0.6;
    }
}

.slider-prev-btn {
    svg {
        transform: rotate(180deg);
    }
}
