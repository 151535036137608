.download-button {
    display: flex;
    gap: 40px;
    padding: 17px;
    border-radius: 18px;
    border: 1px solid #dcdcdd;
    align-items: center;
    cursor: pointer;
    transition: color 0.3s ease;

    width: fit-content;

    @include tablet {
        gap: 22px;
    }

    @include mobile {
        padding: 12px;
        width: auto;

        .download-svg {
            width: 12px;
            height: 12px;
        }
    }

    .download-svg {
        path {
            transition: stroke 0.3s ease;
        }
    }

    .pdf_svg__pdf-text {
        transition: fill 0.3s ease;
        stroke-width: 0.5px;
    }

    .pdf_svg__pdf-icon {
        transition: stroke 0.3s ease;
    }

    @include hover {
        color: $primary;

        .download-svg {
            path {
                stroke: $primary;
            }
        }

        .pdf_svg__pdf-text {
            fill: $primary;
        }

        .pdf_svg__pdf-icon {
            stroke: $primary;
        }
    }

    path {
        width: 100%;
    }
}

.download-button__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
}

.download-button__left {
    display: flex;
    gap: 11px;
    align-items: center;

    svg {
        @include mobile {
            width: 22px;
        }
    }
}
