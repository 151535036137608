.loader-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #21212322;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}
