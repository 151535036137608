.tarif-section-wrapper {
    min-height: 100vh;
    min-height: 100svh;
    width: 100%;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $white;
    padding-top: calc(var(--header-height) + 80px);
    gap: 70px;
    color: $dark;
    position: relative;

    @media screen and (max-width: 1366px) {
        gap: 40px;
    }

    @include tablet {
        min-height: unset;
        gap: 80px;
        padding-top: 112px;
        background-color: $white;
    }

    @include mobile {
        padding-top: 80px;
    }
}

.tarif-top {
    display: flex;
    justify-content: space-between;

    @include mobile {
        flex-direction: column;
        position: relative;
        gap: 24px;
    }
}

.tarif-bottom {
    display: flex;
    flex-direction: row;

    @include tablet {
        flex-wrap: wrap;
    }
}

.tarif-title {
    @include grid-columns(2, max-width);

    @include tablet {
        @include grid-columns(3, max-width);
    }

    @include mobile {
        max-width: 100%;
    }
}

.tarif-description-block {
    display: flex;
    align-items: end;
    gap: 85px;

    @include tablet {
        flex-direction: column;
        align-items: start;
        gap: 40px;
    }

    @include mobile {
        gap: 30px;
    }
}

.tarif-description {
    max-width: 350px;
}

.tarif-card-btn.btn {
    width: fit-content;
    position: absolute;
    bottom: -50px;
    left: 0;
    transform: translateY(100%);
    opacity: 0;

    @include tablet {
        opacity: 1;
        transform: translateY(0);
        position: relative;
        bottom: unset;

        &::after {
            width: 22px;
            height: 22px;
        }

        svg {
            transform: translate(-112%, -50%);
            width: 10px;
        }
    }
}

.tarif-card {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 60vh;
    min-height: 60svh;
    width: calc(100% / 3);
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease;
    background-color: $blue;
    padding: 65px 70px;
    overflow: hidden;
    align-items: flex-start;

    &:nth-child(1) {
        background-color: $gray-2;
        color: $dark;
    }

    &:nth-child(2) {
        background-color: $light-blue;
        color: $dark;
    }

    &:nth-child(3) {
        background-color: $blue;
        color: $white;
    }

    &:nth-child(4) {
        background-color: $dark-blue;
        color: $white;
    }

    @media screen and (max-width: 1366px) {
        padding: 38px 34px;
    }

    @include tablet {
        min-height: 360px;
    }

    @include mobile {
        width: 100%;
        padding: 24px 16px;
        min-height: 226px;
    }

    path {
        transition: fill 0.3s ease;

        fill: $primary;
    }

    @include hover {
        @media screen and (min-width: 1201px) {
            .tarif-card-btn.btn {
                opacity: 1;
            }

            .tarif-card-bottom {
                transform: translateY(-100px);
            }
        }
    }
}

.tarif-card-top {
    width: 100%;

    &:not(:last-child) {
        margin-bottom: 20px;
    }
}

.tarif-card-bottom {
    display: flex;
    flex-direction: column;
    gap: 50px;
    position: relative;
    transition:
        opacity 0.3s ease,
        transform 0.3s ease;

    @include tablet {
        transform: translateY(0);
        gap: 36px;
    }
    @include mobile {
        gap: 24px;
    }
}

.tarif-card-description {
    max-width: Min(342px, 30ch);

    @include mobile {
        max-width: 310px;
    }
}
