.community-detail-page {
    .header.header-theme--dark {
        background-color: #fff;
    }

    .page-welcome__title {
        @include grid-columns(7);
        text-wrap: balance;

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }

    .slider-prev-btn,
    .slider-next-btn {
        border-color: currentColor;

        @include hover {
            border-color: #fff;
        }

        svg {
            [stroke]:not([stroke='none']) {
                stroke: currentColor;
            }

            [fill]:not([fill='none']) {
                fill: currentColor;
            }
        }
    }

    .team-section {
        background-color: $light;
        padding-top: 182px;
        padding-bottom: 90px;

        @media screen and (max-width: 1366px) {
            padding-top: 118px;
            padding-bottom: 60px;
        }

        @include tablet {
            padding-top: 108px;
            padding-bottom: 111px;
            display: grid;
            grid-gap: 0 var(--grid-gap);
            grid-template-columns: repeat(var(--grid-columns), 1fr);
            grid-template-rows: auto auto;

            .team__wrapper {
                display: contents;
            }

            .team-section__title {
                grid-column: 1 / 5;
            }

            .team-block__left {
                grid-column: 5 / -1;
            }

            .team-block__right {
                grid-column: 1 / -1;
            }
        }

        @include mobile {
            padding-top: 76px;
            padding-bottom: 80px;
        }

        @media screen and (max-width: 767px) {
            display: block;

            .team__wrapper {
                display: flex;
            }
        }
    }

    .team__item-pic {
        background: linear-gradient(180deg, #bac0cc, #bbc2cd 100%);
    }

    .how-it-was-section {
        color: $body-color;
        background-color: $light-blue;
    }

    .questions-section {
        --bg-color: #{$white};
    }

    .awords__section {
        background-color: #fff;
    }

    .selection-stages-section {
        background-color: $light;
    }

    .selection-stages-slider-slide {
        background-color: #fff;
    }

    .faq-section {
        background-color: $light-blue;
    }

    .tariffs-info-section {
        padding-top: 92px;

        @media screen and (max-width: 1366px) {
            padding-top: 62px;
        }

        @include tablet {
            padding-top: 108px;
        }

        @include mobile {
            padding-top: 76px;
        }
    }

    .tariffs-main {
        opacity: 1;
    }

    .participants-section {
        color: $dark;
    }
}

.community-event-date-location {
    color: $primary;
}

.community-detail-achors {
    &:not(:last-child) {
        margin-bottom: 40px;

        @media screen and (max-width: 1366px) {
            margin-bottom: 30px;
        }

        @include tablet {
            margin-bottom: 26px;
        }

        @include mobile {
            margin-bottom: 22px;
        }
    }
}

.program-block {
    padding-top: 147px;
    padding-bottom: 30px;

    @media screen and (max-width: 1366px) {
        padding-top: 90px;
        padding-bottom: 20px;
    }

    @include tablet {
        padding-top: 80px;
        padding-bottom: 0;
    }
}

.program-block__top {
    display: flex;
    align-items: flex-end;
    gap: var(--grid-gap);

    @media screen and (max-width: 767px) {
        align-items: flex-start;
        flex-direction: column;
        gap: 36px;
    }

    &:not(:last-child) {
        margin-bottom: 56px;

        @media screen and (max-width: 1366px) {
            margin-bottom: 36px;
        }
    }
}

.program-block__title {
    @include grid-columns(5);
    flex-shrink: 0;

    @include tablet {
        width: auto;
        flex-shrink: 1;
    }
}

.program-block__link {
    margin-left: auto;
    flex-shrink: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    @media screen and (max-width: 767px) {
        margin-left: 0;
    }
}
