.sitemap-page {
    .footer {
        display: none;
    }
}

.sitemap-page-content {
    padding-top: calc(var(--header-height) + 40px);
    padding-bottom: 100px;
}

.sitemap-page-content__title {
    margin-bottom: 60px;
}

.sitemap-block__item {
    &:not(:last-child) {
        margin-bottom: 1em;
    }

    > .sitemap-block {
        margin-top: 1em;
    }
}

.sitemap-block__item-el {
    opacity: 0.5;
}
