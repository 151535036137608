.select {
    &.dropdown--opened {
        .input-group__icon {
            transform: translate3d(0, -50%, 0) rotate(180deg);
        }

        .select-bg {
            $message-height: 17px;
            height: calc(var(--height) + #{$message-height} + var(--content-height, 100%));
        }

        .dropdown-content {
            transition-delay: 0.3s, 0.3s, 0.3s;
        }
    }

    .input-group__icon {
        transition: transform 0.3s $app-default-easing;
    }
}

.select-bg {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: height 0.4s ease-in-out;
    background-color: #fff;
    pointer-events: none;

    ~ .dropdown-btn,
    ~ .form-label,
    ~ .input-group__icon {
        z-index: 2;
    }
}

.select-toggler-wrapper {
    width: 100%;
}

.select-toggler {
    text-align: left;
    border: 0;
    background-color: transparent;
    display: flex;

    &,
    &.form-control {
        padding-right: 50px;
    }
}

.select-toggler-inner {
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: fit-content;
    margin-block: auto;

    @media screen and (max-width: 1366px) {
        margin-top: -5px;
    }

    @media screen and (max-width: 767px) {
        margin-top: 0;
    }
}

.select-options-list {
    display: flex;
    flex-direction: column;
    gap: 34px;
    padding-bottom: 20px;
}

.select-option {
    @include clickable();
    padding-inline: var(--padding-x);
    transition: opacity 0.25s ease;
    opacity: 0.6;
    width: 100%;
    text-align: left;

    @include hover {
        opacity: 1;
    }

    &.is-active {
        opacity: 1;
    }
}
