.wysiwyg {
    p:not([class]) {
        &:not(:first-child) {
            margin-top: 1.33em;
        }

        &:not(:last-child) {
            margin-bottom: 1.33em;
        }
    }

    p:empty {
        display: none;
    }

    img:not([class]) {
        @extend .img-fluid;
    }

    a:not([class]) {
        @extend .link;
        @extend .link--underlined;
        color: $primary;
    }

    table:not([class]) {
        @extend .table;
    }

    table:not([class]),
    .table-wrapper {
        &:not(:first-child) {
            margin-top: 100px;

            @media screen and (max-width: 1366px) {
                margin-top: 70px;
            }

            @include tablet {
                margin-top: 60px;
            }
        }

        &:not(:last-child) {
            margin-bottom: 100px;

            @media screen and (max-width: 1366px) {
                margin-bottom: 70px;
            }

            @include tablet {
                margin-bottom: 60px;
            }
        }
    }

    h1 {
        @extend .h2;

        &:not(:last-child) {
            margin-bottom: 1.25em;
        }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        &:not(:first-child) {
            margin-top: 2.5em;
        }

        &:not(:last-child) {
            margin-bottom: 1.25em;
        }
    }

    h2 {
        @extend .h3;
    }

    h3 {
        @extend .text-l;
        font-weight: 500;
    }

    h4 {
        @extend .text-l;
    }

    h5 {
        @extend .text-m;
    }

    h6 {
        @extend .text-s;
        font-weight: 500;
    }

    ul:not([class]) {
        @extend .list-unstyled;

        > li {
            position: relative;

            &::before {
                content: '';
                border-radius: 50%;
                background-color: $primary;
                position: absolute;
                top: calc(1.25em / 2);
                left: 0;
                transform: translate(0, -50%);
                width: 5px;
                height: 5px;
                transition: opacity $app-default-duration ease;
                pointer-events: none;
            }
        }
    }

    ol:not([class]) {
        @extend .list-unstyled;
        counter-reset: num;

        > li {
            counter-increment: num;
            position: relative;

            &::before {
                content: counter(num) '.';
                color: $primary;
                position: absolute;
                top: calc(1.25em / 2);
                left: 0;
                transform: translate(0, -50%);
                transition: opacity $app-default-duration ease;
                pointer-events: none;
            }
        }
    }

    p {
        &:not(:last-child) {
            margin-bottom: 40px;
        }
    }

    li {
        padding-left: 20px;

        &:not(:last-child) {
            margin-bottom: 1em;
        }
    }

    table {
        width: 100%;
        margin-top: 10px;
        @extend .text-s;
    }

    th,
    td {
        padding: 30px;
        text-align: left;
        border: 1px solid #ddd;

        @media screen and (max-width: 1366px) {
            padding: 20px;
        }
    }

    th {
        border-top: none;
    }

    th:first-child,
    td:first-child {
        border-left: none;
        padding-left: 0;
    }

    th:last-child,
    td:last-child {
        border-right: none;
    }

    tr:last-child {
        td {
            border-bottom: none;
        }
    }
}
