.gradient-section-wrapper {
    pointer-events: none;
    color: #fff;
    display: flex;
    flex-direction: column;
    z-index: 4;
    height: 200vh;
    height: 200svh;
    padding-top: var(--header-height);
    padding-inline: var(--offset-x);
    pointer-events: none;
    justify-content: center;

    @include tablet {
        height: 200vh;
        height: 200svh;
    }

    @include mobile {
        height: auto;
        justify-content: flex-end;
    }
}

.gradient-section-top {
    color: #fff;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    top: 100%;
    @include mobile {
        top: 105vh;
        top: 105svh;
    }
}

.gradient-section-text {
    max-width: 460px;
    margin-right: calc(200px - var(--offset-x));

    @media screen and (max-width: 1366px) {
        max-width: 300px;
        margin-right: calc(130px - var(--offset-x));
    }

    @include tablet {
        margin-right: calc(70px - var(--offset-x));
    }

    @include mobile {
        max-width: 230px;
        margin-right: 25px;
    }

    z-index: 2;
}

.gradient-section-bottom {
    display: flex;
    z-index: 2;
    justify-content: center;
    gap: 110px;
    position: sticky;
    top: 40vh;
    margin-block: auto;
    z-index: 3;

    @media screen and (max-width: 1366px) {
        gap: 80px;
    }

    @include tablet {
        gap: 0;
    }

    @include mobile {
        flex-wrap: wrap;
        position: static;
        top: auto;
        margin-top: 30vh;
        margin-bottom: 20vh;
    }
}

.number-stroke {
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #fff;
    color: transparent;
}

.gradient-section-bottom-item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    will-change: transform;

    @include mobile {
        width: 50%;
        align-items: center;
        &:nth-child(1) {
            margin-bottom: 140px;
        }
        &:nth-child(2) {
            margin-bottom: 140px;
        }
    }
}

.big-numbers-text {
    max-width: 200px;
}
