.menu-popup {
    z-index: 15;
    height: 100%;

    .popup-container {
        padding-top: 30px;
        padding-bottom: 50px;
        padding-inline: var(--offset-x);
        width: 500px;
        height: 100%;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 90px;
        z-index: 3;
        @include hidden-scrollbar();

        @media screen and (max-width: 1750px) {
            gap: 40px;
        }

        @include mobile {
            gap: 30px;
            width: 100%;
            padding: 16px;
        }
    }
}

.menu-back-blue-btn {
    display: none;

    @include mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid $white;

        svg {
            transform: rotate(180deg);
            path {
                fill: $white;
            }
        }
    }
}

.menu-popup-white-bg {
    position: absolute;
    width: 500px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $white;
    transform-origin: left;
    z-index: 2;

    @include mobile {
        width: 100%;
    }
}

.menu-popup-white-bg.menu-popup-opened {
    transition: transform var(--leave-duration) easeInOutCubic;
    left: 0;
    right: auto;
    transform: translate3d(0, 0, 0);
}

.menu-popup-white-bg.menu-popup-closed {
    transition: transform var(--leave-duration) easeInOutCubic;
    transform: translate3d(-100%, 0, 0);
}

.menu-popup-blue-section.menu-popup-opened {
    transition:
        opacity 0.3s ease,
        transform var(--leave-duration) easeInOutCubic;
    transform: translate3d(500px, 0, 0);
    opacity: 1;

    @include mobile {
        transform: translate3d(40%, 0, 0);
    }
}

.menu-popup-blue-section.menu-popup-closed {
    transition:
        opacity 0.3s ease,
        transform var(--leave-duration) easeInOutCubic;
    transform: translate3d(0%, 0, 0);
    opacity: 0;

    @include mobile {
        transform: translate3d(100%, 0, 0);
        opacity: 1;
    }
}

.menu-popup-close-btn {
    --size: 46px;
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $dark;
    border-radius: 50%;
    transition:
        background-color 0.3s ease,
        stroke 0.3s ease;

    @media screen and (max-width: 1600px) {
        --size: 36px;
    }

    @include hover {
        background-color: $dark;
        path {
            stroke: $white;
        }
    }
}

.menu-popup-logo {
    height: auto;

    @media screen and (max-width: 1600px) {
        width: 50px;
    }

    @include mobile {
        width: 35px;
    }
}

.menu-popup-items {
    display: flex;
    flex-direction: column;
    gap: 36px;

    @media screen and (max-width: 1600px) {
        gap: 26px;
    }
}

.menu-popup-link {
    position: relative;
    transition: color 0.5s ease;
    cursor: pointer;
    width: fit-content;

    &::before {
        content: '';
        height: 5px;
        width: 5px;
        background-color: $primary;
        border-radius: 100%;
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translate(-10px, -50%);
        opacity: 0;
        transition:
            transform 0.5s ease,
            opacity 0.5s ease;
    }

    @include hover {
        color: $primary;

        &::before {
            transform: translate(0, -50%);
            opacity: 1;
        }
    }
}

.menu-popup-soc-list {
    --size: 40px;

    @media screen and (max-width: 1366px) {
        --size: 28px;
    }

    @include tablet {
        --size: 34px;
    }

    .soc-list-item {
        background-color: $white;
        transition: background-color 0.3s ease;
        border: 1px solid $dark;

        svg {
            path {
                transition: fill 0.3s ease;
                fill: $dark;
            }
        }

        @include hover {
            background-color: $dark;

            svg {
                path {
                    fill: $white;
                }
            }
        }
    }
}

.menu-popup-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 3;

    .header-btn {
        display: none;

        @include mobile {
            margin-left: auto;
            margin-right: 25px;
            display: block;
        }
    }
}

.menu-popup-mobile-buttons {
    display: none;

    @include mobile {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #00000032;
        padding-bottom: 10px;
    }
}

.menu-popup-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 50px;
    z-index: 3;

    @include mobile {
        padding-top: 50px;
    }
}

.menu-popup-bottom-text {
    display: flex;
    flex-direction: column;
    gap: 33px;

    @media screen and (max-width: 1600px) {
        gap: 20px;
    }
}

.menu-text-item {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1366px) {
        max-width: 186px;
    }

    @include tablet {
        max-width: 220px;
    }
}

.menu-text-item-top {
    display: flex;
    opacity: 0.4;
}

.menu-text-item-bottom {
    display: flex;
}

.menu-popup-blue-section {
    position: absolute;
    width: 450px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $primary;
    z-index: 1;
    color: $white;
    display: flex;
    align-items: center;
    padding: var(--offset-x);
    padding-bottom: 50px;
    transform-origin: left;

    @include tablet {
        width: 50vw;
    }

    @include mobile {
        width: 100%;
        z-index: 4;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 80px;
        gap: 75px;
    }
}

.menu-popup-blue-section-links {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .menu-popup-link {
        color: $white;

        &::before {
            background-color: $white;
        }

        @include hover {
            color: $white;
        }
    }
}
