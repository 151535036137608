.questions-section {
    --padding-top: 182px;
    --bg-color: #{$light-blue};
    --gap: 15px;
    background-color: var(--bg-color);
    display: flex;
    flex-direction: column;
    padding-bottom: calc(var(--padding-top) / 2);
    padding-top: var(--padding-top);
    z-index: 4;

    @include small-laptop {
        --padding-top: 122px;
    }

    @include tablet {
        --offset-block: 60px;
        --padding-top: 122px;
        --grid-columns: 8;
    }

    @include mobile {
        --padding-top: 100px;
        --offset-block: 40px;
        --grid-columns: 4;
    }
}

.questions-form__top,
.questions-form__bot {
    display: grid;
    grid-template-columns: repeat(var(--grid-columns), 1fr);
    gap: var(--gap);

    @include mobile {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
}

.questions-form__bot {
    @include tablet {
        display: flex;
        flex-direction: column;
    }
}

.questions-form__title {
    grid-column: 1 / 5;

    @include mobile {
        width: 90%;
        margin-right: auto;
    }
}

.questions__support {
    grid-column: 9 / 13;
    gap: 20px;

    @include tablet {
        grid-column: 5 / -1;
    }

    @include mobile {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        gap: 24px;
    }
}

.questions-form {
    grid-column: 1 / 7;
}

.questions-form__top {
    margin-bottom: 124px;

    @include small-laptop {
        margin-bottom: 84px;
    }
    @include tablet {
        margin-bottom: 80px;
        align-items: flex-end;
    }
    @include mobile {
        margin-bottom: 64px;
    }
}

.questions__support-block {
    grid-column: 9 / 13;

    @include tablet {
        display: grid;
        grid-template-columns: repeat(var(--grid-columns), 1fr);
        gap: var(--gap);

        .support {
            grid-column: 1 / 4;
        }
    }

    @include mobile {
        .support {
            grid-column: 1 / -1;
        }
    }
}

.support .team__item {
    max-width: 100%;
}

.support__descr {
    max-width: 350px;
    text-wrap: balance;
}
