.car-accreditation,
.car-accreditation__empty {
    margin-bottom: 90px;

    @media screen and (max-width: 1366px) {
        margin-bottom: 60px;
    }

    @include tablet {
        margin-bottom: 110px;
    }

    @include mobile {
        margin-bottom: 80px;
    }
}

.car-accreditation__list {
    --gap: 20px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--gap);

    @include mobile {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }
}

.car-accreditation__item {
    --padding: 47px 40px;

    display: flex;
    flex-direction: column;
    padding: var(--padding);
    justify-content: space-between;
    background-color: #f2f2f2;
    color: $dark;
    border-radius: 12px;
    transition: background-color $app-default-duration $app-default-easing;

    @include hover {
        background-color: $primary;
        color: $white;
    }

    @media screen and (max-width: 1366px) {
        --padding: 27px 20px;
    }
}

.car-accreditation__price {
    margin-bottom: 108px;

    @media screen and (max-width: 1366px) {
        margin-bottom: 83px;
    }

    @include tablet {
        margin-bottom: 100px;
    }
    @include mobile {
        margin-bottom: 118px;
    }
}

.car-accreditation__description {
    max-width: 460px;
}
