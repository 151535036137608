html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: never;
    -webkit-font-size-adjust: 100%;
    font-size-adjust: 100%;
}

body {
    font-family: $font-family;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
    font-weight: 400;
}

h1,
.h1 {
    @include responsive-font-size(160px, 240px, 1280px, 1920px);
    line-height: 1;
    letter-spacing: -5px;

    @include tablet {
        @include responsive-font-size(78px, 160px, 360px, 768px);
        letter-spacing: -3.333px;
    }

    @include mobile {
        letter-spacing: -1.65px;
    }
}

h2,
.h2 {
    @include responsive-font-size(32px, 64px, 1280px, 1920px);
    letter-spacing: -0.05em;
    line-height: 1;

    @include tablet {
        @include responsive-font-size(28px, 44px, 360px, 768px);
    }
}

h3,
.h3 {
    @include responsive-font-size(34px, 51px, 1280px, 1920px);
    letter-spacing: -0.05em;
    line-height: 1;

    @include tablet {
        @include responsive-font-size(28px, 34px, 360px, 768px);
    }
}

h4,
.h4 {
    @include responsive-font-size(32px, 48px, 1280px, 1920px);
    letter-spacing: -0.05em;
    line-height: 1;

    @include tablet {
        @include responsive-font-size(24px, 32px, 360px, 768px);
        line-height: 0.81;
    }

    @include mobile {
        line-height: 0.83;
    }
}

.text-xl {
    @include responsive-font-size(22px, 34px, 1280px, 1920px);
    line-height: 0.82;
    letter-spacing: -0.05em;

    @include tablet {
        @include responsive-font-size(18px, 22px, 360px, 768px);
        line-height: 0.81;
    }

    @include mobile {
        line-height: 0.83;
    }
}

.text-l {
    @include responsive-font-size(16px, 24px, 1280px, 1920px);
    letter-spacing: -0.03em;

    @include tablet {
        @include responsive-font-size(14px, 16px, 360px, 768px);
    }
}

.text-m {
    @include responsive-font-size(14px, 21px, 1280px, 1920px);
    letter-spacing: -0.03em;

    @include tablet {
        @include responsive-font-size(13px, 14px, 360px, 768px);
    }
}

.text-s {
    @include responsive-font-size(12px, 17px, 1280px, 1920px);
    letter-spacing: -0.03em;
    line-height: 1.35;

    @include tablet {
        line-height: 1.25;
    }
}

.text-xs {
    @include responsive-font-size(11px, 15px, 1280px, 1920px);
    letter-spacing: -0.03em;
    line-height: 1.4;

    @include tablet {
        line-height: 1.27;
    }
}

.number-big {
    @include responsive-font-size(110px, 165px, 1280px, 1920px);
    line-height: 1;
    letter-spacing: -0.03em;
    font-weight: 300;
    text-transform: uppercase;

    @include tablet {
        @include responsive-font-size(90px, 110px, 360px, 768px);
    }
}

.number-small {
    @include responsive-font-size(80px, 120px, 1280px, 1920px);
    line-height: 1;
    letter-spacing: -0.03em;
    font-weight: 300;

    @include mobile {
        font-size: 40px;
    }
}
