@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fade-in {
    animation-name: fade-in;
    animation-duration: var(--animation-duration, 0.3s);
    animation-timing-function: ease;
    animation-delay: var(--animation-delay, 0s);
    animation-fill-mode: forwards;
}

@keyframes stroke-dash {
    0% {
        stroke-dashoffset: var(--path-length);
    }

    100% {
        stroke-dashoffset: 0;
    }
}
