.organize-detail-popup {
    .menu-popup-close-btn {
        position: absolute;
        right: var(--offset-x);
        top: var(--offset-x);
    }

    .popup-container {
        right: 0;
        background-color: $white;
        height: 100%;
        width: 670px;
        display: flex;
        flex-direction: column;
        padding: 70px;
        gap: 50px;
        padding-top: 120px;

        @media screen and (max-width: 1600px) {
            gap: 40px;
            padding-top: 70px;
        }

        @include tablet {
            width: 470px;
            padding: 22px;
            padding-top: 70px;
        }

        @include mobile {
            width: 100%;
            padding: 16px;
            padding-top: 70px;
            gap: 30px;
        }
    }
}

.organize-detail-popup-top {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    border-bottom: 1px solid #dcdcdd;

    @media screen and (max-width: 1600px) {
        padding-bottom: 30px;
    }
}

.organize-detail-popup-name {
    margin-top: 45px;
    margin-bottom: 24px;

    @media screen and (max-width: 1600px) {
        margin-top: 24px;
        margin-bottom: 16px;
    }

    @include tablet {
        margin-top: 20px;
    }
}

.organize-detail-popup-bottom {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.organize-detail-popup-logo-block {
    display: flex;
    gap: 20px;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;

    @include tablet {
        margin-bottom: 15px;
    }

    @include mobile {
        margin-bottom: 20px;
    }
}

.organize-detail-popup-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    @include grid-columns(4);

    max-width: 80%;
    max-height: 200px;

    width: auto;

    img {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
        min-height: 56px;
        min-width: 200px;

        @include tablet {
            min-width: 155px;
        }
    }

    @include mobile {
        max-width: 150px;
        max-height: 100px;
    }
}

.organize-detail-popup-logo-name {
    opacity: 0.6;

    text-align: center;

    @include mobile {
        margin-bottom: 16px;
    }
}

.organize-detail-popup-link {
    color: $primary;
    text-decoration: underline;
    text-align: center;
}
