.logo-slider-wrapper {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 130px;

    @include laptop {
        width: unset;
    }
}

.logo-slider-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
        object-fit: contain;
    }
}
