.team-section {
    padding-top: 100px;
    padding-bottom: 100px;

    @include tablet {
        @include recalculate-grid(8);
        padding-top: 68px;
        padding-bottom: 70px;
    }

    @include mobile {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.team-section__title {
    margin-bottom: 90px;

    @media screen and (max-width: 1366px) {
        margin-bottom: 80px;
    }

    @include tablet {
        margin-bottom: 76px;
    }

    @include mobile {
        margin-bottom: 60px;
    }
}
