* {
    @include scrollbar();
    overscroll-behavior: none;
}

html {
    background-color: $body-bg;
    color: $body-color;
    overflow: overlay;

    &.no-scroll {
        overflow: hidden;
        padding-right: var(--scrollbar-width, 0px);

        @include mobile {
            padding-right: 0;
        }
    }
}

body {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    min-height: calc(var(--vh, 1vh) * 100);
    font-family: $font-family;
    display: flex;
    flex-direction: column;
}

body {
    &:has(.anchors-wrapper) {
        .header {
            padding-bottom: 30px;
        }
    }
}

.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}

:focus {
    outline-color: currentColor;
}

:focus-visible {
    @include outline();
}

// ::selection {
//     background-color: rgba(#fff, 0.2);
// }

#__next {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.main {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    min-height: calc(var(--header-height) * 2 + 300px); // достаточно места чтобы рендерить ошибку рендеринга страницы
}

.page {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    white-space: nowrap;
    border: 0;
    clip: rect(0 0 0 0);
}

.wrapper {
    display: block;
    width: 100%;
    max-width: var(--wrapper-max-width);
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--offset-x);
    padding-right: var(--offset-x);
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.canvas-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    user-select: none;
}

.list-unstyled {
    list-style: none;
    padding: 0;
    margin: 0;
}

.pointer-events-none {
    pointer-events: none !important;
}

.pointer-events-auto {
    pointer-events: auto !important;
}

// https://nextjs.org/docs/api-reference/next/image#known-browser-bugs
@supports (font: -apple-system-body) and (-webkit-appearance: none) {
    img[loading='lazy'] {
        clip-path: inset(0.6px);
    }
}

.link {
    --bg-size: 0% 100%;
    --bg-size-hover: 100% 100%;
    --underline-height: 1px;
    background-image: linear-gradient(
        transparent calc(100% - var(--underline-height)),
        currentColor var(--underline-height)
    );
    background-repeat: no-repeat;
    background-size: var(--bg-size);
    display: inline;
    cursor: pointer;
    transition:
        background-size 0.4s $app-default-easing,
        color $app-default-duration $app-default-easing,
        opacity $app-default-duration $app-default-easing;

    @media (any-hover: hover), (hover: hover) and (pointer: fine) {
        &:hover {
            background-size: var(--bg-size-hover);
        }
    }

    &.link--underlined {
        --bg-size: 100% 100%;
        --bg-size-hover: 0% 100%;
    }
}

.webgl-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.page-wrapper {
    height: 100vh;
    height: 100svh;
    width: 100%;
    padding: 30px var(--offset-x);
    display: flex;
    flex-direction: column;
    padding-top: var(--header-height);

    @include tablet {
        justify-content: space-between;
    }
}

.pavilion-page {
    .page-wrapper {
        min-height: 100vh;
        height: auto;
        padding-top: 60px;
        background-color: #f5f5f5;
    }
}

.constructor-page {
    .page-wrapper {
        background-color: #f5f5f5;
    }
}

.page-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    z-index: 1;
    margin-bottom: 40px;

    .page-top__close-btn {
        @include vertical-tablet {
            transform: none;
            right: 0;
            width: fit-content;
            left: unset;
        }
    }

    @include tablet {
        flex-direction: column;
        gap: 12px;
    }

    @include mobile {
        gap: 8px;
        margin-bottom: 80px;
    }
}

.page-top__close-btn {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
}

.list-styled {
    padding-left: 1rem;
    columns: 2;

    li {
        &:not(:first-child) {
            margin-top: 12px;
        }
        &::marker {
            color: $blue;
        }
    }
}

.constructor-page {
    overflow: hidden;
    overflow: clip;
}

canvas {
    touch-action: none;
    user-select: none;
}

.dynamic-bg-wrapper {
    position: relative;
}

.error-message {
    color: $danger;
}
