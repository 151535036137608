.car-list__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-bottom: 272px;

    @media screen and (max-width: 1366px) {
        gap: 16px;
        margin-bottom: 186px;
    }

    @include tablet {
        margin-bottom: 224px;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px;
    }

    @media screen and (max-width: 575px) {
        grid-template-columns: 1fr;
        margin-bottom: 160px;
    }
}

.car-list__empty {
    margin-bottom: 272px;
    @media screen and (max-width: 1366px) {
        margin-bottom: 186px;
    }

    @include tablet {
        margin-bottom: 224px;
    }

    @media screen and (max-width: 575px) {
        margin-bottom: 160px;
    }
}
