.organize-form-popup {
    .menu-popup-close-btn {
        position: absolute;
        right: var(--offset-x);
        top: var(--offset-x);
    }

    .popup-container {
        right: 0;
        background-color: $white;
        height: 100%;
        width: 670px;
        display: flex;
        flex-direction: column;
        padding: 70px;
        gap: 60px;
        padding-top: 120px;

        @media screen and (max-width: 1600px) {
            gap: 40px;
            padding-top: 70px;
        }

        @include tablet {
            width: 470px;
            padding: 22px;
            padding-top: 70px;
        }

        @include mobile {
            width: 100%;
            padding: 16px;
            padding-top: 70px;
            gap: 30px;
        }
    }

    .checkbox__text {
        color: #21212399;

        .form-checkbox-link {
            color: $dark;
            text-decoration: underline;
            cursor: pointer;
            transition: color 0.3s ease;

            @include hover {
                color: $primary;
            }
        }
    }
}

.organize-form-popup-title {
    color: $primary;
}

.organize-input-block {
    display: flex;
    flex-direction: column;
    gap: 18px;
    position: relative;

    .app-message {
        right: 0;
        top: 0;
        left: auto;
        color: #da3f3f;
    }
}

.organize-form-popup-input {
    width: 100%;
    border-radius: 12px;
    padding: 24px;
    border: 1px solid #dcdcdd;
    outline: none;
    box-shadow: inset 100px 100px 100px 100px $white;

    &::placeholder {
        color: #dcdcdd;
    }

    transition: border-color 0.3s ease;

    @include hover {
        border-color: $dark;
    }

    &:focus {
        border-color: $dark;
    }

    &.is-error {
        border-color: #da3f3f;
    }
}

.organize-form-popup-form {
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;

    .checkbox {
        @include mobile {
            padding-right: 0;
        }
    }
}

.organize-form-popup-privacy {
    display: flex;
    gap: 40px;
}

.form-checkbox-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.organize-detail-popup-response {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 28px;
    flex-direction: column;
}

.organize-form-error-message {
    opacity: 0.6;
    width: 50%;
}
