.constructor-slider {
    width: 100%;
    cursor: grab;
    position: relative;
    --aspect-ratio: calc(640 / 400);

    &:active {
        cursor: grabbing;
    }
}

.constructor-slider-el.swiper {
    position: absolute;
    width: calc(100vw - 350px);
    left: 0;
    top: 0;

    @media screen and (max-width: 1366px) {
        width: calc(100vw - 300px);
    }

    @include tablet {
        position: static;
        width: calc(100% + var(--offset-x) * 2);
        margin-left: calc(-1 * var(--offset-x));
    }
}

.constructor-slider-slide {
    max-width: 640px;
    border-radius: 18px;
    position: relative;
    overflow: hidden;

    @include tablet {
        max-width: calc(100% - var(--offset-x) * 2);
    }
}

.constructor-slider-img {
    --aspect-ratio: calc(640 / 400);

    @media screen and (max-width: 1400px) {
        --aspect-ratio: calc(430 / 270);
    }

    @include tablet {
        --aspect-ratio: calc(724 / 450);
    }

    @include mobile {
        --aspect-ratio: calc(330 / 200);
    }
}

.constructor-slider-card {
    opacity: 0;
    --aspect-ratio: calc(1280 / 400);

    @include tablet {
        display: none;
    }
}
