.soc-list {
    display: flex;
    gap: 8px;
}

.soc-list-item {
    @include clickable();
    --size: 50px;
    width: var(--size);
    height: var(--size);
    padding: calc(0.27 * var(--size));
    background-color: $white;
    color: $body-color;
    border: 1px solid currentColor;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;

    @media screen and (max-width: 1366px) {
        --size: 34px;
    }

    svg {
        max-width: 100%;
        height: auto;
        max-height: 100%;

        path {
            transition: fill 0.3s ease;
            fill: currentColor;
        }
    }

    @include hover {
        background-color: $primary;
        color: $white;
    }
}
