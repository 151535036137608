.participation-page {
    .header {
        background-color: #fff;
        padding-bottom: 30px;
    }
}

.visitors__section {
    .program-section-wrapper {
        background-color: $gray;
    }
    .questions-section {
        --bg-color: #{$white};
    }

    .contact-us-popup-response {
        background-color: #fff;
    }

    .faq-section {
        background-color: $light-blue;
    }

    .constructor-section {
        background-color: $light;

        .index-hero__left {
            @include grid-columns(3);

            @include tablet {
                @include grid-columns(6);
                align-items: flex-start;
            }

            @include mobile {
                width: 70%;
            }
        }

        .index-hero__title {
            max-width: 395px;

            @media (max-width: 1610px) {
                max-width: 295px;
            }

            @include tablet {
                text-align: start;
                max-width: 100%;
            }
        }
    }

    .how-it-was-section {
        background-color: #fff;
    }

    .text-with-image__section {
        background-color: #fff;

        .summit__text-with-image__content {
            justify-content: flex-end;
            @include grid-columns(4);

            @include small-laptop {
                max-width: 375px;
            }

            @include tablet {
                width: 50%;
                gap: 0px;
                padding-right: 60px;
            }

            @include mobile {
                width: 100%;
            }
        }

        .text-with-image__subtitle {
            color: $primary;
        }
        .text-with-image__text {
            opacity: 0.6;
            margin-top: 40px;
            max-width: 470px;

            @include tablet {
                margin-top: 30px;
            }

            @include mobile {
                margin-top: 24px;
            }
        }
        .text-with-image__link {
            width: fit-content;
            margin-top: 50px;
            @include tablet {
                margin-top: 40px;
            }
            @include mobile {
                margin-top: 30px;
            }
        }

        .text-with-image__block-top {
            @include tablet {
                @include grid-columns(4);
            }
            @include mobile {
                @include grid-columns(10);
                margin-bottom: 40px;
            }
        }

        .text-with-image__title {
            @include mobile {
                margin-bottom: 0;
            }
        }

        @include tablet {
            .text-with-image__block-left__wrapper {
                width: 100%;
            }

            .text-with-image__block-left {
                width: 100%;
            }
        }
    }
}
