@include configurator() {
    .color-pick-btn-wrapper {
        display: inline-flex;
        align-items: center;
        position: relative;
    }

    .color-pick-btn {
        --padding-x: 8px;
        --padding-y: 8px;
        width: max-content;
        max-width: 100%;
        display: inline-flex;
        align-items: center;
        position: relative;

        &.is-active {
            ~ .color-pick-btn__check {
                opacity: 1;
            }

            @include mobile {
                display: none;

                ~ .color-pick-btn__check {
                    display: none;
                }
            }
        }

        &.has-color {
            --color-default: #000;

            --bg-color-default: #fff;
            --bg-color-hover: #{darken(#fff, 8%)};
            --bg-color-active: #{darken(#fff, 10%)};

            --border-color-default: #{#fff};
            --border-color-hover: #{darken(#fff, 8%)};
            --border-color-active: #{darken(#fff, 10%)};

            ~ .color-pick-btn__check {
                --color-default: #000;

                --bg-color-default: #fff;
                --bg-color-hover: #{darken(#fff, 8%)};
                --bg-color-active: #{darken(#fff, 10%)};
            }
        }

        .constructor-btn__icon {
            margin-right: 0;
            width: 16px;
            display: flex;

            svg {
                width: 100%;
                height: auto;

                [fill]:not([fill='none']) {
                    fill: currentColor;
                }

                [stroke]:not([stroke='none']) {
                    stroke: currentColor;
                }
            }
        }

        .constructor-btn__text {
            font-size: 12px;
            line-height: 1.25;
            letter-spacing: -0.04em;
            font-weight: 400;
        }
    }

    .color-pick-btn__check {
        --color-default: #fff;
        --color: var(--color-default);
        --bg-color-default: #{$primary};
        --bg-color-hover: #{darken($primary, 8%)};
        --bg-color-active: #{darken($primary, 10%)};
        --bg-color: var(--bg-color-default);
        position: absolute;
        top: 0;
        aspect-ratio: 1;
        left: calc(100% + 3px);
        height: 100%;
        border-radius: 50%;
        background-color: $light;
        opacity: 0;
        transition:
            opacity 0.3s ease,
            background-color 0.2s ease;
        color: var(--color);
        background-color: var(--bg-color);

        @include hover {
            --bg-color: var(--bg-color-hover);
        }

        &:not(:disabled):active {
            --bg-color: var(--bg-color-active);
        }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            [stroke] {
                stroke: currentColor;
            }
        }
    }
}
