.community-page {
    // TODO: временный костыль. Переверстать на всем сайте (сейчас плохо сделано)
    .participation-list {
        &::after {
            content: '';
            height: 1px;
            width: 100%;
            background-color: $gray;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }

    .pagination-list {
        margin-bottom: 0;
    }
}
