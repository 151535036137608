.btn {
    --bg-color-default: transparent;
    --bg-color-hover: var(--bg-color-default);
    --bg-color-active: var(--bg-color-hover);
    --bg-color-disabled: var(--bg-color-default);
    --bg-color: var(--bg-color-default);

    --border-color-default: transparent;
    --border-color-hover: var(--border-color-default);
    --border-color-active: var(--border-color-hover);
    --border-color-disabled: var(--border-color-default);
    --border-color: var(--border-color-default);

    --color-default: #{$body-color};
    --color-hover: var(--color-default);
    --color-active: var(--color-hover);
    --color-disabled: var(--color-default);
    --color: var(--color-default);

    --outline-color: currentColor;

    --padding-x: 24px;
    --padding-y: 14px;

    --line-height: 1;

    display: inline-block;
    text-align: center;
    color: var(--color);
    background-color: var(--bg-color);
    border: 1px solid var(--border-color);
    border-radius: 0;
    @include responsive-font-size(11px, 16px, 1280px, 1920px);

    min-height: calc(var(--line-height) * 1em + var(--padding-y) * 2);
    position: relative;
    padding: var(--padding-y) var(--padding-x);
    letter-spacing: 0;
    cursor: pointer;
    text-decoration: none;
    flex-shrink: 0;
    transition:
        color 0.2s ease,
        border-color 0.2s ease,
        background-color 0.2s ease;

    @media (any-hover: hover), (hover: hover) and (pointer: fine) {
        &:not(:disabled):hover {
            --color: var(--color-hover);
            --bg-color: var(--bg-color-hover);
            --border-color: var(--border-color-hover);
        }
    }

    &:hover,
    &:active {
        color: var(--color);
    }

    &:not(:disabled):active {
        --color: var(--color-active);
        --bg-color: var(--bg-color-active);
        --border-color: var(--border-color-active);
    }

    &:focus-visible {
        --color: var(--color-hover);
        --bg-color: var(--bg-color-hover);
        --border-color: var(--border-color-hover);
        outline: 2px solid var(--outline-color);
        outline-offset: 4px;
    }

    &:disabled {
        --color: var(--color-disabled);
        --bg-color: var(--bg-color-disabled);
        --border-color: var(--border-color-disabled);
        cursor: not-allowed;
        opacity: 0.5;
    }

    &.is-async-action-active {
        &::after {
            opacity: 0;
        }

        .btn__text,
        .btn__icon,
        .btn__arrow {
            opacity: 0;
        }

        .spinner {
            --color: currentColor;
        }
    }

    .spinner {
        --size: 22px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: calc(-0.5 * var(--size));
        margin-left: calc(-0.5 * var(--size));
    }

    .btn__icon svg {
        [fill]:not([fill='none']) {
            fill: currentColor;
        }
    }
}

// .btn-primary {
//     --color-default: #fff;

//     --bg-color-default: #{$primary};
//     --bg-color-hover: #{darken($primary, 8%)};
//     --bg-color-active: #{darken($primary, 10%)};

//     --border-color-default: #{$primary};
//     --border-color-hover: #{darken($primary, 8%)};
//     --border-color-active: #{darken($primary, 10%)};
// }

.btn-geometry-wide {
    width: 100%;
}

.btn.btn-outline-light,
.btn.btn-outline-dark,
.btn.btn-outline-primary,
.btn.btn-primary-filled,
.btn.btn-dark-filled {
    --border-color-default: $white;
    --color-default: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: $white;
    height: 42px;
    border-radius: 100px;
    border: 1px solid $white;
    transition:
        padding 0.2s ease,
        gap 0.2s ease,
        opacity 0.3s ease;

    .btn__arrow {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(-200%, -50%);
        opacity: 0;
        transition:
            opacity 0.1s ease,
            transform 0.2s ease;
    }

    @include tablet {
        height: 30px;
        --padding-y: 11px;
    }

    @include hover {
        &::after {
            width: 30px;
            height: 30px;

            @include tablet {
                width: 22px;
                height: 22px;
            }
        }

        .btn__arrow {
            transform: translate(-80%, -50%);
            opacity: 1;
        }

        padding-right: 6px;
        gap: 16px;

        @include mobile {
            .btn__arrow {
                transform: translate(-60%, -50%);
            }
        }

        &:active {
            &::after {
                width: 24px;
                height: 24px;
            }

            .btn__arrow {
                transform: translate(-105%, -50%);
            }

            padding-right: 12px;
        }
    }

    &::after {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $white;
        transition:
            width 0.2s ease,
            height 0.2s ease,
            background-color 0.2s ease;
    }
}

.btn.btn-outline-dark {
    --border-color-default: $dark;
    --color-default: $dark;
    color: $dark;
    border: 1px solid $dark;

    @include hover {
        border-color: $primary;
        color: $primary;
        border-color: $primary;

        &::after {
            background-color: $primary;
        }

        .btn__arrow {
            transform: translate(-80%, -50%);
            opacity: 1;
        }
    }

    .btn__arrow {
        path {
            fill: $white;
        }
    }

    &::after {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $dark;
        transition:
            width 0.2s ease,
            height 0.2s ease;
    }
}

.btn.btn-primary-filled {
    --color-default: $white;
    color: $white;
    border: none;
    background-color: $primary;

    .btn__arrow {
        path {
            fill: $primary;
        }
    }

    @include hover {
        .btn__arrow {
            transform: translate(-80%, -50%);
            opacity: 1;
        }
    }

    &::after {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $white;
        transition:
            width 0.2s ease,
            height 0.2s ease;
    }
}

.btn.btn-grey-filled {
    color: $dark;
    background-color: rgba($dark, 0.1);
    border-radius: 100px;

    @include tablet {
        padding: 11px 16px;
    }
}

.btn.btn-outline-primary {
    --border-color-default: $primary;
    --color-default: $primary;
    color: $primary;
    border: 1px solid $primary;

    .btn__arrow {
        path {
            fill: $white;
        }
    }

    &::after {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $primary;
        transition:
            width 0.2s ease,
            height 0.2s ease;
    }
}

.btn.btn-dark-filled {
    --color-default: $white;
    color: $white;
    border: none;
    background-color: $primary;
    padding-right: 6px;
    gap: 16px;

    .btn__arrow {
        transform: translate(-80%, -50%);
        opacity: 1;

        @include tablet {
            transform: translate(-120%, -50%);
        }

        path {
            fill: $primary;
        }
    }

    &::after {
        content: '';
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $white;
        transition:
            width 0.2s ease,
            height 0.2s ease;
    }
}

.btn-blue {
    --color-default: #fff;

    --bg-color-default: #{$primary};
    --bg-color-hover: #{darken($primary, 8%)};
    --bg-color-active: #{darken($primary, 10%)};

    --border-color-default: #{$primary};
    --border-color-hover: #{darken($primary, 8%)};
    --border-color-active: #{darken($primary, 10%)};

    --padding-x: 29px;
    --padding-y: 18px;
    gap: 6px;
    text-align: center;
    border-radius: 16px;
    cursor: pointer;
    transition:
        color 0.2s ease,
        border-color 0.2s ease,
        background-color 0.2s ease;

    @include tablet {
        --padding-y: 9px;
        font-size: 16px;
    }

    @media screen and (max-width: 767px) {
        --padding-y: 17px;
    }
}

.btn-dark-blue {
    --color-default: #fff;

    --bg-color-default: #{$configurator-primary};
    --bg-color-hover: #{darken($configurator-primary, 8%)};
    --bg-color-active: #{darken($configurator-primary, 10%)};

    --border-color-default: #{$configurator-primary};
    --border-color-hover: #{darken($configurator-primary, 8%)};
    --border-color-active: #{darken($configurator-primary, 10%)};

    --padding-x: 29px;
    --padding-y: 18px;
    gap: 6px;
    text-align: center;
    border-radius: 16px;
    cursor: pointer;
    transition:
        color 0.2s ease,
        border-color 0.2s ease,
        background-color 0.2s ease;

    @include tablet {
        --padding-y: 9px;
        font-size: 16px;
    }

    @media screen and (max-width: 767px) {
        --padding-y: 17px;
    }

    .btn__arrow {
        transform: translate(-50%, -0%);

        path {
            fill: currentColor;
        }
    }
}

.btn-white {
    --color-default: #{$blue};
    --color-hover: #fff;
    --bg-color-default: #fff;
    --bg-color-hover: #{$blue};
    --bg-color-active: #{darken($blue, 10%)};

    --border-color-default: var(--bg-color-default);
    --border-color-hover: #{$blue};
    --border-color-active: #{darken($blue, 10%)};

    --padding-x: 29px;
    --padding-y: 19px;

    gap: 6px;
    text-align: center;
    border-radius: 16px;
    cursor: pointer;
    transition:
        color 0.2s ease,
        border-color 0.2s ease,
        background-color 0.2s ease;

    @include tablet {
        font-size: 16px;
    }
}

.btn.btn-outline-primary {
    --border-color-default: $primary;
    --color-default: $primary;
    color: $primary;
    border: 1px solid $primary;

    @include hover {
        .btn__arrow {
            transform: translate(-80%, -50%);
            opacity: 1;
        }
    }

    .btn__arrow {
        path {
            fill: $white;
        }
    }

    &::after {
        background-color: $primary;
    }
}

.btn.btn-primary-filled {
    --color-default: $white;
    color: $white;
    border: none;
    background-color: $primary;

    .btn__arrow {
        path {
            fill: $primary;
        }
    }

    @include hover {
        .btn__arrow {
            transform: translate(-80%, -50%);
            opacity: 1;
        }
    }

    &::after {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $white;
        transition:
            width 0.2s ease,
            height 0.2s ease;
    }
}

.btn__icon {
    &:not(:last-child) {
        margin-right: 6px;
    }
}
