.services-wrapper {
    padding-top: 100px;
    padding-bottom: 90px;

    @media screen and (max-width: 1366px) {
        padding-top: 70px;
        padding-bottom: 60px;
    }

    @include tablet {
        padding-top: 60px;
        padding-bottom: 112px;
    }

    @include mobile {
        padding-top: 50px;
        padding-bottom: 80px;
    }
}
