.section-docs {
    display: flex;
    align-items: end;
    gap: 10px;
    max-width: 70%;
    flex-wrap: wrap;

    .dropdown {
        .dropdown-content {
            left: auto;

            @include mobile {
                left: 0;
                right: auto;
            }

            @include tablet {
                .pdf_svg__pdf-text {
                    stroke-width: 0.5px;
                    stroke: #fff;
                    fill: #fff;
                }
            }
        }

        .expo-menu-dropdown-link svg {
            margin-left: 0;
        }
    }
}
