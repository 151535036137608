.constructor-news-section {
    width: 100%;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $dark;
    padding-bottom: 80px;
    gap: 60px;
    --padding: 112px;
    background-color: $white;
    padding-top: var(--padding);

    .swiper-wrapper {
        transition-timing-function: easeInOutCubic;
    }

    @include tablet {
        min-height: unset;
        padding-bottom: var(--padding);
    }

    @include mobile {
        --padding: 100px;
    }
}

.constructor-news-top {
    display: flex;
    justify-content: space-between;

    @include mobile {
        flex-direction: column;
        gap: 24px;
    }
}

.constructor-news-bottom {
    --columns: 4;
    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr);
    gap: 20px;
    @include hidden-scrollbar;

    @include tablet {
        overflow: hidden;
        overflow-x: auto;
    }
}

.constructor-news-description-block {
    display: flex;
    align-items: end;
    gap: 85px;
}

.constructor-news-description {
    max-width: 350px;
}

.constructor-news-slider {
    width: 100%;
    cursor: grab;

    &:active {
        cursor: grabbing;
    }
}

.constructor-news-slider-slide {
    height: fit-content;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;

    @media screen and (max-width: 1366px) {
        max-width: 290px;
    }

    @include tablet {
        min-width: 335px;
    }

    @include mobile {
        min-width: 230px;
    }

    @include hover {
        .constructor-news-slide-title {
            color: $primary;
        }

        .constructor-news-slide-btn {
            opacity: 1;
        }
    }
}

.constructor-news-slider-img-wrapper {
    position: relative;
    border-radius: 18px;
    overflow: hidden;
    --aspect-ratio: calc(430 / 290);

    @media screen and (max-width: 1366px) {
        --aspect-ratio: calc(290 / 195);
    }

    @include tablet {
        --aspect-ratio: calc(335 / 240);
    }

    @include mobile {
        --aspect-ratio: calc(230 / 155);
    }
}

.constructor-news-slide-title {
    margin-top: 25px;
    text-align: start;
    transition: color 0.3s ease;

    @media screen and (max-width: 1366px) {
        margin-top: 16px;
    }
}

.constructor-news-slide-date {
    margin-top: 21px;
    text-align: start;
    opacity: 0.6;
}

.constructor-news-slider-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.constructor-news-slide-btn {
    --size: 42px;
    --offset: 20px;
    position: absolute;
    right: var(--offset);
    top: var(--offset);
    min-width: var(--size);
    width: var(--size);
    height: var(--size);
    border: 1px solid $white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease;
    opacity: 0;
    cursor: pointer;

    @include tablet {
        --size: 30px;

        svg {
            width: 12px;
        }
    }

    @include mobile {
        --offset: 20px;
    }

    path {
        transition: fill 0.3s ease;

        fill: $white;
    }
}
