.dynamic-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 4;
    transition: background-color 0.3s ease;
    pointer-events: none;
}
