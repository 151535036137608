.hotel-section {
    display: grid;
    grid-template-columns: repeat(var(--grid-columns), 1fr);
    gap: 15px;
    padding-bottom: 90px;

    @media screen and (max-width: 1366px) {
        padding-bottom: 60px;
    }

    @include tablet {
        padding-bottom: 112px;
        grid-template-columns: 1fr;
        @include recalculate-grid(1);
    }

    @include mobile {
        padding-bottom: 80px;
    }
}

.hotel-section__empty {
    padding-bottom: 272px;

    @media screen and (max-width: 1366px) {
        padding-bottom: 186px;
    }

    @include tablet {
        padding-bottom: 224px;
    }

    @include mobile {
        padding-bottom: 160px;
    }
}

.hotel-section__left {
    grid-column: 1 / 4;
    @include tablet {
        grid-column: 1 / -1;
    }
}

.hotel-section__right {
    grid-column: 5 / -1;
    @include tablet {
        grid-column: 1 / -1;
    }
}

.hotel-section__content {
    position: sticky;
    top: 30%;
    padding-bottom: 10vh;
    display: flex;
    flex-direction: column;
    gap: 124px;

    @include tablet {
        position: relative;
        flex-direction: row;
        align-items: center;
        top: 0;
        gap: 12px;
    }
    @include mobile {
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
    }
}

.hotel-section__content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 62px;
    align-items: flex-start;

    & .btn.btn-outline-dark {
        display: inline-flex;
    }

    @include tablet {
        width: 50%;
        gap: 40px;
    }
    @include mobile {
        width: 100%;
        gap: 24px;
    }
}

.hotel-section-title {
    @include tablet {
        width: 50%;
    }
    @include mobile {
        width: 100%;
    }
}

.hotel-section__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    & li:nth-child(even) {
        transform: translateY(220px);

        @media screen and (max-width: 1366px) {
            transform: translateY(150px);
        }
        @include tablet {
            transform: translateY(134px);
        }
        @include mobile {
            transform: translateY(0);
        }
    }
    @include mobile {
        grid-template-columns: 1fr;
    }
}
