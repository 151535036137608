.page-welcome__wrapper {
    width: 100%;
    background-color: $white;
    display: flex;
    flex-direction: column;
}

.page-welcome__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 80px;
    margin-bottom: 120px;

    @media screen and (max-width: 1366px) {
        margin-top: 60px;
        margin-bottom: 90px;
    }

    @include tablet {
        margin-bottom: 70px;
    }

    @include mobile {
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;

        margin-top: 40px;
        margin-bottom: 30px;
        max-width: 100%;
    }
}

.page-welcome__title {
    @include grid-columns(4);

    @include tablet {
        @include grid-columns(3);
    }

    @include mobile {
        width: 100%;
    }
}

.page-welcome__above-title {
    @include grid-columns(6);

    @include tablet {
        @include grid-columns(10);
    }

    @media screen and (max-width: 767px) {
        width: 100%;
    }

    &:not(:first-child) {
        margin-top: 72px;
    }

    &:not(:last-child) {
        margin-bottom: 30px;

        @media screen and (max-width: 1366px) {
            margin-bottom: 22px;
        }

        @include tablet {
            margin-bottom: 18px;
        }

        @include mobile {
            margin-bottom: 14px;
        }
    }
}
