.tour__section {
    --padding-top: 182px;
    --gap: 15px;
    padding-bottom: calc(var(--padding-top) / 2);
    z-index: 4;

    @include small-laptop {
        --padding-top: 122px;
    }

    @include tablet {
        --offset-block: 60px;
        --padding-top: 122px;
    }

    @include mobile {
        --padding-top: 100px;
        --offset-block: 40px;
    }
}

.tour-frame-wrapper {
    width: 100%;
    height: 80vh;
    height: 80svh;
    border: 1px solid rgba(33, 33, 35, 0.1333333333);

    border-radius: 18px;
    position: relative;
    overflow: hidden;
}

.tour-frame {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}

.tour-frame-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    transform: translate(-50%, -50%);
}
