.contact-us__section {
    display: flex;
    z-index: 4;
    justify-content: space-between;
    padding-bottom: 60px;
}

.contact-us__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    position: relative;
}

.contact-us-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.contact-us__block {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;

    @include tablet {
        gap: 14px;
    }
}

.contact-us__block-top {
    @include mobile {
        flex-direction: column;
    }
}

.contact-us__input-block {
    --gap: 10px;
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    width: 100%;
    position: relative;
    padding-bottom: var(--gap);

    .is-error {
        border-color: #da3f3f;
    }

    .app-message {
        left: 5px;
        text-wrap: nowrap;
        color: #da3f3f;

        position: absolute;
        bottom: -1.2em;
        width: 100%;
    }
}

.contact-us__input {
    width: 100%;
    padding: 25px;
    border-radius: 12px;
    box-shadow: none;
    outline: none;
    outline-offset: 0;
    border: 1.5px solid $dark-gray;
    background-color: var(--bg-color);

    @include small-laptop {
        padding: 16px;
    }

    @include tablet {
        padding: 13px;
    }

    &:-webkit-autofill {
        transition: none;
        caret-color: var(--color);
        border-color: $dark-gray;

        &,
        &:focus,
        &:active,
        &:hover {
            transition: background-color 9999s ease; // суперкостыль, чтобы пофиксить autofill в iOS
            color: var(--color) !important;
            -webkit-text-fill-color: var(--color) !important;
            box-shadow: inset 0 0 0 1000px var(--bg-color) !important;

            ~ .form-label {
                transform: var(--active-label-transform);
            }
        }
    }
}

.contact-us__checkbox {
    &:checked {
        border-color: $dark;
    }

    &.is-error {
        border-color: #da3f3f;
    }

    input[type='checkbox'].is-error ~ .checkbox__element {
        border-color: #da3f3f;
    }
}

.contact-us__checkbox-block__text {
    display: inline-block;
    color: $dark-gray;

    @include mobile {
        max-width: 100%;
    }

    .link {
        color: $dark;
    }
}

.contact-us__checkbox-block {
    @include grid-columns(4);

    @include tablet {
        width: 50%;
    }

    @include mobile {
        width: 100%;
    }
}

.contact-us__block-bot {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .checkbox {
        @include mobile {
            padding-right: 0;
        }
    }

    @include mobile {
        align-items: flex-start;
        flex-direction: column;
        gap: 30px;
    }
}

.contact-us-popup-response {
    position: absolute;
    top: 0;
    left: -10px;
    width: 100%;
    height: 100%;
    background-color: var(--bg-color);

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 28px;
    flex-direction: column;
    z-index: 1;
    padding: 20px;
    box-sizing: content-box;
}
