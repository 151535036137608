.constructor-image-el {
    --aspect-ratio: calc(1180 / 590);

    img {
        border-radius: 18px;
    }
}

.constructor-image-text {
    opacity: 0.6;
}

.constructor-image {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media screen and (max-width: 1366px) {
        gap: 12px;
    }
}
