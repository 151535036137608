.constructor-quote__title {
    margin-bottom: 85px;
    color: $primary;

    @media screen and (max-width: 1366px) {
        margin-bottom: 68px;
    }

    @include mobile {
        margin-bottom: 58px;
    }
}

.blockquote-job {
    opacity: 0.6;
}

.constructor-quote {
    color: $dark;
    border-radius: 18px;
    position: relative;
    overflow: hidden;
    background-color: $light-blue;
    display: flex;
    padding: 50px;
    gap: 50px;

    @media screen and (max-width: 1400px) {
        gap: 32px;
        padding: 32px;
    }

    @include tablet {
        justify-content: space-between;
    }

    @include mobile {
        padding: 20px;
        width: 100%;
    }
}
