@include configurator() {
    .color-input {
        display: flex;

        input[type='color'] {
            --size: 40px;
            border-radius: 50%;
            height: var(--size);
            width: var(--size);
            border: none;
            outline: none;

            &::-webkit-color-swatch-wrapper {
                padding: 0;
            }
            &::-webkit-color-swatch {
                border: none;
                border-radius: 50%;
            }
        }
    }

    .presets-list {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        @include tablet {
            gap: 5px;
        }

        @include mobile {
            gap: 6px;
        }
    }

    .color-preset {
        --size: 50px;
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        position: relative;

        &.color-preset--active {
            &::before {
                --offset: 3px;
                position: absolute;
                content: '';
                top: calc(var(--offset) * -1);
                left: calc(var(--offset) * -1);
                width: calc(100% + var(--offset) * 2);
                height: calc(100% + var(--offset) * 2);
                border: 1px solid $primary;
                border-radius: 50%;
            }
        }

        &.color-preset--white {
            border: 1px solid rgba(#000, 0.1);
        }
    }

    .color-picker-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;

        margin-top: 43px;
    }

    .color-picker {
        margin-inline: auto;
    }
}
