@import './normalize';

button {
    padding: 0;
    border: 0;
    background-color: transparent;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
    letter-spacing: inherit;
    text-transform: inherit;
    cursor: pointer;
}

ul,
ol {
    margin-bottom: 0;
}
