.community-events-section {
    padding-top: 55px;
    padding-bottom: 90px;

    @media screen and (max-width: 1366px) {
        padding-top: 51px;
        padding-bottom: 80px;
    }

    @include tablet {
        @include recalculate-grid(8);
        padding-top: 40px;
    }

    @media screen and (max-width: 767px) {
        padding-top: 30px;
        padding-bottom: 80px;
    }
}

.community-events-list {
    display: flex;
    flex-direction: column;
    gap: 82px;

    @media screen and (max-width: 1366px) {
        gap: 60px;
    }

    @media screen and (max-width: 767px) {
        gap: 50px;
    }
}

.community-event-card {
    display: block;

    @include hover {
        .community-event-card-image {
            transform: scale(1.05);
        }

        .community-event-card-btn {
            background-color: $body-color;
            color: #fff;
        }
    }
}

.community-event-card-dates {
    padding-bottom: 36px;
    border-bottom: 1px solid $border-color;
    margin-bottom: 50px;

    @media screen and (max-width: 1366px) {
        padding-bottom: 28px;
        margin-bottom: 38px;
    }

    @include tablet {
        padding-bottom: 24px;
        margin-bottom: 34px;
    }

    @media screen and (max-width: 767px) {
        padding-bottom: 18px;
        margin-bottom: 26px;
    }
}

.community-event-card-dates-range {
    color: $primary;
    display: flex;
    align-items: baseline;
    gap: 0.4em;
}

.community-event-card-date__lg {
    margin-top: -0.1em;
    margin-bottom: -0.4em;
}

.community-event-card-content {
    display: flex;
    gap: var(--grid-gap);

    @media screen and (max-width: 767px) {
        flex-direction: column;
        gap: 28px;
    }
}

.community-event-card-content__left {
    @include grid-columns(6);
    flex-shrink: 0;
    padding-right: 12px;

    @include tablet {
        @include grid-columns(4);
    }

    @media screen and (max-width: 767px) {
        width: 100%;
        padding-right: 0;
        order: 1;
    }
}

.community-event-card-content__right {
    flex: 1 1 auto;
}

.community-event-card-image-wrapper {
    --aspect-ratio: calc(850 / 460);
    border-radius: 14px;
    overflow: hidden;
    overflow: clip;

    @media screen and (max-width: 1366px) {
        border-radius: 12px;
    }

    @include tablet {
        --aspect-ratio: calc(342 / 262);
    }
}

.community-event-card-image {
    transition: transform 0.35s $app-default-easing;
}

.community-event-card-info {
    display: flex;
    flex-direction: column;
}

.community-event-card-info__top {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding-top: 36px;

    @media screen and (max-width: 1366px) {
        padding-top: 24px;
    }

    @include tablet {
        flex-direction: column;
        gap: 18px;
    }

    @media screen and (max-width: 767px) {
        gap: 14px;
        padding-top: 0;
    }

    &:not(:last-child) {
        margin-bottom: 40px;

        @media screen and (max-width: 767px) {
            margin-bottom: 36px;
        }
    }
}

.community-event-card-title {
    max-width: 20ch;
    line-height: 1;

    @include tablet {
        max-width: 308px;
    }
}

.community-event-card-location {
    color: $primary;
    text-align: right;
    max-width: 205px;
    margin-left: auto;
    flex-shrink: 0;

    @include tablet {
        margin-left: 0;
    }
}

.community-event-card-info__bottom {
    margin-top: auto;
    display: flex;
    align-items: flex-end;
    gap: 20px;

    @media screen and (max-width: 480px) {
        display: block;
    }
}

.community-event-card-description {
    max-width: 45ch;
    opacity: 0.6;

    @include tablet {
        @include clamp(4);
    }

    @media screen and (max-width: 767px) {
        display: block;
    }
}

.community-event-card-button {
    margin-left: auto;

    @media screen and (max-width: 480px) {
        margin-left: 0;
        margin-top: 20px;
    }
}

.community-events__pagination {
    &:not(:first-child) {
        margin-top: 70px;

        @media screen and (max-width: 1366px) {
            margin-top: 60px;
        }

        @include tablet {
            margin-top: 54px;
        }

        @media screen and (max-width: 767px) {
            margin-top: 32px;
        }
    }
}

.community-event-card-btn {
    --size: 46px;
    padding: calc(var(--size) * 0.27);
    width: var(--size);
    height: var(--size);
    border: 1px solid currentColor;
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:
        color 0.3s ease,
        background-color 0.3s ease;

    @media screen and (max-width: 1366px) {
        --size: 40px;
    }

    @include mobile {
        --size: 30px;
    }

    svg {
        [fill]:not([fill='none']) {
            fill: currentColor;
        }

        [stroke]:not([stroke='none']) {
            stroke: currentColor;
        }
    }
}
