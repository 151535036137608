@include configurator() {
    .configurator {
        --y-offset: 30px;
        --x-padding: 20px;
        --y-padding: 20px;
        position: fixed;
        z-index: 1;
        bottom: var(--y-offset);
        right: 25px;
        background-color: #fff;
        padding: var(--y-padding) var(--x-padding);
        border-radius: 16px;
        width: 31vw;
        height: calc(100vh - var(--y-offset) * 2);
        display: flex;
        flex-direction: column;
        transition:
            height 1s $app-default-easing,
            width 1s $app-default-easing;
        overflow: hidden;
        overflow: clip;
        will-change: height, width;
        overflow-y: auto;
        @include small-scrollbar();

        @include large-desktop {
            width: 620px;
        }

        @include tablet {
            --y-offset: 0px;
            --x-padding: 16px;
            --y-padding: 16px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            width: 35vw;
            height: 100vh;
            height: 100svh;
            overflow-y: auto;
            right: 0;
            z-index: 2;
        }

        @include vertical-tablet {
            top: unset;
            bottom: 0;
            height: calc(50svh - var(--y-offset));
            width: 100%;
        }

        &.configurator--hidden {
            height: var(--hidden-height, auto);
            width: 360px;

            @media screen and (max-width: 480px) {
                width: 300px;
            }
        }

        &:not(.configurator--hidden) .configurator-body {
            @media (min-width: 1200px) {
                overflow-y: auto;
                @include small-scrollbar();
                width: calc(100% + var(--x-padding) - 4px);
                margin-right: calc(var(--x-padding) * -1 - 4px);
                padding-right: calc(var(--x-padding) - 4px);
            }
        }

        .close-button-default {
            color: $configurator-primary;

            .close-button__icon {
                border: 1px solid rgba($configurator-primary, 0.2);
            }
        }
    }

    .pavilion-page {
        .configurator {
            height: calc(100vh - var(--y-offset) * 2);
        }
    }

    .configurator-body {
        margin-bottom: auto;
    }

    .configurator-top {
        display: flex;
        align-items: center;

        .close-button {
            margin-left: auto;
        }

        &.configurator-top-form {
            align-items: flex-start;
        }
    }

    .configurator-toggler {
        transition: transform 0.5s ease;
        margin-left: auto;

        .configurator--hidden & {
            transform: rotate(180deg);
        }
    }

    .configurator-count {
        margin-left: 12px;
        padding: 8px 10px;
        background-color: $configurator-primary;
        border-radius: 30px;
        color: #fff;
        line-height: 8px;
    }

    .configurator-title {
        max-width: 70%;

        &:not(:first-child) {
            margin-left: 20px;

            @media screen and (max-width: 1366px) {
                margin-left: 16px;
            }

            @include mobile {
                margin-left: 12px;
            }
        }
    }

    .configurator-hint {
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        max-width: 296px;
        opacity: 0.4;
        transition: transform 2s easeInOutSine;

        .configurator-opened & {
            transform: translateX(calc(-50% - (var(--configurator-width) / 2)));
        }

        @include mobile {
            bottom: 50vh;
            bottom: 50svh;
            max-width: 90vw;
            width: 100%;
        }
    }

    .configurator-hint__icon {
        --size: 16px;
        width: var(--size);
        height: var(--size);
        display: flex;
        justify-content: center;
        align-items: center;

        @include tablet {
            display: none;
        }
    }

    .configurator-hint-desktop-text {
        @include tablet {
            display: none;
        }
    }

    .configurator-hint-mobile-text {
        display: none;

        @include tablet {
            display: block;
        }
    }

    .configurator-banner-label {
        background-color: $blue;
        color: #fff;
        --size: 35px;
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition:
            background-color $app-default-easing 0.4s,
            transform $app-default-easing 0.8s;
        transform: scale(0, 0);
        pointer-events: none;
        user-select: none;
        visibility: hidden;

        &.banner-label--active {
            background-color: $primary;
            visibility: visible;
        }

        &.banner-label--visible {
            transform: scale(1, 1);
            pointer-events: all;
            visibility: visible;
        }
    }

    .banner-wrapper {
        pointer-events: none;
        user-select: none;
    }

    .configurator-form {
        margin-top: 52px;

        margin-bottom: calc(var(--y-padding) * -1);
        padding-bottom: var(--y-padding);
    }

    .configurator-form-head {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .configurator-form-description {
        opacity: 0.4;
    }

    .configurator-title--form {
        max-width: 100%;
    }

    .configurator-color-picker-buttons {
        margin-top: auto;
    }

    .configurator-additional-hint {
        opacity: 0.5;
        margin-block: 32px;
    }

    .configurator-additional {
        margin-block: auto;

        .picker-list + & {
            margin-top: 64px;
        }

        .configurator-group-picker & {
            margin-bottom: 64px;
        }
    }

    .configurator-color-tip {
        margin: auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        gap: 8px;
        color: $configurator-primary;
        opacity: 0.4;
        max-width: 320px;

        + .configurator-color-picker-buttons {
            margin-top: 0;
        }
    }

    .stand-picker-button-wrapper {
        opacity: 0;
        --animation-delay: 0.1s;
        --animation-duration: 0.5s;
    }

    .stand-picker-button {
        @include clickable();
        display: inline-flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        gap: 6px;
        border-radius: 30px;
        color: #fff;
        background-color: $primary-2;
        height: 35px;
        padding-inline: 12px;
        white-space: nowrap;
        transition:
            background-color 0.3s ease,
            scale 0.3s ease,
            transform 0.3s ease;
        font-weight: 400;
        box-shadow: 0px 0px 10px 0px rgba(1, 21, 77, 0.16);

        &.is-inactive {
            background-color: #fff;
            color: $configurator-primary;

            .stand-picker-button__space {
                opacity: 0.4;
            }

            @include hover {
                color: #fff;
            }
        }

        &.is-picked {
            color: #fff;
            background-color: $configurator-primary;
        }

        &.is-booked {
            background-color: #989fb0;
            gap: 20px;
            height: 50px;
            border-radius: 16px;

            @include mobile {
                height: 40px;
                border-radius: 12px;
            }

            &.is-picked {
                background-color: $configurator-primary;
            }
        }

        @include tablet {
            height: 30px;
            padding-inline: 10px;
        }

        @include hover {
            background-color: $configurator-primary;
            will-change: transform;

            &.is-booked {
                transition-delay: 0.1s;
                transform: translate(-10px, -15px);
                scale: 1.5;
            }
        }
    }

    .stand-picker-button__name {
        text-transform: uppercase;
    }

    .stand-picker-button-booked__logo {
        max-height: 100%;
        width: max-content;
        position: static;
        height: auto;
        flex-shrink: 0;
        min-width: 0;
        max-width: 80px;
        object-fit: contain;
        scale: 1.3;

        @include mobile {
            scale: 1.2;
            max-width: 70px;
        }
    }

    .stand-picker-button-booked__right {
        flex-shrink: 0;
        min-width: 0;
        display: flex;
        flex-direction: column;
        text-align: left;
    }

    .stand-picker-button-booked__status {
        opacity: 0.7;
        margin-top: 4px;
    }

    .stand-picker-button__space {
        opacity: 0.5;
    }
}

.configurator-color-list {
    display: flex;
    flex-direction: column;
    gap: 40px;

    &:not(:first-child) {
        margin-top: 110px;

        @media screen and (max-width: 1366px) {
            margin-top: 90px;
        }

        @include tablet {
            margin-top: 80px;
        }

        @include mobile {
            margin-top: 58px;
        }
    }
}

.configurator-color-item {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    color: $configurator-primary;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(#000, 0.1);

    @include tablet {
        padding-bottom: 16px;
    }
}

.configurator-color-item__icon {
    --size: 40px;
    flex-shrink: 0;
    width: var(--size);
    height: var(--size);
    border-radius: 14px;
    border: 1px solid rgba($configurator-primary, 0.2);
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 40%;
        height: auto;
        color: $configurator-primary;

        [fill]:not([fill='none']) {
            fill: currentColor;
        }

        [stroke]:not([stroke='none']) {
            stroke: currentColor;
        }
    }
}

.configurator-color-item__title {
    color: $primary;
}

.configurator-color-item__color-container {
    margin-left: auto;
    flex-shrink: 0;
}

.configurator-color-item__color {
    display: flex;
    align-items: center;
    gap: 15px;
    text-transform: uppercase;

    @media screen and (max-width: 767px) {
        gap: 10px;
    }
}

.configurator-color-item__color-preview {
    --size: 21px;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    border: 1px solid rgba($configurator-primary, 0.15);
    flex-shrink: 0;
}

.webgl-canvas {
    max-height: 100svh;

    canvas + div {
        @include hidden-scrollbar();
    }
}
