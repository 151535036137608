.program-detail-section-wrapper {
    display: flex;
    flex-direction: column;
    z-index: 4;
    padding-bottom: 60px;
    gap: 70px;
    min-height: 300px;
    overflow: hidden;
    overflow: clip;

    @media screen and (max-width: 1600px) {
        padding-bottom: 50px;
        gap: 50px;
    }

    @include tablet {
        flex-direction: column;
        gap: 20px;
        background-color: $white;
        justify-content: flex-start;
        min-height: unset;
    }

    @include mobile {
        gap: 0;
        padding-bottom: 0;
    }
}

.program-detail-list-rows {
    .program-detail-list-row:first-child {
        border-top: 1px solid #dcdcdd;
    }
}

.program-detail-list-row {
    position: relative;
    display: flex;
    align-items: center;
    height: 170px;
    width: 100%;
    transition:
        background-color 0.3s ease,
        border-color 0.3s ease;
    z-index: 3;
    padding-inline: 30px;
    cursor: pointer;
    border-bottom: 1px solid #dcdcdd;

    &::before {
        content: '';
        position: absolute;
        top: -2px;
        left: 0;
        height: calc(100% + 4px);
        width: 100%;
        background-color: $light-blue;
        opacity: 0;
        z-index: 2;
        border-radius: 18px;
        transition: opacity 0.3s ease;
    }

    &::after {
        content: '';
        position: absolute;
        top: -2px;
        left: 0;
        height: calc(100% + 4px);
        width: 100%;
        background-color: $white;
        opacity: 0;
        z-index: 1;
        transition: opacity 0.2s ease;
    }

    @include hover {
        &::before {
            opacity: 1;
        }
        &::after {
            opacity: 1;
        }
    }

    @include laptop {
        height: 115px;
    }

    @media screen and (max-width: 1440px) {
        padding-inline: 30px;
    }
}

.program-detail-list-row-date {
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
}

.program-detail-list-row-button {
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;

    &.is-opacity {
        opacity: 0;
        pointer-events: none;
    }
}

.program-detail-list-row-date,
.program-detail-list-row-topic,
.program-detail-list-row-place,
.program-detail-list-row-logo,
.program-detail-list-row-right,
.program-detail-list-row-format {
    z-index: 2;
    position: relative;
}

.program-detail-list-header {
    display: flex;
    margin-bottom: 20px;
    padding-left: 30px;
}

.program-detail-list-row-topic,
.program-detail-list-header-topic {
    margin-left: 170px;
    min-width: 470px;
    width: 470px;

    @include laptop {
        margin-left: 9vw;
        min-width: 24.5vw;
        width: 24.5vw;
    }

    @include tablet {
        margin: 0;
        min-width: unset;
        width: unset;
    }
}

.program-detail-list-row-date,
.program-detail-list-header-date {
    white-space: nowrap;
    width: 100px;
    min-width: 100px;
    text-align: left;

    @include laptop {
        width: 71px;
        min-width: 71px;
    }

    @include mobile {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.program-detail-list-row-place,
.program-detail-list-header-place {
    min-width: 120px;
    width: 120px;
    margin-left: 64px;

    @include laptop {
        margin-left: 3vw;
        min-width: 6.6vw;
        width: 6.6vw;
    }

    @include tablet {
        margin: 0;
        min-width: unset;
        width: unset;
    }
}

.program-detail-list-row-format,
.program-detail-list-header-format {
    min-width: 230px;
    width: 230px;
    margin-left: 94px;
    text-align: left;

    @include laptop {
        min-width: 12vw;
        width: 12vw;
        margin-left: 4.9vw;
    }

    @include tablet {
        margin: 0;
        min-width: unset;
        width: unset;
    }
}

.program-detail-list-row-place {
    color: $primary;
    cursor: pointer;
    pointer-events: all;
    text-align: left;
}

.program-detail-list-row-topic-type {
    opacity: 0.4;
}

.program-detail-list-row-topic {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;

    @include laptop {
        gap: 12px;
    }
}

.program-detail-list-row-topic-title {
    text-align: left;
}

.program-detail-list-row-logo {
    margin-inline: 58px;
    min-width: 130px;
    overflow: hidden;
    max-width: 200px;

    img {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
    }

    @include laptop {
        margin-inline: 3vw;
        min-width: unset;
        max-width: 200px;
    }

    @include tablet {
        margin: 0;
    }
}

.program-detail-list-row-star {
    --size: 90px;
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #dcdcdd;
    border-right: 1px solid #dcdcdd;
    cursor: pointer;

    svg {
        transition: fill 0.3s ease;
        path {
            transition:
                stroke 0.3s ease,
                opacity 0.3s ease;
        }
    }

    &.is-favorite {
        svg {
            fill: $primary;

            path {
                opacity: 1;
                stroke: $primary;
            }
        }
    }

    @media screen and (max-width: 1440px) {
        --size: 60px;
        svg {
            width: 24px;
            height: 24px;
        }
    }

    @include mobile {
        --size: 40px;
        svg {
            width: 22px;
            height: 22px;
        }
    }
}

.program-detail-list-row-right {
    display: flex;
    align-items: center;
    gap: 28px;
    margin-left: auto;

    @include tablet {
        margin: 0;
    }

    @include mobile {
        position: absolute;
        right: 0;
        top: 0;
        width: fit-content;
    }
}

.program-detail-list-row-button {
    width: 42px;
    height: 42px;
    border: 1px solid $dark;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:
        background-color 0.3s ease,
        transform 0.3s ease;
    cursor: pointer;

    @media screen and (max-width: 1366px) {
        width: 30px;
        height: 30px;

        svg {
            width: 12px;
        }
    }

    @include hover {
        background-color: $dark;

        path {
            fill: $white;
        }

        &:active {
            transform: scale(0.95);
        }
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    path {
        transition: fill 0.3s ease;

        fill: $dark;
    }
}

.program-detail-list-btn {
    width: fit-content;
}

.program-detail-list-btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    @include mobile {
        margin-top: 20px;
        margin-bottom: 30px;
    }
}

.program-detail-list-row-mobile {
    display: flex;
    width: 100%;
    gap: 100px;
    position: relative;
    justify-content: space-between;
    margin-top: 28px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dcdcdd;
}

.program-detail-list-row-center {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 24px;

    @include mobile {
        padding-top: 55px;
    }
}

.program-detail-list-row-mobile-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.program-detail-list-row-date-text {
    text-align: left;
}

.program-detail-separate-section {
    padding-top: calc(var(--header-height) + 60px);
    padding-bottom: 0;
    --padding: 112px;

    @media screen and (max-width: 1366px) {
        padding-top: calc(var(--header-height) + 40px);
    }

    @include tablet {
        padding-top: var(--padding);
    }

    @include mobile {
        --padding: 80px;
    }

    .program-detail-section-wrapper.wrapper {
        padding-inline: 0;
    }

    .program-detail-title {
        margin-bottom: 0;
        margin-top: 0;

        @include mobile {
            margin-bottom: 30px;
        }
    }

    .program-detail-title-block {
        align-items: flex-end;
        margin-bottom: 60px;
    }

    .news-top-block {
        padding-bottom: 20px;
    }

    .program-detail-title-btns {
        margin-bottom: 0;

        @include mobile {
            width: 100%;
        }
    }

    .program-detail-title-block-text {
        width: 50%;

        @include mobile {
            width: 100%;
        }
    }

    .program-detail-section-wrapper {
        padding-bottom: 0;
    }
}
