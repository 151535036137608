.showreel-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    position: relative;
}

.showreel-video-wrapper {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    transition: opacity 1.5s ease-in-out;
}

.showreel-video-wrapper.hidden-video {
    opacity: 0;
    pointer-events: none;
}

.poster-wrapper {
    height: 100%;
    width: 100vw;
    object-fit: cover;
    z-index: 0;
}

.canvas {
    pointer-events: none;
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: $blue;
}

.first-section-wrapper {
    min-height: 100vh;
    min-height: 100svh;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: var(--header-height);
    padding-bottom: 100px;
    padding-inline: var(--offset-x);

    @include tablet {
        flex-direction: column;
        align-items: flex-start;
        gap: 50px;
        padding-bottom: 60px;
    }

    @include mobile {
        gap: 40px;
        padding-bottom: 20px;
    }
}

.hero-title-block {
    position: relative;
    z-index: 2;
    color: #fff;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    text-transform: uppercase;

    @include mobile {
        gap: 32px;
    }
}

.hero-title {
    white-space: nowrap;

    @media screen and (max-width: 350px) {
        font-size: 68px;
    }
}

.hero-title-text {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.hero-title-left {
    max-width: 200px;

    @include small-laptop {
        max-width: 155px;
    }

    @include mobile {
        max-width: 150px;
    }
}

.hero-title-right {
    max-width: 270px;
    text-align: end;
}

.hero-btn {
    z-index: 3;
}
