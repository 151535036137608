.booking-form {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.booking-form__top {
    display: flex;
    gap: 20px;
    margin-bottom: 48px;

    @media screen and (max-width: 1366px) {
        margin-bottom: 29px;
    }
}

.booking-form__top-right {
    margin-left: auto;
}

.booking-form__title {
    margin-bottom: 20px;

    @media screen and (max-width: 1366px) {
        margin-bottom: 8px;
    }
}

.booking-form__description {
    opacity: 0.5;

    @include tablet {
        max-width: 301px;
    }
}

.booking-form__inputs {
    --gap-x: 26px;
    --gap-y: 26px;
    display: flex;
    flex-direction: column;
    gap: var(--gap-y) var(--gap-x);

    @media screen and (max-width: 1366px) {
        --gap-x: 6px;
        --gap-y: 10px;
    }
}

.booking-form__input-group {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap-y) var(--gap-x);

    @media screen and (max-width: 767px) {
        flex-direction: column;
    }

    > .booking-form__input-field {
        flex: 1 1 calc(50% - var(--gap-x));
    }
}

.booking-form__checkbox-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 21px;
    margin-top: 10px;

    &:not(:last-child) {
        margin-bottom: 40px;
    }

    .checkbox__text {
        color: currentColor;
    }
}

.booking-form__submit-button {
    width: 100%;
    margin-top: auto;

    @media screen and (max-width: 1366px) {
        --padding-y: 9px;
    }

    @media screen and (max-width: 767px) {
        --padding-y: 17px;
    }
}

.booking-form-message {
    margin-block: 20px;

    &.is-error {
        color: $danger;
    }
}

.form-response {
    position: absolute;
    z-index: 8;
    background-color: #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition:
        opacity 0.3s ease,
        visibility 0.3s ease;

    &.is-visible {
        opacity: 1;
        visibility: visible;
    }

    @include tablet {
        justify-content: center;
        align-items: center;
    }
}

.form-response__close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
}

.form-response-content {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 30px;
    margin-right: 30px;
}

.form-response-content__title {
    margin-bottom: 14px;
}

.form-response-content__text {
    opacity: 0.6;
}

.form-response-content__link {
    margin-top: 56px;
}
