.participation-page-wrapper {
    .questions-section {
        --bg-color: #{$white};
    }
}

.participation-top {
    width: 100%;
    background-color: $white;
    display: flex;
    flex-direction: column;
}

.participation-breadcrumbs {
    padding-top: calc(var(--header-height) + 60px);

    @media screen and (max-width: 1366px) {
        padding-top: calc(var(--header-height) + 40px);
    }

    @include mobile {
        padding-top: calc(var(--header-height) + 20px);
    }
}

.participation-title {
    margin-top: 80px;
    margin-bottom: 120px;

    @media screen and (max-width: 1366px) {
        margin-top: 60px;
        margin-bottom: 90px;
    }

    @include tablet {
        margin-bottom: 70px;
        max-width: 250px;
    }

    @include mobile {
        margin-top: 40px;
        margin-bottom: 30px;
        max-width: 100%;
    }
}

.participation-title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 70px;
    }
}

.participation-list-item {
    position: relative;
    transition: color 0.3s ease;
    cursor: pointer;
    margin-left: 16px;
    display: flex;
    align-items: center;
    white-space: nowrap;

    &:last-child {
        @include tablet {
            padding-right: var(--offset-x);
        }
    }

    &::before {
        content: '';
        height: 5px;
        width: 5px;
        background-color: $primary;
        border-radius: 100%;
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translate(-10px, -50%);
        opacity: 0;
        transition:
            transform 0.5s ease,
            opacity 0.5s ease;
    }
}

.participation-list-item.is-active {
    color: $primary;

    &::before {
        transform: translate(0, -50%);
        opacity: 1;
    }
}

.participation-list {
    display: flex;
    gap: 50px;
    padding: 25px 0px;
    position: relative;

    @media screen and (max-width: 1366px) {
        gap: 30px;
        padding: 20px 0px;
    }

    @include tablet {
        overflow-x: auto;
        overflow-y: hidden;
        @include hidden-scrollbar;
        margin-inline: calc(-1 * var(--offset-x));
        padding-left: var(--offset-x);
    }
}

.anchors-wrapper {
    position: sticky;
    top: var(--header-height);
    z-index: 5;
    overflow: hidden;
    background-color: $white;
    margin-top: -1px;

    &::after {
        content: '';
        width: calc(100% - (var(--offset-x) * 2));
        position: absolute;
        left: var(--offset-x);
        right: var(--offset-x);
        bottom: 0;
        border-bottom: 1px solid $gray;
    }
    &::before {
        content: '';
        width: calc(100% - (var(--offset-x) * 2));
        position: absolute;
        left: var(--offset-x);
        right: var(--offset-x);
        top: 1px;
        border-bottom: 1px solid $gray;
    }
}

.anchors {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 30px;
}

.anchors-container {
    display: flex;
    gap: 30px;
    align-items: center;

    @include tablet {
        overflow-x: auto;
        @include hidden-scrollbar;
    }
}

.anchor-item {
    padding: 12px;
    border-radius: 100px;
    color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    letter-spacing: -0.03em;
    z-index: 5;
    backdrop-filter: blur(6px);
    position: relative;
    transition: 0.4s ease;
    cursor: pointer;
    &:before,
    &:after {
        background: $primary;
        border-radius: 100px;
        transition: 0.3s ease;
        content: '';
        position: absolute;
        z-index: -1;
        top: 50%;
        right: 50%;
        bottom: 50%;
        left: 50%;
    }

    scroll-margin-left: calc(12px + var(--offset-x));

    @include hover {
        &:before {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        color: $white;
    }

    &:last-child {
        @include tablet {
            padding-right: var(--offset-x);
        }
    }

    &.is-active {
        &:before {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        color: $white;
    }
}

.anchors-theme--light {
    color: $white;
}

.anchors-theme--dark {
    color: $dark;
}

.scroll-back-btn {
    border: 1px solid $dark;
    --size: 30px;
    --offset: 12px;
    min-width: var(--size);
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:
        background-color 0.3s ease,
        transform 0.3s ease,
        border-color 0.3s ease;
    transform: rotate(-90deg);

    path {
        transition: fill 0.3s ease;
    }

    cursor: pointer;

    @media screen and (max-width: 1366px) {
        svg {
            width: 12px;
        }
    }
}

.scroll-back-btn-block {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-left: auto;
}

.scroll-back-btn-mobile {
    --size: 50px;
    --offset: 24px;
    position: fixed;
    width: var(--size);
    height: var(--size);
    bottom: calc(var(--offset) + 50px + 16px);
    right: var(--offset);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: background-color 0.3s ease;
    cursor: pointer;

    @include mobile {
        bottom: var(--offset);
    }

    &.theme-dark {
        background-color: $primary;
        svg {
            path {
                stroke: $white;
            }
        }
    }

    &.theme-light {
        background-color: $white;
    }

    &.universal {
        --size: 80px;
        --offset: 40px;
        bottom: calc(var(--offset) + 80px + 20px);

        svg {
            width: 28px;
            height: 28px;
        }

        @media screen and (max-width: 1366px) {
            --size: 50px;
            --offset: 24px;
            bottom: calc(var(--offset) + 50px + 12px);

            svg {
                width: 18px;
                height: 18px;
            }
        }

        @include mobile {
            --offset: 16px;
            bottom: var(--offset);
        }
    }
}
