@include configurator() {
    .config-list {
        display: flex;
        flex-wrap: wrap;
        --gap: 10px;
        gap: var(--gap);
        margin-bottom: 87px;
        margin-top: 43px;
    }

    .config-list__item {
        width: calc(50% - var(--gap) / 2);
        height: 130px;

        @include tablet {
            width: 100%;
        }
    }

    .config-list-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        gap: 16px;
        justify-content: space-between;
        padding: 20px;
        border-radius: 16px;
        border: 1px solid rgba(#c4c4c4, 0.5);
        width: 100%;
        height: 100%;

        @include small-laptop {
            padding: 12px;
        }
    }

    .config-list-item__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        width: 100%;
    }

    .config-list-item__hint {
        opacity: 0.4;
    }

    .pavilion-prop-opacity {
        opacity: 0.6;
    }

    .configurator-hero-buttons {
        justify-self: flex-end;
        display: flex;
        flex-direction: column;
        gap: 10px;
        background: #fff;
        padding-top: 20px;
        position: relative;

        .configurator--scroll & {
            &::after {
                position: absolute;
                content: '';
                top: 0;
                left: calc(var(--x-padding) * -1);
                width: calc(100% + var(--x-padding) * 2);
                height: 1px;
                background-color: rgba($primary, 0.2);
            }
        }
    }

    .pavilion-info {
        margin-bottom: 87px;
        margin-top: auto;
        display: flex;
        flex-direction: column;
        gap: 43px;
    }

    .configurator-copy-config-btn {
        @include clickable();
        --size: 50px;
        width: var(--size);
        height: var(--size);
        border-radius: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background-color: $configurator-primary;

        @media screen and (max-width: 1366px) {
            --size: 40px;
            border-radius: 12px;
        }

        @include mobile {
            --size: 32px;
            border-radius: 8px;
        }
    }

    .configurator-copy-config-btn__icon {
        path,
        rect {
            stroke-dasharray: var(--path-length) var(--path-length);
            animation: stroke-dash 1s $app-default-easing forwards;
        }
    }
}
