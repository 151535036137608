.events-section-wrapper {
    display: flex;
    gap: var(--grid-gap);
    min-height: 100vh;
    z-index: 4;
    --padding: 120px;
    padding-top: calc(var(--header-height) + var(--padding));
    padding-bottom: var(--padding);
    position: relative;

    @include tablet {
        --padding: 112px;
        padding-top: var(--padding);
        flex-direction: column;
        background-color: $white;
    }

    @include mobile {
        padding-bottom: 80px;
        padding-top: 80px;
    }
}

.events-left {
    @include grid-columns(4);
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - var(--header-height) - 180px);
    position: sticky;
    top: calc(var(--header-height) + 120px);
    z-index: 4;

    @include tablet {
        top: calc(var(--header-height));
        gap: 24px;
        flex-direction: column;
        position: relative;
        top: unset;
    }
}

.events-title {
    max-width: 440px;

    @include tablet {
        max-width: 310px;
        width: 100%;
    }
}

.events-info {
    max-width: 440px;
    height: 40%;
    position: relative;

    @include tablet {
        max-width: 310px;
        width: 100%;
    }

    @include mobile {
        height: 250px;
        max-width: 100%;
        bottom: 0;
        left: 0;
        right: unset;
    }
}

.events-info-content {
    display: flex;
    flex-direction: column;
    gap: 45px;
    bottom: 20px;

    @include tablet {
        gap: 38px;
    }

    @include mobile {
        height: fit-content;
        gap: 0;
    }
}

.events-title {
    @include tablet {
        margin-bottom: 56px;
    }
}

.events-right {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    z-index: 4;

    @include tablet {
        width: 100%;
        padding-bottom: 120px;
    }
}

.event-btn {
    z-index: 10;
    width: fit-content;
}

.events-info-title {
    color: $primary;
}

.events-info-description {
    @include mobile {
        margin-bottom: 32px;
        margin-top: 24px;
    }
}

.event-card-wrapper {
    position: sticky;
    top: var(--header-height);

    @include tablet {
        position: relative;
        top: unset;
        margin-bottom: 40px;
    }

    @include mobile {
        margin-bottom: 20px;
    }
}

.event-card {
    width: 100%;
    border-radius: 18px;
    overflow: hidden;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    --aspect-ratio: calc(1030 / 600);

    @include tablet {
        --aspect-ratio: calc(720 / 420);
    }

    @include mobile {
        box-shadow: none;
    }
}

.events-cards-wrapper {
    display: flex;
    flex-direction: column;
    gap: 50px;
}
