@include configurator() {
    .page-top {
        .page-top__close-btn {
            @include vertical-tablet {
                .close-button__text {
                    display: none;
                }
            }
        }
    }

    .street-page-content {
        display: flex;
        gap: var(--grid-gap);
        margin-top: 50px;
        color: $dark-blue;

        @media screen and (max-width: 1366px) {
            margin-top: 0;
        }

        @include tablet {
            flex-direction: column;
            gap: 0;
            margin-top: 0;
        }
    }

    .street-page-content__table {
        @include grid-columns(3);
        flex-shrink: 0;
        position: relative;
        z-index: 1;

        @media screen and (max-width: 1366px) {
            width: 191px;
            font-size: 12px;
        }

        @media screen and (max-width: 767px) {
            font-size: 14px;
        }
    }

    .street-page-right {
        border-radius: 24px;
        position: absolute;
        z-index: 1;
        bottom: var(--offset-y);
        right: var(--offset-x);
        @include grid-columns(6);
        height: calc(100vh - var(--offset-y) * 2);
        background-color: #fff;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        overscroll-behavior: none;

        @media screen and (max-width: 1366px) {
            @include grid-columns(5);
        }

        @include tablet {
            margin-top: 24px;
            width: 100%;
            position: relative;
            bottom: 0;
            right: 0;
            height: auto;
            margin-inline: calc(-1 * var(--offset-x));
            width: calc(100% + var(--offset-x) * 2);
            overscroll-behavior: auto;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .booking-form,
        .booking-form__top {
            @include tablet {
                @include grid-offset(padding-inline, 1);
            }

            @media screen and (max-width: 767px) {
                padding-inline: 0;
            }
        }
    }

    .street-map-wrapper {
        overflow: hidden;
        overflow: clip;
        width: 100%;
        @include grid-columns(14);
        margin-top: -170px;

        @media screen and (max-width: 1440px) {
            margin-top: -165px;
            @include grid-columns(11);
        }

        @media screen and (max-width: 1366px) {
            margin-top: -100px;
        }

        @include tablet {
            margin-top: -200px;
            padding-inline: 150px;
            overflow-x: auto;
            @include hidden-scrollbar();
            padding-bottom: 10px;
            margin-inline: calc(-1 * var(--offset-x));
            width: calc(100% + var(--offset-x) * 2);
            margin-bottom: 30px;
        }

        @media screen and (max-width: 767px) {
            padding-inline: 0px;
            margin-top: -150px;
        }
    }

    .street-map {
        width: 100%;
        transform: translate(0, -10%);

        @media screen and (max-width: 1440px) {
            transform: none;
        }

        @include tablet {
            max-width: none;
            width: calc(100% + 300px);
            min-width: 700px;
        }
    }

    .street-page-right-inner {
        padding: 30px;
        flex: 1 1 auto;
        overflow-y: auto;
        @include small-scrollbar();
        display: flex;
        flex-direction: column;

        @include tablet {
            padding: 20px;
        }
    }

    .street-gallery-opener {
        display: block;
        position: absolute;
        z-index: 2;
        left: var(--offset-x);
        bottom: 30px;
        width: 288px;
        height: 250px;
        display: flex;
        gap: 6px;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 16px;

        @media screen and (max-width: 1366px) {
            width: 194px;
            height: 168px;
        }

        @include tablet {
            position: relative;
            top: 0;
            left: 0;
            width: auto;
            height: auto;
            align-self: flex-start;
            flex-shrink: 0;
            padding: 10px 19px;
            border-radius: 10px;
        }

        @media screen and (max-width: 767px) {
            border-radius: 8px;
            padding: 0;
            width: 40px;
            height: 40px;
        }
    }

    .street-gallery-opener__img {
        width: 86%;
        height: 86%;
        object-fit: cover;
        border-radius: inherit;

        @include tablet {
            display: none;
        }
    }

    .street-gallery-opener__icon {
        display: none;
        width: auto;
        height: 1em;

        @include tablet {
            display: inline-flex;
        }

        [fill]:not([fill='none']) {
            fill: currentColor;
        }
    }

    .street-gallery-opener__text {
        background-color: #fff;
        padding: 13px 31px;
        border-radius: 16px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: max-content;

        @include tablet {
            position: static;
            transform: none;
            padding: 0;
        }

        @media screen and (max-width: 767px) {
            @include visually-hidden();
        }
    }

    .street-page {
        .page-wrapper {
            padding-top: 60px;
            background-color: #f5f5f5;
        }
    }

    .street-page-top {
        @include tablet {
            margin-bottom: 28px;
        }
    }
}
