.services-title-block__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--grid-gap);
    padding-bottom: 120px;

    @media screen and (max-width: 1366px) {
        padding-bottom: 84px;
    }

    @media screen and (max-width: 767px) {
        padding-bottom: 80px;
        gap: 0px;
        flex-direction: column;
        align-items: flex-start;
    }

    @media screen and (max-width: 575px) {
        padding-bottom: 64px;
    }
}

.services-title__header {
    @include grid-columns(2);
    @include grid-offset(padding-right, 1);

    @include mobile {
        padding-right: 0;
        padding-bottom: 24px;
    }
}

.services-description-block__header {
    @include grid-columns(4);

    display: flex;
    align-items: center;
    gap: 20px;

    @include tablet {
        @include grid-columns(6);
        flex-direction: column;
        align-items: start;
    }

    @include mobile {
        gap: 30px;
    }
}

.services-description__header {
    @include grid-columns(3);

    @media screen and (max-width: 1366px) {
        @include grid-columns(4);
    }

    @media screen and (max-width: 767px) {
        @include grid-columns(6);
    }

    @media screen and (max-width: 575px) {
        @include grid-columns(10);
    }
}
