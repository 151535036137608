.service-video-review-card {
    width: 100%;
    border-radius: var(--border-radius);
    overflow: hidden;
    overflow: clip;
    color: #fff;
    display: block;

    @include hover {
        .service-video-review-card__btn {
            --size: var(--size-active);
        }
    }

    &.service-video-review-card--vertical {
        .service-video-review-card__responsive {
            --aspect-ratio: calc(368 / 560);

            @supports (aspect-ratio: 1) {
                padding-top: 0;
                height: 100%;
                aspect-ratio: var(--aspect-ratio);
            }
        }
    }

    &.is-playing {
        .service-video-review-card__media {
            &::after {
                opacity: 0;
            }
        }

        .service-video-review-card__btn {
            --size: var(--size-active);
        }

        .service-video-review-card__date,
        .service-video-review-card__title {
            opacity: 0;
            transform: translate(0, 10px);
        }
    }
}

.video-constructor-wrapper {
    position: relative;
    --aspect-ratio: calc(1180 / 664);
    background-color: $dark-gray;

    @media screen and (max-width: 1366px) {
        --aspect-ratio: calc(800 / 450);
    }

    @media screen and (max-width: 768px) {
        --aspect-ratio: calc(720 / 360);
    }
}

.video-constructor-wrapper {
    .video-play-btn {
        pointer-events: all;
    }
}
