.street-page-right-inner,
.configurator {
    .app-message {
        $line-height: 1.1;
        min-height: $line-height * 1em;
        line-height: $line-height;
        margin-top: 6px;
        padding-left: var(--padding-x);
        padding-right: var(--padding-x);
        font-size: 12px;

        @media screen and (max-width: 1366px) {
            font-size: 10px;
        }
    }

    .input-group {
        --bg-color: #fff;
        --color: currentColor;
        --border-color: #{rgba($primary, 0.1)};
        --padding-x: 20px;
        --height: 56px;
        --border-radius: 16px;
        --icon-size: 20px;
        --active-label-transform: translate3d(0, calc(-215% + var(--label-height, 0px)), 0) scale(0.6);
        position: relative;

        @media screen and (max-width: 1366px) {
            --height: 40px;
            --border-radius: 10px;
            --padding-x: 14px;
        }

        @media screen and (max-width: 767px) {
            --height: 56px;
            --padding-x: 20px;
            --border-radius: 16px;
        }

        &.input-group--textarea {
            --active-label-transform: translate3d(0, calc(-185% + var(--label-height, 0px)), 0) scale(0.6);

            &::before {
                content: '';
                position: absolute;
                z-index: 1;
                top: 1px;
                left: var(--padding-x);
                width: calc(100% - var(--padding-x) * 2);
                height: 32px;
                background-color: var(--bg-color);
                pointer-events: none;
            }
        }

        &.is-error {
            --color: #{$danger};

            .app-message {
                color: $danger;
            }
        }
    }

    .input-group__icon {
        position: absolute;
        z-index: 1;
        top: calc(var(--height) / 2);
        transform: translate3d(0, -50%, 0);
        right: var(--padding-x);
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--icon-size);
        height: var(--icon-size);
        border-radius: 50%;

        svg {
            width: 16px;
            height: auto;
            max-height: 100%;

            [fill]:not([fill]) {
                fill: currentColor;
            }

            [stroke]:not([stroke]) {
                stroke: currentColor;
            }
        }

        ~ .form-control {
            padding-right: calc(var(--padding-x) + var(--icon-size) + 15px);
        }
    }

    .form-label {
        font-weight: 400;
        letter-spacing: -0.04em;
        max-width: calc(100% - var(--padding-x) * 2);
        transform-origin: 0% 50%;
        display: block;
        width: 100%;
        color: var(--color);
        opacity: 0.6;
    }

    .floated-labels {
        position: relative;

        ~ .form-label {
            transition:
                transform 0.3s $app-default-easing,
                letter-spacing 0.3s ease,
                opacity 0.3s ease;
            pointer-events: none;
            position: absolute;
            z-index: 1;
            display: inline-block;
            top: calc(var(--height) / 2);
            left: var(--padding-x);
            will-change: transform;
            transform: translate3d(0, -50%, 0);
        }

        &.floated-labels--active {
            &.input-group .form-label,
            ~ .form-label {
                transform: var(--active-label-transform);
            }
        }
    }

    .form-control {
        width: 100%;
        padding: 20px var(--padding-x) 10px;
        background-color: var(--bg-color);
        color: var(--color);
        letter-spacing: inherit;
        border: 0;
        border: 1px solid var(--border-color);
        border-radius: var(--border-radius);
        transition: border-color $app-default-duration $app-default-easing;

        @include hover {
            &:not(:focus) {
                border-color: #{rgba($primary, 0.5)};
            }
        }

        &:focus {
            outline: none;
            box-shadow: none;
            color: var(--color);
            border-color: var(--color);
        }

        &:-webkit-autofill {
            transition: none;
            caret-color: var(--color);
            border-color: #{rgba($primary, 0.5)};

            &,
            &:focus,
            &:active,
            &:hover {
                transition: background-color 9999s ease; // суперкостыль, чтобы пофиксить autofill в iOS
                color: var(--color) !important;
                -webkit-text-fill-color: var(--color) !important;
                box-shadow: inset 0 0 0 1000px var(--bg-color) !important;

                ~ .form-label {
                    transform: var(--active-label-transform);
                }
            }
        }

        &::-webkit-credentials-auto-fill-button {
            visibility: hidden;
        }

        &::placeholder {
            opacity: 0.4;
            color: currentColor;
        }

        &[type='file'] {
            @include visually-hidden();
        }

        &.is-error {
            border-color: $danger;

            ~ .form-label {
                &::before {
                    background-color: $danger;
                }
            }

            ~ .app-message {
                color: $danger;
            }
        }

        &:disabled {
            opacity: $btn-disabled-opacity;
            cursor: not-allowed;
            border-color: currentColor;

            ~ * {
                opacity: $btn-disabled-opacity;
            }
        }
    }

    .form-control:not(textarea) {
        height: var(--height);

        @-moz-document url-prefix() {
            height: calc((var(--height) + 5px));
        }
    }

    textarea.form-control {
        min-height: var(--height);
        padding-top: 32px;
        @include hidden-scrollbar();
    }

    .select-bg {
        ~ .dropdown-btn,
        ~ .form-label,
        ~ .input-group__icon {
            z-index: 2;
        }
    }
}
