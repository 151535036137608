.constructor-company-title {
    color: $primary;
    padding-bottom: 24px;
    margin-bottom: 32px;
    border-bottom: 1px solid #dcdcdd;

    @include small-laptop {
        padding-bottom: 16px;
        margin-bottom: 20px;
    }
}

.companies-list {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;

    @include small-laptop {
        gap: 30px;
    }

    @include mobile {
        gap: 20px;
    }
}

.company-item {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 140px;
    height: 80px;

    @include small-laptop {
        max-width: 90px;
        height: 40px;
    }

    img {
        height: 100%;
        width: auto;
    }
}
