.constructor-block {
    --constructor-offset-y: 90px;

    @media screen and (max-width: 1366px) {
        --constructor-offset-y: 64px;
    }

    @include mobile {
        --constructor-offset-y: 48px;
    }

    &.wysiwyg {
        h3 {
            @extend .text-xl;

            &:not(:first-child) {
                margin-top: var(--constructor-offset-y);
            }

            &:not(:last-child) {
                margin-bottom: 1.2em;

                @media screen and (max-width: 1366px) {
                    margin-bottom: 1.5em;
                }
            }
        }
    }

    + .constructor-block {
        margin-top: var(--constructor-offset-y);
    }
}

.program-detail-popup {
    .constructor-block {
        margin-top: 70px;

        @media screen and (max-width: 1366px) {
            margin-top: 44px;
        }

        @include tablet {
            margin-top: 50px;
        }

        @include mobile {
            margin-top: 40px;
        }
    }

    .news-detail-wrapper {
        transform: none;
    }
}
