.other-events-section {
    min-height: 100vh;
    min-height: 100svh;
    width: 100%;
    z-index: 4;
    display: flex;
    gap: var(--grid-gap);
    color: $white;
    padding-top: calc(var(--header-height) + 70px);
    padding-bottom: 80px;
    position: relative;
    --padding: 112px;

    .swiper-wrapper {
        transition-timing-function: easeInOutCubic;
    }

    @include tablet {
        gap: 80px;
        padding-bottom: 182px;
        padding-top: 112px;
        flex-direction: column;
        min-height: unset;
    }

    @include mobile {
        padding-top: 80px;
        padding-bottom: 142px;
    }
}

.other-events-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include grid-columns(5);
    flex-shrink: 0;
    gap: 30px;

    @include tablet {
        width: 100%;
        flex-direction: row;
    }

    @include mobile {
        flex-direction: column;
        gap: 24px;
    }
}

.other-events-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 60px;
    flex: 1 1 auto;
    min-width: 0;

    @include tablet {
        width: 100%;
    }
}

.other-events-title {
    @include grid-columns(4, max-width);

    @include tablet {
        @include grid-columns(3, max-width);
    }

    @include mobile {
        max-width: 90%;
    }
}

.other-events-description-block {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 85px;

    @include tablet {
        align-items: start;
        gap: 40px;
    }
}

.other-events-description {
    max-width: 400px;

    @include mobile {
        font-size: 14px;
    }
}

.other-events-slider {
    width: 100%;
    cursor: grab;
    display: flex;
    gap: var(--grid-gap);
    user-select: none;
    overflow: hidden;
    overflow: clip;
    touch-action: pan-y;

    &:active {
        cursor: grabbing;
    }
}

.other-events-slider-slide {
    @include grid-columns(1);
    display: flex;
    align-items: flex-end;
    flex-shrink: 0;

    @include tablet {
        @include recalculate-grid(8);
        @include grid-columns(2);
    }

    @media screen and (max-width: 576px) {
        width: 25%;
    }

    &:first-child {
        @include grid-columns(4);

        @media screen and (min-width: 2100px) {
            @include grid-columns(3);
        }

        @media screen and (max-width: 576px) {
            width: calc(75% - var(--grid-gap));
        }

        .news-slide-title {
            color: $primary;
        }

        .other-events-slide-bottom-wrapper {
            opacity: 1;
            transition: opacity 0.3s 0.3s ease;
        }

        button.other-events-slider-slide-content {
            cursor: auto;
        }

        @include hover {
            .news-slide-btn {
                opacity: 1;
            }
        }
    }

    &:nth-child(5) {
        ~ .other-events-slider-slide {
            display: none;
        }
    }

    &:nth-child(4) {
        @include tablet {
            display: none;
        }
    }

    &:nth-child(3) {
        @media screen and (max-width: 576px) {
            display: none;
        }
    }
}

.other-events-slider-slide-content {
    --aspect-ratio: calc(580 / 722);
    border-radius: 18px;
    display: block;
    position: relative;
    overflow: hidden;
    overflow: clip;
    text-align: left;

    @include tablet {
        --aspect-ratio: calc(356 / 436);
    }
}

.other-events-slider-slide-content__item {
    display: flex;
    justify-content: center;
    border-radius: inherit;
    contain: strict;
}

.other-events-slider-slide-content-el {
    position: relative;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    border-radius: inherit;
}

.other-events-slider-slide-content-el-inner {
    border-radius: inherit;
    width: 100%;
    height: 100%;
    padding: 35px;
    display: flex;
    align-items: flex-end;

    @include tablet {
        padding: 24px;
    }

    @include mobile {
        padding: 16px;
    }
}

.news-slide-btn {
    z-index: 1;
}

.other-events-slider-next-btn,
.other-events-slider-prev-btn {
    --size: 48px;
    min-width: var(--size);
    min-height: var(--size);
    border: 1px solid $white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:
        background-color 0.3s easeInOutCubic,
        transform 0.3s easeInOutCubic;
    cursor: pointer;

    @include tablet {
        --size: 30px;

        svg {
            width: 12px;
        }
    }

    @include hover {
        background-color: $white;

        path {
            fill: $dark-blue;
        }

        &:active {
            transform: scale(0.95);
        }
    }

    path {
        transition: fill 0.3s ease;

        fill: $white;
    }
}

.other-events-slide-bottom-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 23px;
    opacity: 0;
    transition: opacity 0s;
}

.other-events-slider-prev-btn {
    svg {
        transform: rotate(180deg);
    }
}

.other-events-pagination-wrapper {
    display: flex;
    align-items: center;
    gap: 27px;

    @media screen and (max-width: 1366px) {
        gap: 0;
    }

    @include tablet {
        position: absolute;
        bottom: 112px;
        left: 50%;
        height: 40px;
        transform: translateX(-50%);
    }

    @include mobile {
        gap: 10px;
        bottom: 80px;
    }
}

.other-events-slider-img-wrapper {
    border-radius: inherit;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.6) 100%);
    }
}

.other-events-slider-img {
    border-radius: inherit;
}
