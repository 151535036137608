.search-input {
    border: 1px solid $dark;
    background-color: transparent;
    border-radius: 33px;
    padding: 0px 15px;
    outline: none;
    color: $dark;
    height: 40px;
    width: 100%;
    position: relative;

    &::placeholder {
        color: $dark;
    }
}

.search-input-wrapper {
    position: relative;
    width: 250px;
}

.search-input-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    --size: 30px;
    width: var(--size);
    height: var(--size);
    background-color: $white;
    top: 5px;
    right: 5px;
    position: absolute;
    border-radius: 50%;
    transition: opacity 0.3s ease;

    svg {
        width: 18px;
    }
}

.search-input-btn.is-submitting {
    opacity: 0.5;
}
