.error-page-layour {
    min-height: 100vh;
    min-height: 100svh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: var(--header-height);
    padding-bottom: 100px;
    padding-inline: var(--offset-x);
    text-align: center;
    background-color: $blue;
    color: $white;

    @include tablet {
        flex-direction: column;
        padding-bottom: 60px;
    }

    @include mobile {
        gap: 40px;
        padding-bottom: 20px;
    }
}

.error-page-layour__wrapper {
    position: relative;
    padding-top: calc(var(--header-height) + 120px);
    z-index: 4;

    @include tablet {
        padding-top: 0;
    }
}

.error-page-layour__btn {
    padding-top: 60px;

    @include tablet {
        padding-top: 30px;
    }
}

.error-page-layour__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 60px;

    @include tablet {
        gap: 25px;
    }
}

.error-page-layour__inner-text {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include tablet {
        gap: 20px;
    }
}

.error-page-layour__inner-text__title {
    color: #fff;
    text-align: center;
}

.error-page-layour__inner-text__descr {
    opacity: 0.6;
    width: 55%;
    margin: 0 auto;
}
