.mission__section {
    --padding: 112px;
    width: 100%;
    position: relative;
    z-index: 4;
    padding-top: calc(var(--header-height) + 80px);
    padding-bottom: 80px;
    gap: 120px;
    display: flex;
    flex-direction: column;

    @include tablet {
        min-height: unset;
        padding-bottom: var(--padding);
        padding-top: var(--padding);
        gap: 80px;
    }

    @include mobile {
        --padding: 80px;
        gap: 65px;
    }
}

.mission__wrapper {
    display: flex;
    flex-direction: column;
    gap: 120px;

    @include tablet {
        gap: 80px;
    }

    @include mobile {
        gap: 65px;
    }
}

.mission-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include mobile {
        flex-direction: column;
        gap: 24px;
    }
}

.mission-title {
    display: flex;

    @include grid-columns(3);
}

.mission-description-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
    @include grid-columns(4);

    @include tablet {
        width: 50%;
        flex-direction: column;
        gap: 40px;
    }

    @include mobile {
        gap: 30px;
    }
}

.mission-description {
    max-width: 200px;

    @include small-laptop {
        max-width: 175px;
    }

    @include tablet {
        max-width: 175px;
    }

    @include mobile {
        max-width: 170px;
    }
}

.mission-section-btn {
    width: fit-content;
}

.mission-bottom {
    width: 100%;
}

.mission-cards__list {
    display: flex;
    flex-direction: row;
    gap: var(--grid-gap);
    width: 100%;

    .mission-card__item {
        flex: 1;
        max-width: calc(100% / 3 - var(--grid-gap));

        @include tablet {
            flex: none;
            max-width: 50%;
            @include grid-columns(6, width);
        }

        @include mobile {
            max-width: calc(100% - var(--offset-x) * 2);
        }
    }
    .mission-card__item-descr {
        width: 100%;
    }
}

.mission-slider-slide {
    max-width: 640px;
    border-radius: 18px;
    position: relative;
    overflow: hidden;

    @include tablet {
        @include grid-columns(6, max-width);
    }

    @include mobile {
        max-width: calc(100% - var(--offset-x) * 2);
    }
}

.mission-card__item {
    --aspect-ratio: calc(29 / 24);
    @include grid-columns(4, width);

    aspect-ratio: var(--aspect-ratio);
    flex-shrink: 0;
    border-radius: 18px;
    overflow: hidden;

    @include small-laptop {
        --aspect-ratio: calc(196 / 163);
    }

    @include tablet {
        --aspect-ratio: calc(89 / 74);
        @include grid-columns(6, width);
    }
    @include mobile {
        --aspect-ratio: calc(41 / 34);
        max-width: 100%;
        width: 90%;
    }
}

.mission-card__item-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    color: $white;
    padding: 40px;
}

.mission-card__item-title {
    font-weight: 400;
}

.mission-card__item-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.6) 100%);
    }
}

.mission-card__item-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mission-card__item-descr {
    display: flex;

    @include grid-columns(3);

    @include tablet {
        @include grid-columns(4);
    }

    @include mobile {
        @include recalculate-grid(3);
        max-width: 210px;
    }
}

.mission-card__item-slide {
    @include tablet {
        width: 100%;
    }
}

.mission-pagination-wrapper {
    margin-top: 60px;

    @include mobile {
        margin-top: 30px;
    }

    @include pagination-dark();
}
