.favorite-btn-mobile {
    --size: 50px;
    --offset: 16px;
    position: fixed;
    width: var(--size);
    height: var(--size);
    bottom: calc(var(--offset));
    right: var(--offset);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition:
        background-color 0.3s ease,
        transform 0.3s ease;
    cursor: pointer;
    background-color: $dark;
    pointer-events: all;

    &.is-translated {
        transform: translateY(-62px);
    }
}

.favorite-btn {
    @include clickable();
}

.star-btn-svg {
    width: 24px;
    height: 24px;
    path {
        stroke: $white;
        opacity: 1;
    }
}

.favorite-btn-svg-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
