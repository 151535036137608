.fullscreen-slider-section {
    width: 100%;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $white;
    gap: 120px;

    .swiper-wrapper {
        transition-timing-function: easeInOutCubic;
        will-change: transform;
    }

    @include tablet {
        gap: 80px;
        background-color: $dark-blue;
        min-height: unset;
    }

    .dot {
        position: absolute;
        left: 49%;
        top: 50%;
        transform: translateX(-50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        transition:
            transform 0.65s easeInOutCubic,
            opacity 0.3s ease-out;
    }
}

.participation-page {
    .fullscreen-slider-section {
        margin-top: 40px;

        @media screen and (max-width: 1366px) {
            margin-top: 30px;
        }
    }
}

.fullscreen-slider-bottom {
    position: relative;
}

.fullscreen-slider {
    width: 100%;
    cursor: grab;

    &:active {
        cursor: grabbing;
    }
}

.fullscreen-slider-slide {
    position: relative;
    overflow: hidden;
}

.fullscreen-slider-img {
    --scale: 1.1;
    --aspect-ratio: calc(1920 / (800 * var(--scale)));
    overflow: hidden;
    overflow: clip;
    will-change: transform;

    @include vertical-tablet {
        --aspect-ratio: calc(768 / (900 * var(--scale)));
    }

    @include vertical-mobile {
        --aspect-ratio: calc(360 / (520 * var(--scale)));
    }
}

.fullscreen-slider-img-inner {
    will-change: transform;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.2;
    }
}

.fullscreen-slider-next-btn,
.fullscreen-slider-prev-btn {
    --size: 48px;
    width: var(--size);
    height: var(--size);
    flex-shrink: 0;
    border: 1px solid $white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:
        background-color 0.3s ease,
        transform 0.2s ease;
    cursor: pointer;

    @include tablet {
        --size: 30px;

        svg {
            width: 12px;
        }
    }

    @include hover {
        background-color: $white;

        path {
            fill: $dark-blue;
        }

        &:active {
            transform: scale(0.95);
        }
    }

    path {
        transition: fill 0.3s ease;

        fill: $white;
    }
}

.fullscreen-slider-prev-btn {
    svg {
        transform: rotate(180deg);
    }
}

.fullscreen-slider-pagination-wrapper {
    position: absolute;
    bottom: 50px;
    left: 0;
    z-index: 4;
    width: 100%;
    display: flex;
    justify-content: center;
}

.fullscreen-slider-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 27px;

    @include mobile {
        gap: 10px;
        justify-content: space-between;
    }
}

.dots {
    overflow: hidden;
    position: relative;
    width: 300px;
    height: 10px;

    @include mobile {
        width: 220px;
    }
}

.fulscreen-slider-text {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    position: absolute;
    max-width: 400px;
    text-align: center;

    @include mobile {
        max-width: 300px;
        width: 100%;
    }
}

.fulscreen-slider-text-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}
