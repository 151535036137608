.reviews-section {
    min-height: 100vh;
    min-height: 100svh;
    width: 100%;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $dark;
    padding-top: calc(var(--header-height) + 80px);
    padding-bottom: 80px;
    gap: 90px;
    --padding: 112px;
    position: relative;

    .swiper-wrapper {
        transition-timing-function: easeInOutCubic;
    }

    @media screen and (max-width: 1366px) {
        gap: 60px;
    }

    @include tablet {
        min-height: unset;
        gap: 80px;
        padding-bottom: var(--padding);
        padding-top: var(--padding);
        background-color: $light-blue;
    }

    @include mobile {
        --padding: 80px;
    }
}

.reviews-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include mobile {
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }
}

.reviews-bottom {
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.reviews-title {
    @include grid-columns(3, max-width);

    @include mobile {
        max-width: 100%;
    }
}

.reviews-description-block {
    display: flex;
    align-items: end;
    gap: 85px;
    margin-right: 7%;

    @include tablet {
        flex-direction: column;
        align-items: start;
        gap: 40px;
    }
}

.reviews-description {
    max-width: 360px;
}

.reviews__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--grid-gap);
    width: 100%;
    justify-content: space-between;
    height: 100%;

    .reviews-slider-slide.swiper-slide {
        width: calc((100% / 2) - var(--grid-gap));
        height: auto;

        @include mobile {
            width: 100%;
        }
    }
}

.reviews-slider {
    width: 100%;
    height: 450px;
    cursor: grab;

    &:active {
        cursor: grabbing;
    }

    @media screen and (max-width: 1366px) {
        height: 300px;
    }

    @include tablet {
        height: 360px;
    }

    @include mobile {
        height: 320px;
    }
}

.reviews-slider-slide.swiper-slide {
    color: $dark;
    width: 800px;
    border-radius: 18px;
    position: relative;
    overflow: hidden;
    background-color: $white;
    display: flex;
    padding: 50px;
    gap: 50px;

    @media screen and (max-width: 1366px) {
        gap: 32px;
        padding: 32px;
        width: 600px;
    }

    @include tablet {
        justify-content: space-between;
        min-width: 720px;
    }

    @include mobile {
        max-width: calc(100% - var(--offset-x) * 2);
        padding: 20px;
        min-width: unset;
    }
}

.reviews-slider-img-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 14px;
    --aspect-ratio: calc(230 / 330);

    @media screen and (max-width: 1366px) {
        --aspect-ratio: calc(154 / 220);
    }

    @include mobile {
        display: none;
    }
}

.reviews-slider-slide-inner {
    min-width: 260px;

    @media screen and (max-width: 1366px) {
        min-width: 164px;
    }

    @include tablet {
        min-width: 206px;
    }
}

.reviews-slider-slide {
    position: relative;
}

.reviews-slider-slide-inner-mobile {
    min-width: 104px;
    height: fit-content;
    overflow: hidden;
    border-radius: 8px;
    background: linear-gradient(180deg, #05164a, #082e74 78.81%);
}

.reviews-slider-img-wrapper-mobile {
    display: none;

    @include mobile {
        display: flex;
        --aspect-ratio: 1;
        position: relative;
    }
}

.reviews-slider-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.reviews-slider-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mobile {
        gap: 10px;
    }
}

.reviews-slider-content-top {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.reviews-slider-name {
    @include mobile {
        font-size: 14px;
    }
}

.reviews-slider-name-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 220px;

    @media screen and (max-width: 1366px) {
        gap: 12px;
    }
}

.reviews-slider-name-block-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;

    @include mobile {
        flex-direction: column;
    }
}

.review-quote {
    color: $primary;
}

.reviews-slider-job {
    opacity: 0.6;
}

.reviews-slider-video-block {
    display: flex;
    gap: 14px;
    align-items: flex-start;
    cursor: pointer;
    height: fit-content;

    @include hover {
        circle {
            fill: $primary;
        }

        path {
            fill: $white;
        }
    }

    @media screen and (max-width: 1366px) {
        gap: 8px;
    }
}

.video-review-text {
    color: $primary;
    height: 36px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1366px) {
        height: 24px;
    }
}

.video-review-logo {
    width: 36px;

    @media screen and (max-width: 1366px) {
        width: 24px;
        height: 24px;
    }

    circle,
    path {
        transition: fill 0.3s ease;
    }
}

.reviews-slider-next-btn,
.reviews-slider-prev-btn {
    width: 48px;
    height: 48px;
    border: 1px solid $dark;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:
        background-color 0.3s ease,
        transform 0.3s ease;
    flex-shrink: 0;
    cursor: pointer;

    @include tablet {
        width: 30px;
        height: 30px;

        svg {
            width: 12px;
        }
    }

    @include hover {
        background-color: $dark;

        path {
            fill: $white;
        }

        &:active {
            transform: scale(0.95);
        }
    }

    path {
        transition: fill 0.3s ease;

        fill: $dark;
    }
}

.reviews-slider-prev-btn {
    svg {
        transform: rotate(180deg);
    }
}

.reviews-pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 27px;

    @include mobile {
        gap: 10px;
    }

    .dots {
        overflow: hidden;
        position: relative;
        width: 300px;
        height: 10px;

        @include mobile {
            width: 220px;
        }
    }

    .dot {
        position: absolute;
        left: 49%;
        top: 50%;
        transform: translateX(-50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        transition:
            transform 0.65s easeInOutCubic,
            opacity 0.3s ease-out;
    }
}
