.blockquote-img-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 14px;
    --aspect-ratio: calc(230 / 307);

    @media screen and (max-width: 1366px) {
        --aspect-ratio: calc(154 / 220);
    }

    @include mobile {
        display: none;
    }
}

.blockquote-slide-inner-mobile {
    min-width: 104px;
    height: fit-content;
}

.blockquote-slide-inner {
    min-width: 260px;

    @media screen and (max-width: 1400px) {
        min-width: 164px;
    }

    @include tablet {
        min-width: 206px;
    }
}

.blockquote-slide {
    position: relative;
}

.blockquote-img-wrapper-mobile {
    display: none;

    @include mobile {
        display: flex;
        --aspect-ratio: 1;
        position: relative;
        overflow: hidden;
        border-radius: 8px;
    }
}

.blockquote-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blockquote {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $light-blue;
    gap: 60px;

    @include mobile {
        gap: 32px;
    }
}

.blockquote-content-top {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.blockquote-name {
    @include mobile {
        font-size: 14px;
    }
}

.blockquote-name-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 220px;

    @media screen and (max-width: 1366px) {
        gap: 12px;
    }
}

.blockquote-name-block-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;

    @include mobile {
        flex-direction: column;
    }
}

.review-quote {
    color: $primary;
}
