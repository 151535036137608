.apply-registration-form-popup {
    .menu-popup-close-btn {
        position: absolute;
        right: var(--offset-x);
        top: var(--offset-x);
    }

    .popup-container {
        right: 0;
        background-color: $white;
        height: 100%;
        width: 950px;
        display: flex;
        flex-direction: column;
        padding: 70px;
        gap: 50px;
        padding-top: 164px;

        @media screen and (max-width: 1600px) {
            gap: 40px;
            padding: 36px;
            padding-top: 96px;
            width: 700px;
        }

        @include small-laptop {
            width: 640px;
        }

        @include tablet {
            width: 600px;
            padding: 22px;
            padding-top: 120px;
        }

        @include mobile {
            width: 100%;
            padding: 16px;
            padding-top: 90px;
            gap: 30px;
            padding-bottom: 50px;
        }
    }

    .checkbox__text {
        color: $dark-gray;

        .form-checkbox-link {
            color: $dark;
            text-decoration: underline;
            cursor: pointer;
            transition: color 0.3s ease;

            @include hover {
                color: $primary;
            }
        }
    }
}

.apply-registration-form-popup-title {
    color: $primary;
}

.apply-registration-input-block {
    display: flex;
    flex-direction: column;
    gap: 18px;
    position: relative;

    @media screen and (max-width: 1600px) {
        gap: 12px;
    }

    .app-message {
        position: absolute;
        right: 0;
        top: 0;
        left: auto;
        color: $danger;

        @media screen and (max-width: 1600px) {
            top: -2px;
        }

        @include tablet {
            top: -4px;
        }
    }
}

.apply-registration-form-popup-input {
    width: 100%;
    border-radius: 12px;
    padding: 24px;
    border: 1px solid $border-color;
    outline: none;
    box-shadow: inset 100px 100px 100px 100px $white;

    @media screen and (max-width: 1600px) {
        padding: 16px;
    }

    &::placeholder {
        color: $border-color;
    }

    transition: border-color 0.3s ease;

    @include hover {
        border-color: $dark;
    }

    &:focus {
        border-color: $dark;
    }

    &.is-error {
        border-color: $danger;
    }
}

.apply-registration-form-popup-textarea {
    textarea {
        width: 100%;
        border-radius: 12px;
        padding: 24px;
        border: 1px solid $border-color;
        outline: none;
        box-shadow: inset 100px 100px 100px 100px $white;
        height: 100px;
        resize: none;
        display: block;

        @media screen and (max-width: 1600px) {
            padding: 16px;
        }

        &::placeholder {
            color: $border-color;
        }

        transition: border-color 0.3s ease;

        @include hover {
            border-color: $dark;
        }

        &:focus {
            border-color: $dark;
        }

        &.is-error {
            border-color: $danger;
        }
        @include small-scrollbar();
    }
}

.apply-registration-form-popup-form {
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;

    .checkbox {
        @include mobile {
            padding-right: 0;
        }
    }
}

.apply-registration-form-fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-gap: 40px;

    @media screen and (max-width: 1600px) {
        grid-gap: 20px;
    }

    @include mobile {
        grid-template-columns: 1fr;
    }
}

.full-width-field {
    grid-column: span 2;

    @include mobile {
        grid-column: span 1;
    }
}

.apply-registration-form-popup-privacy {
    display: flex;
    gap: 40px;
}

.form-checkbox-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.apply-registration-detail-popup-response {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 28px;
    flex-direction: column;
}

.apply-registration-form-error-message {
    opacity: 0.6;
    width: 50%;
}

.form-steps {
    display: flex;
    gap: 20px;
    padding: 14px 0;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;

    @media screen and (max-width: 1600px) {
        gap: 16px;
    }

    @include tablet {
        padding: 10px 0;
    }

    @include mobile {
        padding: 12px 0;
        overflow-x: auto;
        overflow-y: hidden;
        @include hidden-scrollbar();
        min-height: 54px;
        margin-inline: calc(-1 * var(--offset-x));
        width: calc(100% + var(--offset-x) * 2);
        padding-inline: var(--offset-x);
    }
}

.form-step-item {
    border-radius: 50px;
    background-color: $primary;
    height: 38px;
    position: relative;
    color: $white;
    display: flex;
    align-items: center;
    padding-left: 45px;
    padding-right: 16px;
    padding-top: 14px;
    padding-bottom: 14px;
    transition: background-color 0.3s ease;

    @media screen and (max-width: 1600px) {
        height: 30px;
        padding-right: 12px;
        padding-left: 33px;
    }

    @include mobile {
        white-space: nowrap;
    }

    &.disabled {
        background-color: $white;
        color: $border-color;
        border: 1px solid $border-color;
        border-radius: 50px;

        .form-step-item-circle {
            color: $white;
            background-color: $border-color;
        }
    }
}

.form-step-item-circle {
    position: absolute;
    left: 3px;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary;
    transition:
        background-color 0.3s ease,
        color 0.3s ease;

    @media screen and (max-width: 1600px) {
        width: 24px;
        height: 24px;
    }
}

.apply-registration-form-info {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 530px;
    margin-bottom: 60px;

    @media screen and (max-width: 1600px) {
        margin-bottom: 40px;
        gap: 20px;
    }
}

.apply-registration-form-info-title {
    color: $primary;
}

.schema-popup-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.schema-popup-btns__back-wrapper {
    display: flex;
    align-items: center;
    gap: 14px;
    position: absolute;
    top: var(--offset-x);
    left: var(--offset-x);

    @media screen and (max-width: 1366px) {
        gap: 10px;
    }
}

.schema-popup-btns__back {
    transform: rotate(90deg);
    right: unset;
    --size: 46px;
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $dark;
    border-radius: 50%;
    transition:
        background-color 0.3s ease,
        stroke 0.3s ease;

    @media screen and (max-width: 1600px) {
        --size: 36px;
    }

    @include hover {
        background-color: $dark;
        path {
            stroke: $white;
        }
    }
}

.apply-registration-form-button {
    margin-left: auto;
    margin-top: 60px;

    @media screen and (max-width: 1600px) {
        margin-top: 30px;
    }

    @include mobile {
        margin-top: 40px;
        margin-right: auto;
        margin-left: 0;
    }
}

.apply-registration-form-submit-block {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
}

.apply-registration-form-popup-privacy {
    max-width: 320px;
}

.textarea-wrapper {
    overflow: hidden;
    border-radius: 12px;
}

.form-bottom-info-block {
    display: flex;

    @include mobile {
        flex-direction: column;
    }
}

.form-bottom-info-block-text {
    margin-top: auto;

    @include mobile {
        margin-top: 20px;
    }
}

.apply-registration-detail-popup-response-text {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}
