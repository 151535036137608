.responsive {
    position: relative;
    height: 0;
    padding-top: calc(1 / var(--aspect-ratio, calc(16 / 9)) * 100%);
    width: 100%;
}

.responsive__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img,
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: var(--object-fit, cover);
    }
}

img.responsive__item,
video.responsive__item {
    object-fit: var(--object-fit, cover);
}
