.how-it-was-section {
    min-height: 100vh;
    min-height: 100svh;
    width: 100%;
    position: relative;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $white;
    padding-top: calc(var(--header-height) + 80px);
    padding-bottom: 80px;
    gap: 120px;

    .swiper-wrapper {
        transition-timing-function: easeInOutCubic;
    }
    --padding: 112px;

    @include tablet {
        gap: 80px;
        background-color: $dark-blue;
        min-height: unset;
        padding-bottom: var(--padding);
        padding-top: var(--padding);
    }

    @include mobile {
        --padding: 80px;
    }
}

.participation-page {
    .how-it-was-section,
    .faq-section {
        background-color: $light-blue;
        color: $dark;
    }

    .slider-next-btn,
    .slider-prev-btn {
        border-color: $dark;

        @include hover {
            background-color: $dark;

            path {
                fill: $white;
            }

            &:active {
                transform: scale(0.95);
            }
        }

        path {
            transition: fill 0.3s ease;

            fill: $dark;
        }

        &:disabled {
            opacity: $btn-disabled-opacity;
            pointer-events: none;
        }
    }
}

.how-it-was-top {
    display: flex;
    justify-content: space-between;

    @include mobile {
        flex-direction: column;
        gap: 24px;
    }
}

.how-it-was__list {
    display: flex;
    flex-direction: row;
    height: 100%;
    gap: var(--grid-gap);

    .how-it-was-slider-slide {
        width: 100%;
        height: 100%;
        flex-shrink: 0;
    }
}

.how-it-was-bottom {
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.how-it-was-title {
    @include grid-columns(2, max-width);

    @include tablet {
        @include grid-columns(3, max-width);
    }

    @include mobile {
        max-width: 100%;
    }
}

.how-it-was-description-block {
    display: flex;
    align-items: end;
    gap: 85px;
    @include grid-columns(4);

    @include tablet {
        flex-direction: column;
        align-items: start;
        gap: 40px;

        @include grid-columns(6);
        @include grid-offset(padding-right, 1);
    }

    @include mobile {
        width: 100%;
    }
}

.how-it-was-description {
    max-width: 350px;
}
.how-it-was-slider {
    width: 100%;
    cursor: grab;

    &:active {
        cursor: grabbing;
    }
}

.how-it-was-slider-slide {
    max-width: 640px;
    border-radius: 18px;
    position: relative;
    overflow: hidden;

    @include tablet {
        max-width: calc(100% - var(--offset-x) * 2);
    }
}

.how-it-was-slider-img {
    --aspect-ratio: calc(640 / 400);

    @media screen and (max-width: 1400px) {
        --aspect-ratio: calc(430 / 270);
    }

    @include tablet {
        --aspect-ratio: calc(724 / 450);
    }

    @include mobile {
        --aspect-ratio: calc(330 / 200);
    }
}

.how-it-was-slider-img-inner {
    will-change: transform;
    scale: 1.1;
}

.slider-next-btn,
.slider-prev-btn {
    --size: 48px;
    width: var(--size);
    height: var(--size);
    border: 1px solid $white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:
        background-color 0.3s ease,
        transform 0.2s ease;
    cursor: pointer;

    @include tablet {
        --size: 30px;

        svg {
            width: 12px;
        }
    }

    @include hover {
        background-color: $white;

        path {
            fill: $dark-blue;
        }

        &:active {
            transform: scale(0.95);
        }
    }

    path {
        transition: fill 0.3s ease;

        fill: $white;
    }
}

.slider-prev-btn {
    svg {
        transform: rotate(180deg);
    }
}

.pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 27px;

    @include mobile {
        gap: 10px;
        justify-content: space-between;
    }
}

.dots {
    overflow: hidden;
    position: relative;
    width: 300px;
    height: 10px;

    @media screen and (max-width: 1366px) {
        width: 230px;
    }

    @include mobile {
        width: 220px;
    }
}

.dot {
    position: absolute;
    left: 49%;
    top: 50%;
    transform: translateX(-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: currentColor;
    transition:
        transform 0.65s easeInOutCubic,
        opacity 0.3s ease-out;
}
