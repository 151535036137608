.dropdown {
    position: relative;
    display: flex;

    &.dropdown--opened {
        .dropdown-content {
            opacity: 1;
            pointer-events: auto;
            transform: none;
        }
    }
}

.dropdown-toggler {
    cursor: pointer;
}

.dropdown-content {
    --offset: 10px;
    position: absolute;
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    transform: translate(0, var(--offset));
    transition:
        opacity 0.25s $app-default-easing,
        visibility 0.25s $app-default-easing,
        transform 0.25s $app-default-easing,
        color 0.3s ease;
    min-width: 100%;
    max-width: 100vw;
    max-height: 400px;
    overflow-y: auto;
    @include small-scrollbar();

    &.bottom-left {
        top: calc(100% + var(--offset));
        left: 0;
    }

    &.bottom-center {
        top: calc(100% + var(--offset));
        left: 50%;

        .dropdown-content-inner {
            transform: translate(-50%, 0);
        }
    }

    &.bottom-right {
        top: calc(100% + var(--offset));
        right: 0;
    }

    &.top-left {
        bottom: calc(100% + var(--offset));
        left: 0;
    }

    &.top-right {
        bottom: calc(100% + var(--offset));
        right: 0;
    }
}

.dropdown-content-inner {
    cursor: pointer;
}
