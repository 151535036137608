@include configurator() {
    .map-dropdown {
        position: absolute;
        z-index: 7;
        visibility: hidden;
        opacity: 0;

        &.is-visible {
            visibility: visible;
            opacity: 1;

            .map-dropdown-inner {
                visibility: visible;
                opacity: 1;
            }
        }

        .constructor-btn-blue {
            --bg-color-default: #{$primary};
            --bg-color-hover: #{darken($primary, 8%)};
            --bg-color-active: #{darken($primary, 10%)};

            --border-color-default: #{$primary};
            --border-color-hover: #{darken($primary, 8%)};
            --border-color-active: #{darken($primary, 10%)};
        }
    }

    .map-dropdown-inner {
        padding: 20px;
        display: flex;
        gap: 42px;
        border-radius: 16px;
        background-color: #fff;
        visibility: hidden;
        opacity: 0;
        transition:
            opacity 0.2s ease,
            visibility 0.2s ease;
    }

    .map-dropdown-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 24px;
        min-width: 288px;

        @include laptop {
            min-width: 190px;
        }

        @include mobile {
            display: contents;
        }
    }

    .map-dropdown-title {
        color: $blue;
    }

    .pavilion-prop {
        --offset: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        &:not(:first-child) {
            padding-top: var(--offset);
            margin-top: var(--offset);
            border-top: 1px solid rgba(#000, 0.1);
        }
    }

    .pavilion-prop--dropdown {
        --offset: 14px;
    }

    .pavilion-prop-key--dropdown {
        opacity: 0.3;
    }

    .pavilion-prop-key {
        opacity: 0.6;
    }

    .map-dropdown-right {
        width: 288px;
        position: relative;

        @include laptop {
            width: 190px;
        }

        @include mobile {
            width: 100%;
        }
    }

    .map-dropdown-image {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 84.6%;
        border-radius: 16px;
        overflow: hidden;

        @include laptop {
            padding-top: 105%;
        }

        @include mobile {
            padding-top: 210px;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .map-dropdown-button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
        display: inline-flex;
        align-items: center;

        .text-xxs {
            line-height: 0;
        }
    }

    .map-popup-inner {
        padding: 20px;
        display: flex;
        gap: 42px;
        border-radius: 16px;
        background-color: #fff;
    }
}
