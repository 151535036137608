.organize-section-wrapper {
    display: flex;
    flex-direction: column;
    z-index: 4;
    padding-top: calc(var(--header-height) + 120px);
    padding-bottom: 60px;
    gap: 120px;

    @include tablet {
        flex-direction: column;
        gap: 90px;
        background-color: $white;
        justify-content: flex-start;
        min-height: unset;
    }

    @include mobile {
        gap: 64px;
        padding-bottom: 80px;
        padding-top: 80px;
    }
}

.organize-section-content {
    width: 100%;
    display: flex;
    height: 100%;
    gap: var(--grid-gap);

    @include tablet {
        flex-direction: column;
        @include recalculate-grid(8);
    }
}

.organize-section-title {
    @include grid-columns(3);
}

.organize-section-content-right {
    @include grid-columns(9);
    margin-inline: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 60px;

    @media screen and (max-width: 1600px) {
        gap: 40px;
    }

    @include tablet {
        width: 100%;
    }
}

.organize-section-content-left {
    display: flex;
    flex-direction: column;
    gap: 50px;
    height: 100%;
    @include grid-columns(3);

    @include tablet {
        display: none;
    }
}

.organize-section-categories {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.organize-section-category-item {
    display: flex;
    gap: 7px;
    cursor: pointer;
    transition: color 0.3s ease;
    position: relative;

    &::before {
        content: '';
        height: 5px;
        width: 5px;
        background-color: $primary;
        border-radius: 100%;
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translate(-10px, -50%);
        opacity: 0;
        transition:
            transform 0.5s ease,
            opacity 0.5s ease;

        @media screen and (max-width: 1600px) {
            left: -10px;
            top: 9px;
        }
    }

    &.is-active {
        color: $primary;

        &::before {
            transform: translate(0, -50%);
            opacity: 1;
        }
    }
}

.organize-section-category-count {
    opacity: 0.6;
}

.organize-content-category-block {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding-top: 60px;
    border-top: 1px solid #dcdcdd;

    @media screen and (max-width: 1600px) {
        gap: 35px;
    }

    @include tablet {
        gap: 35px;
    }

    @include mobile {
        padding-top: 40px;
    }

    &:first-child {
        border: none;
        padding-top: 0;
    }
}

.organize-content-category-list {
    --columns: 4;
    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr);
    gap: 20px;

    @media screen and (max-width: 1600px) {
        display: flex;
        flex-wrap: wrap;
        gap: var(--grid-gap);
    }

    @include mobile {
        width: 100%;
    }

    .pagination-wrapper {
        margin-top: 30px;
    }
}

.organize-content-category-block-top {
    display: flex;
    justify-content: space-between;

    @include mobile {
        flex-direction: column;
        width: 100%;
        gap: 36px;
    }
}

.category-title {
    color: $primary;

    @include mobile {
        &.text-xl {
            line-height: 1.2;
        }
    }
}

.organize-content-category-item {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    border-radius: 18px;
    background-color: $white;
    transition: background-color 0.3s ease;
    padding: 10px 20px;
    border: 1px solid #dcdcdd;
    height: 110px;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    @media screen and (max-width: 1600px) {
        @include grid-columns(3);
    }

    @include tablet {
        padding: 15px;
        @include grid-columns(4);
    }

    @include mobile {
        width: 100%;
    }

    @include hover {
        background-color: $light-blue;

        &:active {
            .organize-item-btn {
                transform: scale(0.8);
            }
        }

        .organize-item-btn {
            opacity: 1;
        }
    }

    .organize-item-btn {
        transform: rotate(-45deg);

        svg {
            width: 13px;
            height: 13px;
            path {
                fill: $white;
            }
        }
    }
}

.category-description {
    max-width: 50%;

    @include mobile {
        max-width: 100%;
    }
}

.organize-item-btn {
    --size: 26px;
    --offset: 10px;
    width: var(--size);
    height: var(--size);
    background-color: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: var(--offset);
    top: var(--offset);
    opacity: 0;
    border-radius: 50%;
    transition:
        opacity 0.3s ease,
        transform 0.2s ease;
}

.organize-left-btn {
    width: fit-content;
}

.organize-select {
    position: relative;
    z-index: 2;
}

.organize-select-item {
    position: relative;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 7px;

    &::before {
        content: '';
        height: 5px;
        width: 5px;
        background-color: $white;
        border-radius: 100%;
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translate(7px, -50%);
    }
}

.organize-filter__content {
    padding: 22px;
    border-radius: 18px;
    background-color: $primary;
    color: #fff;
    width: max-content;
    min-width: 100px;
    position: absolute;
    top: calc(100% + 12px);
    left: 0;
    transition: color 0.3s ease;
}

.dropdown--opened {
    .organize-select-btn {
        background-color: $primary;
        color: $white;
        border-color: $primary;

        path {
            fill: $white;
        }
    }
}

.organize-select-btn {
    --border-color-default: $dark;
    --color-default: $dark;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: $dark;
    height: 33px;
    border-radius: 100px;
    border: 1px solid $dark;
    padding: 10px 16px;

    transition:
        color 0.3s $app-default-easing,
        background-color 0.3s $app-default-easing;
}

.organize-arrow-svg {
    flex-shrink: 0;
    margin-left: 6px;
    transition: transform 0.3s $app-default-easing;

    path {
        fill: $dark;
    }

    .dropdown--opened & {
        transform: rotate(180deg);
    }

    [stroke]:not([stroke='none']) {
        stroke: currentColor;
    }
}

.organize-section-content-top-mobile {
    display: flex;
    border-bottom: 1px solid #dcdcdd;
    padding-bottom: 6px;
    justify-content: space-between;

    @include mobile {
        flex-direction: column-reverse;
        gap: 12px;
    }
}

.organize-logo {
    object-fit: contain;
    min-width: 80px;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    width: auto;
}

.organize-pagination-wrapper {
    @include pagination-dark();
}
