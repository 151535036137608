.program-section-wrapper {
    min-height: 100vh;
    min-height: 100svh;
    width: 100%;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $white;
    padding-top: calc(var(--header-height) + 80px);
    padding-bottom: 80px;
    gap: 120px;
    color: $dark;
    --padding: 112px;
    position: relative;

    @include tablet {
        gap: 80px;
        padding-bottom: var(--padding);
        padding-top: var(--padding);
        background-color: $gray;
    }

    @include mobile {
        gap: 50px;
        --padding: 80px;
    }
}

.program-top {
    display: flex;
    justify-content: space-between;

    @include mobile {
        flex-direction: column;
        gap: 24px;
    }
}

.program-bottom {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media screen and (max-width: 1400px) {
        gap: 10px;
    }
}

.program-title {
    @include grid-columns(3, max-width);

    @include tablet {
        @include grid-columns(5, max-width);
    }

    @include mobile {
        max-width: 100%;
    }
}

.program-description-block {
    display: flex;
    align-items: end;
    gap: 85px;

    @include tablet {
        flex-direction: column;
        align-items: start;
        gap: 40px;
    }
}

.program-description {
    max-width: 350px;
}

.program-card {
    display: flex;
    position: relative;
    width: 100%;
    background-color: $white;
    border-radius: 18px;
    justify-content: space-between;
    padding: 30px 46px;
    align-items: center;
    transition: background-color 0.3s ease;
    cursor: pointer;

    @media screen and (max-width: 1400px) {
        padding: 20px 46px;
    }

    @include mobile {
        padding-bottom: 30px;
        padding-inline: 20px;
        padding-top: 80px;
    }

    path {
        transition: fill 0.3s ease;

        fill: $primary;
    }

    @include hover {
        background-color: $blue;
        color: $white;

        .program-card-btn {
            border-color: $white;

            path {
                fill: $white;
            }
        }

        .program-card-title {
            color: $white;
        }
    }
}

.program-card-date {
    --offset: 20px;
    display: flex;
    align-items: baseline;

    @include mobile {
        --size: 30px;
        position: absolute;
        left: var(--offset);
        top: var(--offset);
    }
}

.program-card-text {
    display: flex;
    align-items: flex-end;
    margin-left: 110px;
    justify-content: space-between;
    width: 100%;

    @include tablet {
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
        width: 60%;
        gap: 24px;
    }

    @include mobile {
        gap: 16px;
        width: 100%;
    }
}

.program-card-title {
    color: $primary;
    text-align: left;
    width: 250px;
}
.program-card-description {
    margin-right: auto;
    margin-left: auto;
    width: 50%;

    @include tablet {
        margin: 0;
        width: 100%;
    }
}

.program-card-btn {
    --size: 48px;
    --offset: 12px;
    min-width: var(--size);
    width: var(--size);
    height: var(--size);
    border: 1px solid $primary;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:
        background-color 0.3s ease,
        border-color 0.3s ease;
    cursor: pointer;

    @include tablet {
        --size: 30px;
        position: absolute;
        right: var(--offset);
        top: var(--offset);

        svg {
            width: 12px;
        }
    }

    @include mobile {
        --offset: 20px;
    }

    path {
        transition: fill 0.3s ease;

        fill: $primary;
    }
}
