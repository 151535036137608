.pagination-list {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 90px;
    margin-inline: auto;

    @include mobile {
        width: 80%;
    }
}

.pagination-list-ctrl {
    --size: 40px;
    @include clickable();
    width: var(--size);
    height: var(--size);
    min-height: 0;
    flex-shrink: 0;

    @media screen and (max-width: 1366px) {
        --size: 30px;
    }

    @include tablet {
        .btn__icon {
            padding: 14px 12px;
        }
    }

    @include mobile {
        --size: 40px;
    }

    .btn__icon {
        padding: 16px;

        &::after {
            display: none;
        }
    }

    &.pagination-list-ctrl--prev .btn__icon {
        transform: scaleX(-1);
    }

    &.is-disabled {
        pointer-events: none;
        opacity: $btn-disabled-opacity;
    }
}

.pagination-list-ctrl--prev,
.pagination-list-ctrl--next {
    width: 42px;
    height: 42px;
    border: 1px solid $dark;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:
        background-color 0.3s ease,
        transform 0.3s ease;
    cursor: pointer;

    @media screen and (max-width: 1366px) {
        width: 30px;
        height: 30px;

        svg {
            width: 12px;
        }
    }

    @include hover {
        background-color: $dark;

        path {
            fill: $white;
        }

        &:active {
            transform: scale(0.95);
        }
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    path {
        transition: fill 0.3s ease;

        fill: $dark;
    }
}

.pagination-list-ctrl--prev {
    margin-right: 27px;
    svg {
        transform: rotate(180deg);
    }
}
.pagination-list-ctrl--next {
    margin-left: 27px;
}

.pagination-list-link {
    color: $body-color;
    transition: color $app-default-duration $app-default-easing;

    & + & {
        margin-left: 16px;

        @include tablet {
            margin-left: 12px;
        }

        @include mobile {
            margin-left: 24px;
        }
    }
}

a.pagination-list-link {
    position: relative;

    &::after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 30px;
        height: 30px;
    }

    &::before {
        width: 30px;
        height: 30px;
    }
    @include hover {
        color: $primary;
    }
}

.pagination-list-link--active {
    color: $primary;
    pointer-events: none;
}
