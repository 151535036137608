.food-area-popup {
    .menu-popup-close-btn {
        width: var(--size);
        height: var(--size);
        align-self: flex-end;
    }

    .popup-container {
        right: 0;
        background-color: $white;
        height: 100%;
        width: 670px;
        display: flex;
        flex-direction: column;
        padding: 50px 70px;

        @media screen and (max-width: 1366px) {
            padding: 32px 36px;
        }
        @include tablet {
            width: 442px;
        }

        @include mobile {
            width: 100%;
            padding: 18px 16px;
        }
    }
}

.food-area-popup-content__title {
    padding-top: 14px;
    padding-bottom: 50px;
    border-bottom: 1px solid rgba(33, 33, 35, 0.2);

    @media screen and (max-width: 1366px) {
        padding-bottom: 40px;
        padding-top: 5px;
    }
    @include mobile {
        padding-bottom: 36px;
    }
}

.food-area-popup-content__info {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 50px;

    @media screen and (max-width: 1366px) {
        margin-top: 30px;
        gap: 30px;
    }
}

.food-area-popup-content__info-schema.food-area-popup-content__info-value--disabled {
    color: $dark;
    cursor: default;
    pointer-events: none;
}

.food-area-popup-content__info-item {
    display: flex;
}

.food-area-popup-content__info-label {
    opacity: 0.6;
    width: 33%;
}

.food-area-popup-content__info-date {
    display: flex;
    gap: 40px;
}

.food-area-popup-content__info-schema {
    color: $primary;
    cursor: pointer;
}

.food-area-popup-content__info-value {
    width: 50%;

    @include mobile {
        width: 67%;
    }
}

.food-area-popup-content__description {
    margin-top: 80px;
    & > *:not(:last-child) {
        margin-bottom: 24px;

        @media screen and (max-width: 1366px) {
            margin-bottom: 16px;
        }
    }
    @media screen and (max-width: 1366px) {
        margin-top: 40px;
    }
}

.food-area-popup-content__slider {
    margin-top: 50px;

    @media screen and (max-width: 1366px) {
        margin-top: 40px;
    }
}

.food-area-popup-content__slider-wrapper .swiper {
    overflow: visible;
}

.food-area-popup-content__slide {
    max-width: 640px;
    border-radius: 18px;
    position: relative;
    overflow: hidden;

    @media screen and (max-width: 1366px) {
        border-radius: 12px;
    }
}

.food-area-popup-content__slider-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;

    & .slider-prev-btn,
    & .slider-next-btn {
        border-color: $dark;
        flex-shrink: 0;

        & svg path {
            fill: $dark;
        }
    }

    & .swiper-button-disabled {
        opacity: 0.2;
        cursor: default;
    }
}
.food-area-popup-content__slider-slider {
    width: 100%;
}
