.constructor-gallery-popup {
    z-index: 11;
    opacity: 0;
    visibility: hidden;
    transition:
        opacity 0.3s ease,
        visibility 0.3s ease;
    background-color: #000;

    &.popup--opened {
        visibility: visible;
        opacity: 1;
    }
}

.constructor-gallery-popup__top {
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    color: #fff;
    top: 30px;

    .page-top__close-btn {
        @include tablet {
            transform: none;
            right: var(--offset-x);
            width: fit-content;
            left: unset;

            .close-button__text {
                display: none;
            }
        }
    }
}

.constructor-gallery-popup__top-right {
    margin-left: auto;

    @include tablet {
        display: none;
    }
}

.constructor-gallery-popup-slider {
    height: 100%;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        z-index: 1;
        pointer-events: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(rgba(#000, 0.4), transparent);
    }
}

.constructor-gallery-popup-thumbnails-slider-wrapper {
    position: fixed;
    z-index: 2;
    width: 505px;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    align-items: center;
    gap: 20px;
    padding-inline: var(--offset-x);

    @include mobile {
        left: 0;
        transform: translate(0);
        width: 100%;
    }

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 64px);
        height: calc(100% + 20px * 2);
        opacity: 0.5;
        background-color: #000;
        border-radius: 16px;
    }
}

.constructor-gallery-popup-thumbnails-slider {
    width: 100%;
}

.constructor-gallery-popup-slider__slide-img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include tablet {
        object-fit: contain;
    }
}

.constructor-gallery-popup-thumbnails-slider__slide {
    border-radius: 4px;
    overflow: hidden;
    overflow: clip;
    border: 1px solid transparent;
    transition: border-color 0.3s ease;
    cursor: pointer;

    &,
    &.swiper-slide {
        width: 100px;
        height: auto;
    }
}

.constructor-gallery-popup-thumbnails-slider__nav-btn {
    @include clickable();
    flex-shrink: 0;
    width: 18px;
    position: relative;

    @include mobile {
        display: none;
    }

    svg {
        [stroke] {
            stroke: #fff;
        }
    }
}

.constructor-gallery-popup-thumbnails-slider__nav-btn--prev {
    transform: rotate(180deg);
}

.constructor-gallery-popup-table {
    margin-top: 50px;
}
