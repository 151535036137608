.web-share__label {
    color: $dark;
    margin-bottom: 20px;

    @media screen and (max-width: 1366px) {
        margin-bottom: 12px;
    }

    @include tablet {
        margin-bottom: 0;
    }
}

.web-share__content {
    display: flex;
    align-items: center;
    gap: 8px;

    @media screen and (max-width: 1366px) {
        gap: 16px;
    }

    @include mobile {
        gap: 12px;
    }
}

.web-share__list-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    &:not(:last-child) {
        &::after {
            content: '';
            width: 1px;
            height: 30px;
            background-color: $primary;
            opacity: 0.3;

            @media screen and (max-width: 1366px) {
                height: 20px;
            }
        }
    }
}

.web-share__list {
    display: flex;
    align-items: center;
    gap: 8px;
}

.web-share__button {
    --size: 40px;
    width: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--size);
    background-color: $primary;
    border-radius: 50%;

    path {
        fill: $white;
    }

    @media screen and (max-width: 1366px) {
        --size: 30px;

        svg {
            width: 10px;
        }
    }
}
