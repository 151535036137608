.summit-page {
    .header {
        background-color: #fff;
    }
}
.hr-summit__section {
    .participants-section {
        color: $dark;
        background-color: $light;

        .participants-slider-prev-btn,
        .participants-slider-next-btn {
            border-color: $dark;
            svg path {
                fill: $dark;
            }

            @include hover {
                background-color: $dark;

                path {
                    fill: $white;
                }

                &:active {
                    transform: scale(0.95);
                }
            }

            &:disabled {
                opacity: $btn-disabled-opacity;
                pointer-events: none;
            }
        }

        .participants-title {
            @include grid-columns(3, max-width);
            @include tablet {
                @include grid-columns(4.5, max-width);
            }
            @include mobile {
                @include grid-columns(8, max-width);
            }
        }

        @include tablet {
            min-height: auto;
        }
    }

    .mission__section {
        background-color: $light-blue;

        .mission-pagination-wrapper {
            @include pagination-dark();
        }
    }

    .organize-left-btn {
        display: none;
    }

    .organize-section-content {
        .organize-pagination-wrapper {
            @include pagination-dark();
        }
    }

    .how-it-was-section {
        color: $dark;
        background-color: $light-blue;

        .how-it-was-pagination {
            @include pagination-dark();
        }
    }
}

.summit__text-with-image__content {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

    @include tablet {
        height: auto;
        gap: 30px;
        justify-content: space-between;
    }
    @include mobile {
        gap: 24px;
    }
}

.text-with-image__text {
    @include tablet {
        margin-left: auto;
    }
}
