.team__wrapper {
    display: flex;
    flex-direction: row;
    gap: var(--grid-gap);

    + .team__wrapper {
        margin-top: 120px;

        @include tablet {
            margin-top: 60px;
        }
    }

    @include tablet {
        flex-direction: column;
        --grid-columns: 8;
    }

    @include mobile {
        --grid-columns: 4;
    }
}

.team-block__right {
    flex: 1 1 auto;
}

.team-block__left {
    @include grid-columns(4);
    flex-shrink: 0;
    display: flex;
    flex-direction: column;

    @include tablet {
        width: 100%;
        gap: var(--grid-gap);
        flex-direction: row;
    }

    @include mobile {
        flex-direction: column;
        gap: 26px;
        margin-bottom: 36px;
    }
}

.team__descr {
    @include tablet {
        @include grid-columns(4);
        flex-shrink: 0;
    }

    &:not(:last-child) {
        margin-bottom: 119px;

        @media screen and (max-width: 1366px) {
            margin-bottom: 84px;
        }

        @include tablet {
            margin-bottom: 46px;
        }

        @include mobile {
            margin-bottom: 0;
        }
    }
}

.team__descr-inner {
    max-width: 25ch;
}

.team__number-title {
    opacity: 0.4;
}

.team__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 60px;
    column-gap: var(--grid-gap);

    @media screen and (max-width: 1366px) {
        row-gap: 50px;
    }

    @include tablet {
        row-gap: 40px;
    }

    @include mobile {
        row-gap: 30px;
    }
}
