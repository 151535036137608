.schema-popup {
    z-index: 20;

    .menu-popup-close-btn {
        width: var(--size);
        height: var(--size);
        margin-left: auto;
    }

    .popup-container {
        right: 0;
        background-color: $white;
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: column;
        padding: 50px 70px;

        @media screen and (max-width: 1366px) {
            padding: 32px 36px;
        }

        @include tablet {
            width: 100%;
            padding: 18px 16px;
        }
    }
}

.schema-popup-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.schema-popup-btns__back-wrapper {
    display: flex;
    align-items: center;
    gap: 14px;

    @media screen and (max-width: 1366px) {
        gap: 10px;
    }
}

.schema-popup-btns__back {
    transform: rotate(90deg);
}

.schema-popup-content {
    margin-top: 104px;

    @media screen and (max-width: 1366px) {
        margin-top: 60px;
    }
    @include tablet {
        margin-top: 124px;
    }
    @include mobile {
        margin-top: 68px;
    }
}

.schema-popup-content__info {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include mobile {
        margin-top: 16px;
    }
}

.schema-popup-content__info-label {
    opacity: 0.6;
}

.schema-popup-content__image {
    --aspect-ratio: calc(414 / 301);
    margin-top: 60px;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media screen and (max-width: 1366px) {
        margin-top: 40px;
    }

    @include tablet {
        margin-top: 50px;
    }
    @include mobile {
        margin-top: 20px;
    }

    &:has(.schema-popup-content__img--overflow) {
        overflow: scroll;
    }
}

.schema-popup-content__img {
    width: 100%;
    max-width: none;
    display: block;
}

.schema-popup-content__image:not(:has(.schema-popup-content__img--overflow)) .schema-popup-content__img {
    height: 100%;
}
.schema-popup-content__img--overflow {
    @include mobile {
        width: auto;
    }
}

.schema-popup-schema-list {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
}
