.constructor-text {
    display: flex;
    flex-direction: column;

    .table-wrapper {
        @include mobile {
            width: calc(100% + var(--offset-x) * 2);
            margin-inline: calc(-1 * var(--offset-x));
            padding-inline: var(--offset-x);
        }
    }
}

.constructor-text__title {
    font-weight: 500;

    @include tablet {
        font-weight: 400;
    }

    &:not(:last-child) {
        margin-bottom: 1em;

        @include mobile {
            margin-bottom: 24px;
        }
    }
}

.table-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;

    &::-webkit-scrollbar {
        height: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $primary;
    }

    .table,
    .table-container {
        min-width: 700px;
    }
}

.table-container {
    overflow: hidden;
    width: fit-content;

    .table {
        clip-path: inset(1px 1px 1px 1px);
    }
}

.constructor-text-title {
    @extend .text-m;
    color: $primary;
    border-bottom: 1px solid #dcdcdd;
    padding-bottom: 24px;
    margin-bottom: 32px;

    @include small-laptop {
        padding-bottom: 16px;
        margin-bottom: 20px;
    }
}
