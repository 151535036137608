.service-navigation-wrapper {
    .services-page-navigation .participation-list::after {
        bottom: 0;
    }

    .services-page-title-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: var(--grid-gap);
        padding-top: 80px;
        padding-bottom: 100px;

        @media screen and (max-width: 1366px) {
            padding-top: 60px;
            padding-bottom: 90px;
        }

        @include tablet {
            padding-bottom: 70px;
        }

        @media screen and (max-width: 767px) {
            padding-top: 55px;
            padding-bottom: 44px;
            gap: 0px;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .services-page-title {
        @include grid-columns(7);
        @include grid-offset(padding-right, 1);

        @include tablet {
            @include grid-columns(6);
        }

        @include mobile {
            padding-right: 0;
            padding-bottom: 30px;
        }
    }

    .services-page-description-block {
        display: flex;
        align-items: center;
        gap: 20px;
        @include grid-columns(4);

        @include tablet {
            flex-direction: column;
            align-items: start;
            @include grid-columns(6);
            gap: 40px;
        }

        @media screen and (max-width: 767px) {
            gap: 30px;
            @include grid-columns(12);
        }
    }

    .services-page-description {
        @include grid-columns(3);

        @media screen and (max-width: 767px) {
            @include grid-columns(6);
        }

        @media screen and (max-width: 575px) {
            @include grid-columns(8);
        }
    }
}
