.program-detail-popup {
    .menu-popup-close-btn {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 4;
    }

    .menu-popup-close-btn-wrapper {
        position: sticky;
        top: 50px;
        z-index: 7;

        @include small-laptop {
            top: 30px;
        }

        @include mobile {
            top: 16px;
        }
    }

    .popup-container {
        right: 0;
        background-color: $white;
        height: 100%;
        width: 51vw;
        display: flex;
        flex-direction: column;
        padding: 70px;
        padding-top: 0px;

        @media screen and (max-width: 1600px) {
            padding-inline: 36px;
        }

        @include tablet {
            width: 76vw;
            padding: 22px;
            padding-top: 0px;
        }

        @include mobile {
            width: 100%;
            padding: 16px;
            padding-top: 0px;
        }
    }
}

.program-detail-popup-top {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    border-bottom: 1px solid #dcdcdd;
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 3;
    padding-top: 100px;
    margin-bottom: 50px;

    @media screen and (max-width: 1600px) {
        padding-top: 70px;
        margin-bottom: 40px;
    }

    @include small-laptop {
        margin-bottom: 24px;
    }

    @include tablet {
        padding-top: 90px;
        margin-bottom: 30px;
    }

    @include mobile {
        padding-top: 30px;
    }
}

.program-detail-popup-info-description {
    white-space: pre-line;
}

.program-detail-popup-title {
    color: $primary;
    margin-top: 10px;
    max-width: 32.1vw;
    padding-bottom: 24px;

    @media screen and (max-width: 1600px) {
        margin-top: 24px;
    }

    @include small-laptop {
        margin: 0;
        margin-top: 20px;
        padding-bottom: 16px;
    }

    @include tablet {
        margin-top: 20px;
    }

    @include mobile {
        max-width: 90%;
        padding-bottom: 20px;
    }
}

.program-detail-popup-content {
    display: flex;
    flex-direction: column;
}

.program-detail-popup-info {
    width: 100%;
    display: flex;
    gap: 5vw;
    justify-content: space-between;
    margin-bottom: 50px;

    @include small-laptop {
        margin-bottom: 40px;
    }

    @include mobile {
        flex-direction: column;
        gap: 24px;
        margin-bottom: 30px;
    }
}

.program-detail-popup-info-item {
    display: flex;
    flex-direction: column;
    gap: 27px;
    min-width: 100px;

    @include small-laptop {
        gap: 16px;
    }

    @include mobile {
        flex-direction: row;
        gap: 50px;
    }
}

.program-detail-popup-info-item-text--time {
    color: $white; //говорят временно
    display: none;
}

.program-detail-popup-info-item-title {
    opacity: 0.4;

    @include mobile {
        min-width: 85px;
    }
}

.program-detail-popup-place {
    color: $primary;
    cursor: pointer;
}

.program-anchors-wrapper {
    position: sticky;
    top: var(--header-height);
    z-index: 5;
    overflow: hidden;
    background-color: $white;
    border-top: 1px solid $gray;
    margin-top: 50px;

    @include small-laptop {
        margin-top: 40px;
    }
}

.program-anchors {
    display: flex;
    gap: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
    overflow-x: auto;
    @include hidden-scrollbar;
}

.program-anchor-item {
    padding: 12px;
    border-radius: 100px;
    color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    z-index: 5;
    backdrop-filter: blur(6px);
    position: relative;
    transition: 0.4s ease;
    cursor: pointer;
    &:before,
    &:after {
        background: $primary;
        border-radius: 100px;
        transition: 0.3s ease;
        content: '';
        position: absolute;
        z-index: -1;
        top: 50%;
        right: 50%;
        bottom: 50%;
        left: 50%;
    }

    scroll-margin-left: calc(12px + var(--offset-x));

    @include hover {
        &:before {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        color: $white;
    }

    &:last-child {
        @include tablet {
            padding-right: var(--offset-x);
        }
    }

    &.is-active {
        &:before {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        color: $white;
    }
}
