.services-section {
    min-height: 100vh;
    min-height: 100svh;
    width: 100%;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $dark;
    padding-top: calc(var(--header-height) + 80px);
    padding-bottom: 80px;
    gap: 90px;
    --padding: 112px;
    background-color: $light;

    .swiper-wrapper {
        transition-timing-function: easeInOutCubic;
    }

    @media screen and (max-width: 1366px) {
        gap: 60px;
    }

    @include tablet {
        min-height: unset;
        gap: 80px;
        padding-bottom: var(--padding);
        padding-top: var(--padding);
    }

    @include mobile {
        --padding: 80px;
    }
}

.services-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include mobile {
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }
}

.services-bottom {
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.services-title {
    @include grid-columns(3, max-width);

    @include mobile {
        max-width: 100%;
    }
}

.services-description-block {
    display: flex;
    align-items: end;
    gap: 85px;
    margin-right: 7%;

    @include tablet {
        flex-direction: column;
        align-items: start;
        gap: 40px;
    }
}

.services-description {
    max-width: 360px;
}

.services-slider {
    width: 100%;
    height: 480px;
    cursor: grab;

    &:active {
        cursor: grabbing;
    }

    @media screen and (max-width: 1366px) {
        height: 300px;
    }

    @include tablet {
        height: 360px;
    }

    @include mobile {
        height: 240px;
    }

    &:not(.is-enabled) {
        .swiper-wrapper {
            transform: none !important;
            justify-content: center;
        }
    }
}

.services-slider-slide.swiper-slide {
    color: $dark;
    max-width: 430px;
    border-radius: 18px;
    position: relative;
    overflow: hidden;
    background-color: $white;
    display: flex;

    @media screen and (max-width: 1400px) {
        gap: 32px;
        max-width: 290px;
    }

    @include tablet {
        justify-content: space-between;
        max-width: 356px;
    }

    @include mobile {
        max-width: calc(100% - var(--offset-x) * 2);
        min-width: unset;
    }
}

.services-slider-next-btn,
.services-slider-prev-btn {
    width: 48px;
    height: 48px;
    border: 1px solid $dark;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:
        background-color 0.3s ease,
        transform 0.3s ease;
    cursor: pointer;

    @include tablet {
        width: 30px;
        height: 30px;

        svg {
            width: 12px;
        }
    }

    @include hover {
        background-color: $dark;

        path {
            fill: $white;
        }

        &:active {
            transform: scale(0.95);
        }
    }

    path {
        transition: fill 0.3s ease;

        fill: $dark;
    }
}

.services-slider-prev-btn {
    svg {
        transform: rotate(180deg);
    }
}

.services-pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 27px;

    @include mobile {
        gap: 10px;
    }

    .dots {
        overflow: hidden;
        position: relative;
        width: 300px;
        height: 10px;

        @include mobile {
            width: 220px;
        }
    }

    .dot {
        position: absolute;
        left: 49%;
        top: 50%;
        transform: translateX(-50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        transition:
            transform 0.65s easeInOutCubic,
            opacity 0.3s ease-out;
    }
}

.services__list {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: var(--grid-gap);
}

.services-card__slide.services-card {
    display: flex;
    @include grid-columns(3);
    gap: 50px;
    flex-shrink: 0;

    color: $dark;
    border-radius: 18px;
    position: relative;
    overflow: hidden;
    background-color: $white;
    display: flex;

    @media screen and (max-width: 1400px) {
        gap: 32px;
    }

    @include tablet {
        justify-content: space-between;
        width: calc(100% / 2 - var(--grid-gap));
    }

    @include mobile {
        max-width: calc(100% - var(--offset-x) * 2);
        min-width: unset;
    }
}

.services-card {
    display: flex;
    position: relative;
    min-height: 230px;
    width: 100%;
    background-color: $white;
    border-radius: 12px;
    flex-direction: column;
    justify-content: space-between;
    padding: 46px 40px;

    align-items: center;
    transition: background-color 0.3s ease;
    cursor: pointer;

    @media screen and (max-width: 1366px) {
        padding: 36px 26px;
    }

    @include mobile {
        padding: 25px 20px;
    }

    path {
        transition: fill 0.3s ease;

        fill: $white;
    }

    @include hover {
        background-color: $primary;
        color: $white;

        .services-card-btn {
            border-color: $white;

            path {
                fill: $white;
            }
        }

        .services-card-title {
            color: $white;
        }
    }
}

.services-card-text {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    height: 100%;
    gap: 50px;

    @include tablet {
        gap: 32px;
    }
}

.services-card-title {
    max-width: 150px;

    @include mobile {
        width: 50%;
    }
}

.services-card-description {
    width: 100%;
}

.services-card-btn {
    --size: 46px;
    --offset: 12px;
    min-width: var(--size);
    width: var(--size);
    height: var(--size);
    border: 1px solid $white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:
        background-color 0.3s ease,
        border-color 0.3s ease;
    cursor: pointer;
    position: absolute;
    right: var(--offset);
    top: var(--offset);

    @media screen and (max-width: 1366px) {
        --size: 30px;
        svg {
            width: 12px;
        }
    }

    @include tablet {
        border: 1px solid $primary;

        path {
            fill: $primary;
        }
    }

    @include mobile {
        --offset: 20px;
    }
}
