.food-areas-list__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 90px;

    @media screen and (max-width: 1366px) {
        gap: 16px;
        margin-bottom: 60px;
    }
    @media screen and (max-width: 1199px) {
        gap: 12px;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 112px;
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        margin-bottom: 80px;
    }
}

.food-areas-list__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 272px;

    @media screen and (max-width: 1366px) {
        margin-bottom: 186px;
    }
    @media screen and (max-width: 1199px) {
        margin-bottom: 224px;
    }
    @media screen and (max-width: 767px) {
        margin-bottom: 160px;
    }
}

.food-areas-list__item-title {
    padding-right: 20px;
}

.food-areas-list__item-btn {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    padding: 40px;
    gap: 30px;
    background-color: #f2f2f2;
    border-radius: 18px;
    cursor: pointer;

    @media screen and (max-width: 1366px) {
        border-radius: 12px;
    }

    @include hover {
        .food-areas-list__arrow {
            opacity: 1;
        }
    }

    @media screen and (max-width: 1366px) {
        padding: 32px;
        border-radius: 12px;
        gap: 18px;
    }

    @media screen and (max-width: 767px) {
        padding: 24px;
        gap: 16px;
    }
    @media screen and (max-width: 575px) {
        padding: 20px;
        gap: 12px;
    }
}

.food-areas-list__item-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    height: 100%;
}

.food-areas-list__item-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.food-areas-list__item-access-label {
    opacity: 0.6;
    margin-bottom: 14px;

    @media screen and (max-width: 1366px) {
        margin-bottom: 10px;
    }
}

.food-areas-list__item-access-value:not(:last-child) {
    margin-bottom: 24px;

    @media screen and (max-width: 1366px) {
        margin-bottom: 18px;
    }
}

.food-areas-list__arrow {
    --offset: 12px;
    --size: 40px;
    --svg-size: 16px;

    position: absolute;
    right: var(--offset);
    top: var(--offset);
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    background-color: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity $app-default-duration $app-default-easing;

    & svg {
        width: var(--svg-size);
        height: var(--svg-size);
    }

    @media screen and (max-width: 1366px) {
        --size: 28px;
        --svg-size: 14px;
    }
}

.food-areas-list__item-image {
    position: relative;
    border-radius: 18px;
    overflow: hidden;
    width: 230px;
    height: 320px;
    flex-shrink: 0;

    & img {
        height: 100%;
        width: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
    }

    @media screen and (max-width: 1366px) {
        border-radius: 10px;
        width: 154px;
        height: 220px;
    }

    @media screen and (max-width: 767px) {
        width: 144px;
        height: 200px;
    }
    @media screen and (max-width: 575px) {
        width: 104px;
        height: 154px;
        border-radius: 8px;
    }
}
