.footer-input {
    border: 1px solid $white;
    background-color: transparent;
    border-radius: 33px;
    padding: 0px 5px 0px 16px;
    outline: none;
    color: $white;
    height: 40px;
    width: 100%;

    &::placeholder {
        color: $white;
    }
}

.footer-input-response {
    position: absolute;
    left: 16px;
    bottom: -20px;

    &.is-error {
        color: $danger;
    }
}

.footer-input-wrapper {
    position: relative;
    width: 100%;
}

.footer-input-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    --size: 30px;
    width: var(--size);
    height: var(--size);
    background-color: $white;
    top: 5px;
    right: 5px;
    position: absolute;
    border-radius: 50%;
    transition: opacity 0.3s ease;
}

.footer-input-btn.is-submitting {
    opacity: 0.5;
}

.app-message {
    --padding-x: 16px;

    $line-height: 1.1;
    min-height: $line-height * 1em;
    line-height: $line-height;
    margin-top: 6px;
    padding-left: var(--padding-x);
    padding-right: var(--padding-x);
    font-size: 12px;
}
