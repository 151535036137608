.contacts {
    padding-top: calc(var(--header-height) + 50px);
    --offset-block: 80px;
    --border-radius: 18px;

    @include tablet {
        padding-top: calc(var(--header-height) + 42px);
        --offset-block: 60px;
    }

    @include mobile {
        padding-top: calc(var(--header-height) + 20px);
        --offset-block: 40px;
    }
}

.contacts-content-top {
    margin-bottom: var(--offset-block);
}

.contacts-content__welcome {
    grid-template-columns: repeat(var(--grid-columns), 1fr);
    display: grid;
    margin-top: var(--offset-block);
    width: 100%;
    margin-bottom: 120px;

    @include tablet {
        --grid-columns: 8;
        margin-bottom: 80px;
    }

    @include mobile {
        display: flex;
        flex-direction: column;
        gap: 60px;
        margin-bottom: 60px;
    }
}

.support-wrapper {
    grid-column: 9 / 13;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    @include tablet {
        grid-column: 5 / 9;
    }

    @include mobile {
        grid-column: 1 / -1;
        gap: 20px;
        align-items: flex-start;
        flex-direction: column;
    }
}

.contacts__info {
    display: grid;
    grid-template-columns: repeat(var(--grid-columns), 1fr);
    gap: 20px;

    @include mobile {
        display: flex;
        flex-wrap: wrap;
    }
}

.contacts__info-title {
    opacity: 0.4;
    margin-bottom: 10px;
}

.contacts__info-block--phone {
    grid-column: 1 / 3;

    @include tablet {
        grid-column: 1 / 4;
    }
}

.contacts__info-block--email {
    grid-column: 4 / 6;

    @include tablet {
        grid-column: 4 / 9;
    }
}

.contacts__info-block--address {
    grid-column: 9 / 13;
}

.contacts__info-block:not(.contacts__info-block--address) {
    .contacts__info-text {
        transition: color 0.3s ease;
        @include hover {
            color: $primary;
        }
    }
}

.contacts-map {
    width: 100%;
    overflow: hidden;
    padding-block: var(--offset-block);

    @media screen and (max-width: 1366px) {
        margin-bottom: 60px;
    }

    @include tablet {
        margin-bottom: 30px;
    }

    @include mobile {
        margin-bottom: 20px;
    }

    .map {
        height: 50vh;
        border-radius: 18px;
    }
}

.team__number-phone,
.team__item-phone,
.team__item-email {
    transition: color 0.3s ease;
    @include hover {
        color: $primary;
    }
}
