.footer-map-responsive {
    --aspect-ratio: calc(746 / 710);
    border-radius: var(--border-radius);
    background-color: $white;

    @media screen and (max-width: 767px) {
        border-radius: 14px;
    }

    @include mobile {
        height: auto;
        padding-top: 0;
        height: calc(80vh - var(--header-height));
        max-height: 340px;
    }
}

.map {
    contain: content;
    width: 100%;
    height: 100%;

    &:not(.responsive__item) {
        position: relative;
    }

    .loader-wrapper {
        background-color: #fff;
        border: 1px solid #21212322;
        border-radius: var(--border-radius);
        z-index: 9999;
    }
}

.map-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.map-info {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: #fff;
}

.map-marker {
    width: fit-content;
    min-width: 100px;
    min-height: 40px;
    border-radius: 18px;
    background-color: $primary;
    position: relative;
    padding: 12px;
    box-sizing: border-box;
    transform: translate(-50%, -115%);
    display: flex;
    justify-content: center;
}

.map-marker__inner {
    position: relative;
    width: fit-content;
    height: fit-content;
    text-align: center;
    color: #fff;

    &::after {
        content: '';
        position: absolute;
        bottom: -18px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid $primary;
    }
}
