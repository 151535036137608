.second-section-wrapper {
    height: 100vh;
    height: 100svh;
    width: calc(100% + var(--offset-x) * 2);
    margin-left: calc(-1 * var(--offset-x));
    position: relative;
    // margin-top: 10vh;
    z-index: 7;
    pointer-events: all;
}

.polygon-wrapper {
    border-radius: 100px;
    width: fit-content;
    height: fit-content;
    overflow: hidden;
}

.video-play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 7;
    background: transparent;
    cursor: pointer;
    pointer-events: all;
}

.video-clip-short {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-clip-long {
    position: absolute;
    width: 0;
}
