.favorite-window {
    position: fixed;
    flex-direction: column;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 28px;
    width: 430px;
    border-radius: 18px;
    background-color: $dark;
    padding: 24px 30px;
    z-index: 120;

    @include small-laptop {
        width: 335px;
        padding: 17px 23px;
        gap: 20px;
    }

    @include mobile {
        left: 16px;
        width: calc(100% - 16px * 2 - 50px - 12px);
        padding: 17px;
        border-radius: 12px;
        gap: 10px;
        padding-top: 11px;
    }
}

.favorite-window-top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.favorite-window-delete-btn-text,
.favorite-window-count {
    color: $white;
}

.favorite-window-delete-btn {
    display: flex;
    gap: 6px;
    align-items: center;
    cursor: pointer;

    svg {
        @include mobile {
            width: 19px;
            height: 19px;
        }
    }
}

.favorite-window-download-btn {
    --favourite-padding: 17px;
    position: relative;
    display: flex;
    background-color: $white;
    gap: 40px;
    padding: var(--favourite-padding);
    border-radius: 12px;
    border: 1px solid #dcdcdd;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    transition: color 0.3s ease;
    overflow: hidden;

    @include small-laptop {
        --favourite-padding: 15px;

        .download-svg {
            width: 14px;
            height: 14px;
        }

        .pdf-svg {
            width: 23px;
            height: 27px;
        }
    }

    @include mobile {
        --favourite-padding: 12px;
        gap: 22px;
        border-radius: 8px;

        .download-svg {
            width: 12px;
            height: 12px;
        }

        .pdf-svg {
            width: 17px;
            height: 20px;
        }
    }

    .btn__text {
        display: flex;
        gap: 20px;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .download-svg {
        opacity: 1;
        transition: opacity 0.3s ease-in-out;

        path {
            transition: stroke 0.3s ease;
        }
    }

    .pdf_svg__pdf-text {
        transition: fill 0.3s ease;
    }

    .pdf_svg__pdf-icon {
        transition: stroke 0.3s ease;
    }

    @include hover {
        color: $primary;

        .download-svg {
            path {
                stroke: $primary;
            }
        }

        .pdf_svg__pdf-text {
            fill: $primary;
        }

        .pdf_svg__pdf-icon {
            stroke: $primary;
        }
    }

    &.is-loader {
        pointer-events: none;

        .download-loader-wrapper {
            opacity: 1;
        }

        .download-svg {
            opacity: 0;
        }

        .download-loader {
            width: 100%;
            height: 100%;
            border: 5px dotted $primary;
            border-radius: 50%;
            display: inline-block;
            position: relative;
            box-sizing: border-box;
            animation: rotation 3s linear infinite;
            background-color: #fff;
        }
    }

    &.is-error .presentation-btn-error {
        opacity: 1;
    }
}

.download-loader-wrapper {
    position: absolute;
    right: calc(var(--favourite-padding) / 1.5);
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    width: 30px;
    height: 30px;
    transition: opacity 0.3s ease;
}

.presentation-btn-error {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}
