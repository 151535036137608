.technological-page {
    .header {
        background-color: $white;
    }

    .questions-section {
        --bg-color: #{$white};
    }

    .reviews-section {
        background-color: $light-blue;
        min-height: fit-content;

        .pagination-wrapper {
            @include pagination-dark();
        }
    }

    .reviews-title {
        @include grid-columns(4, max-width);

        @include mobile {
            max-width: 100%;
        }
    }

    .how-it-was-section {
        color: $dark;
        background-color: $light-blue;
        min-height: fit-content;

        .pagination-wrapper {
            @include pagination-dark();
        }
    }

    .mission__section {
        background-color: $white;

        .pagination-wrapper {
            @include pagination-dark();
        }
    }

    .about__title {
        @include grid-columns(4);
    }

    .services-content {
        padding-block: 80px;
        margin-bottom: 0;

        @include tablet {
            padding-block: 112px;
        }

        @include mobile {
            padding-block: 80px;
        }

        .services-content__content {
            @include grid-columns(6);

            @include mobile {
                width: 100%;
            }
        }

        .services-content__content-inner {
            p {
                margin-bottom: 40px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .text-with-image__section {
        .summit__text-with-image__content {
            justify-content: flex-end;
        }

        .text-with-image__text {
            color: $primary;
            padding-bottom: 30px;
        }
        .text-with-image__description {
            color: $dark;
            opacity: 0.6;
            padding-bottom: 44px;
        }

        @include tablet {
            .text-with-image__block-left__wrapper {
                gap: 0;
            }
            .text-with-image__block-top,
            .summit__text-with-image__content {
                width: 50%;
            }
        }

        @include mobile {
            .summit__text-with-image__content {
                gap: 0;
            }
            .text-with-image__block-top,
            .summit__text-with-image__content {
                width: 100%;
            }
        }
    }

    .organize-section-title.organize-section-title--simpe {
        @include grid-columns(4);

        @include tablet {
            @include grid-columns(6);
        }

        @include mobile {
            width: 100%;
        }
    }

    .selection-stages-section {
        min-height: fit-content;
        .services-pagination-wrapper {
            @include pagination-dark();
        }
    }
}
