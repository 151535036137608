.turn-device-message {
    background-color: $primary;
    color: #fff;
    position: fixed;
    z-index: 99999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px;
    padding-top: 13vh;
}

.turn-device-message__icon {
    width: 100px;

    img {
        width: 100%;
        height: auto;
        animation: turn-device-icon-rotation 4s ease infinite;
    }
}

@keyframes turn-device-icon-rotation {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(90deg);
    }
}

.turn-device-message__text {
    width: 520px;
    max-width: calc(100% - var(--offset-x) * 2);
}
