.footer {
    margin-top: auto;
    width: 100%;
    z-index: 4;
    display: flex;
    flex-direction: column;
    padding-bottom: 48px;
    padding-top: calc(var(--header-height) + 260px);
    color: $white;
    gap: 180px;
    background-color: $blue;

    @media screen and (max-width: 1600px) {
        padding-top: calc(var(--header-height) + 200px);
    }

    @media screen and (max-width: 1366px) {
        padding-top: calc(var(--header-height) + 125px);
    }

    @include tablet {
        @include recalculate-grid(8);
        min-height: unset;
        padding-top: 112px;
    }

    @include vertical-tablet {
        padding-top: 148px;
    }

    @include mobile {
        padding-top: 80px;
    }

    .btn {
        @include mobile {
            --padding-x: 16px;
        }
    }
}

.footer-inner {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 180px;

    @media screen and (max-width: 1366px) {
        gap: 130px;
    }

    @include mobile {
        gap: 75px;
    }
}

.footer-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include grid-columns(2);

    @media screen and (max-width: 767px) {
        width: auto;
    }
}

.footer-contacts {
    display: contents;

    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        gap: 28px;
    }
}

.footer-address-wrapper {
    @include tablet {
        @include grid-columns(4);
    }

    @media screen and (max-width: 767px) {
        order: -2;
        width: auto;
        grid-column: 1 / 2;
    }

    @media screen and (max-width: 345px) {
        grid-column: 1 / -1;
    }
}

.footer-address {
    @include tablet {
        max-width: 220px;
    }

    .footer-item-bottom {
        @media screen and (max-width: 380px) {
            max-width: 155px;
        }
    }
}

.footer-item--map {
    display: flex;
    align-self: flex-end;

    @include tablet {
        order: 1;
        @include grid-columns(4);
    }

    @media screen and (max-width: 767px) {
        order: -1;
        width: auto;
        grid-column: 2 / 3;
    }

    @media screen and (max-width: 345px) {
        grid-column: 1 / -1;
    }
}

.footer-item--map-inner {
    text-wrap: nowrap;
}

.footer-item-top {
    display: flex;
    opacity: 0.4;
}

.footer-item-bottom {
    display: flex;
}

.footer-top {
    display: flex;
    gap: var(--grid-gap);

    @include tablet {
        flex-direction: column;
        gap: 96px;
    }

    @include mobile {
        gap: 76px;
    }
}

.footer-top-left {
    @include grid-columns(6);
    flex-shrink: 0;

    @include tablet {
        width: 100%;
    }
}

.footer-top-right {
    flex: 1 1 auto;
    display: flex;
    gap: var(--grid-gap);

    @include tablet {
        flex-wrap: wrap;
    }

    @media screen and (max-width: 767px) {
        display: grid;
        grid-template-columns: 1fr auto;
        row-gap: 28px;

        .footer-item {
            width: auto;
        }
    }
}

.footer-top-right-inner {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    gap: 70px var(--grid-gap);

    @media screen and (max-width: 1366px) {
        row-gap: 42px;
    }

    @include tablet {
        display: contents;
    }
}

.footer-news {
    display: flex;
    flex-direction: column;
    @include grid-columns(3);
    gap: 55px;

    @media screen and (max-width: 1600px) {
        gap: 36px;
    }

    @include tablet {
        @include grid-columns(3);
    }

    @include mobile {
        width: 100%;
    }
}

.footer-news-text {
    @include tablet {
        max-width: 250px;
    }

    @include mobile {
        font-size: 14px;
    }
}

.footer-soc-list {
    margin-left: auto;

    @include tablet {
        order: 2;
        margin-left: 0;
    }

    @media screen and (max-width: 767px) {
        order: 1;
        grid-column: 2 / 3;
        justify-self: end;
        align-self: end;
    }

    .soc-list-item {
        color: $blue;
        border: 0;
    }
}

.footer-bottom {
    display: flex;
    flex-direction: column;
    gap: 130px;

    @include mobile {
        gap: 80px;
    }
}

.footer-partners {
    display: flex;
    gap: var(--grid-gap);
    @include grid-columns(6);
    flex-shrink: 0;

    @include tablet {
        min-width: 100%;
    }

    @include mobile {
        flex-direction: column;
        gap: 24px;
    }
}

.footer-partner {
    display: flex;
    gap: 20px;
    align-items: center;
    @include grid-columns(3);

    @include tablet {
        @include grid-columns(4);
    }

    @media screen and (max-width: 767px) {
        gap: 12px;
        width: 100%;
    }

    svg,
    img {
        height: 3.2em;
        width: auto;

        @include mobile {
            @include grid-columns(2);
            max-height: 48px;
        }
    }
}

.footer-partner-text {
    max-width: 175px;
}

.footer-bottom-top {
    display: flex;
    gap: var(--grid-gap);

    @include tablet {
        flex-wrap: wrap;
        gap: 80px;
        justify-content: flex-end;
    }

    @include mobile {
        justify-content: flex-start;
    }
}

.footer-support {
    display: flex;
    gap: var(--grid-gap);
    width: 100%;

    @include tablet {
        @include grid-offset(padding-left, 4);
    }

    @media screen and (max-width: 767px) {
        padding-left: 0;
    }
}

.footer-support-link {
    flex-shrink: 0;
    width: auto;

    @include tablet {
        margin-left: auto;
    }
}

.footer-bottom-bottom {
    display: flex;
    gap: var(--grid-gap);

    @include tablet {
        justify-content: space-between;
        align-items: flex-end;
    }

    @include mobile {
        flex-direction: column-reverse;
        gap: 80px;
    }
}

.footer-bottom-left {
    display: flex;
    gap: var(--grid-gap);
    @include grid-columns(6);
    flex-shrink: 0;

    @include tablet {
        flex: 1 1 auto;
        width: 100%;
    }

    @media screen and (max-width: 767px) {
        flex-direction: column-reverse;
        gap: 20px;
    }
}

.footer-copyright {
    @include grid-columns(3);

    @include tablet {
        @include grid-columns(4);
    }

    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

.developer {
    display: flex;
    align-items: center;
    gap: 12px;
}

.developer-icon {
    height: 1em;
    width: auto;
}

.footer-bottom-right {
    display: flex;
    gap: var(--grid-gap);

    @include tablet {
        flex-direction: column;
        align-items: flex-end;
        max-width: unset;
        gap: 12px;
        width: 22%;
        white-space: nowrap;
        text-align: right;
    }

    @include mobile {
        width: 100%;
        align-items: flex-start;
    }
}

.footer-bottom-right-link-wrapper {
    display: flex;

    @include tablet {
        align-items: flex-end;
    }
}
