.cookies-agreement {
    position: fixed;
    z-index: 10;
    top: calc(var(--header-height) + 10px);
    right: var(--offset-x);
    background-color: #fff;
    color: $body-color;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    padding: 33px;
    border-radius: 18px;
    border: 1.5px solid $primary;

    @include tablet {
        right: var(--offset-x);
        padding: 20px;
        @include grid-columns(3);
        gap: 10px;

        @include recalculate-grid(8);
    }

    @include mobile {
        top: auto;
        top: var(--header-height);
        right: auto;
        left: var(--offset-x);
        width: 70%;
    }

    @media screen and (max-width: 375px) {
        flex-direction: column;
        gap: 20px;
        align-items: stretch;
    }
}

.cookies-agreement__text {
    max-width: 304px;

    @include tablet {
        max-width: 600px;
    }
}

.cookies-agreement__btn {
    flex-shrink: 0;
    margin-right: auto;

    @media screen and (max-width: 375px) {
        margin-left: 0;
    }
}

.cookies-agreement__btn.outline-primary {
    border: 1px solid $primary;
    color: $primary;

    & svg path {
        fill: #fff;
    }

    &::after {
        background-color: $primary;
    }

    @include hover {
        &::after {
            z-index: -1;
        }
    }
}
