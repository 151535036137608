.filterable-search-results {
    padding-top: 182px;

    @include small-laptop {
        padding-top: 122px;
    }

    @include tablet {
        padding-top: 112px;
    }

    @include mobile {
        padding-top: 102px;
    }
}

.filterable-search-results__input {
    position: relative;
    bottom: 0;
    appearance: none;
    width: 100%;
    background-color: #fff;
    color: $body-color;
    border: 0;
    border-bottom: 1px solid $body-color;
    border-radius: 0;
    transition: border-color $app-default-duration $app-default-easing;
    padding-right: 105px;

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &:-webkit-autofill {
        transition: none;
        caret-color: $body-color;
        border-color: $body-color;

        &,
        &:focus,
        &:active,
        &:hover {
            transition: background-color 9999s ease; // суперкостыль, чтобы пофиксить autofill в iOS
            color: $body-color !important;
            -webkit-text-fill-color: $body-color !important;
            box-shadow: inset 0 0 0 1000px #fff !important;
        }
    }

    &::-webkit-credentials-auto-fill-button {
        visibility: hidden;
    }

    &::placeholder {
        opacity: 0.45;
        color: currentColor;
    }

    &.is-error {
        border-color: $danger;
    }

    &:disabled {
        opacity: $btn-disabled-opacity;
        cursor: not-allowed;
        border-color: currentColor;

        ~ * {
            opacity: $btn-disabled-opacity;
        }
    }
}

.filterable-search-results__form {
    width: 100%;
    position: relative;
    display: flex;
    height: 66px;

    @include small-laptop {
        height: 50px;
    }

    @include tablet {
        height: 42px;
    }
}

.filterable-search-results__input-block {
    margin-top: 80px;

    @include small-laptop {
        margin-top: 60px;
    }
}

.filterable-search-results__content {
    margin-top: 100px;
    display: flex;
    height: 100%;
    justify-content: space-between;
    gap: 100px;
    margin-bottom: 90px;
    min-height: 70vh;

    & .organize-section-content-top-mobile {
        border: none;
    }

    @include small-laptop {
        margin-top: 80px;
        margin-bottom: 60px;
    }

    @include tablet {
        flex-direction: column;
        gap: 8px;
        margin-top: 40px;
        margin-bottom: 80px;
        justify-content: flex-start;
    }
}

.filterable-search-results__btns {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    gap: 10px;
}

.filterable-search-results .menu-popup-close-btn {
    width: 42px;
    height: 42px;

    @include small-laptop {
        width: 30px;
        height: 30px;
    }
}

.filterable-search-results__btns .filterable-search-results__btn {
    height: 42px;

    @include small-laptop {
        height: 30px;
        padding: 11px 16px;
        line-height: 1;
        min-height: auto;
        gap: 8px;

        &.btn.btn-outline-dark:not(:disabled):hover::after {
            width: 22px;
            height: 22px;
        }

        &.btn.btn-outline-dark:not(:disabled):hover .btn__arrow {
            height: 12px;
            transform: translate(-65%, -50%);
        }
    }

    @include mobile {
        display: none;
    }
}

.filterable-search-results__aside {
    @include grid-columns(3);
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    min-width: fit-content;
}

.filterable-search-results__main {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 60px;
    width: 100%;
}

.search-results__list-wrapper {
    display: flex;
    flex-direction: column;
    gap: 60px;

    @media screen and (max-width: 1600px) {
        gap: 40px;
    }

    & .pagination-list {
        margin-bottom: 0;
    }
}

.search-results__empty {
    text-align: left;
    color: $primary;
}
