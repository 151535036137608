.constructor-people {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @include small-laptop {
        gap: 20px;
    }

    @include mobile {
        gap: 24px;
    }
}

.constructor-people-title {
    color: $primary;
    padding-bottom: 24px;
    border-bottom: 1px solid #dcdcdd;

    @include small-laptop {
        padding-bottom: 16px;
    }

    @include mobile {
        padding-bottom: 20px;
    }
}

.constructor-people-list {
    display: flex;
    flex-direction: column;
    gap: 36px;

    @include small-laptop {
        gap: 24px;
    }
}

.constructor-people-theme {
    color: $primary;
    margin-top: 20px;

    @include small-laptop {
        margin-top: 16px;
    }
}

.constructor-people-name {
    margin-bottom: 16px;

    @include small-laptop {
        margin-bottom: 12px;
    }
}

.constructor-people-above {
    opacity: 0.4;
}

.constructor-people-position {
    opacity: 0.6;
}

.constructor-people-list-image-item {
    display: flex;
    gap: 40px;

    .constructor-people-name {
        margin-bottom: 0;
    }
}

.constructor-people-image {
    --size: 100px;
    background: linear-gradient(180deg, #05164a 0%, #082e74 78.81%);
    border-radius: 8px;
    object-fit: cover;
    width: var(--size);
    height: var(--size);

    @include small-laptop {
        --size: 90px;
    }
}

.constructor-people-text {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-end;
    max-width: 320px;

    @include small-laptop {
        gap: 18px;
    }
}
