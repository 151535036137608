.expo-page {
    .index-hero__left {
        .index-hero__title {
            margin-block: 0;
            text-align: left;
        }

        @include tablet {
            gap: 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            padding-bottom: 80px;

            .index-hero__title {
                margin-left: 0;
                margin-right: auto;
                @include grid-columns(4);
            }

            .index-hero__expo-content {
                @include grid-columns(6);
                @include grid-offset(padding-right, 2);

                @include tablet {
                    @include grid-offset(padding-right, 1);
                }
            }
        }

        @include mobile {
            display: flex;
            flex-direction: column;
            gap: 40px;
            width: 100%;
            align-items: self-start;

            .index-hero__title {
                margin-left: 0;
                margin-right: auto;
                @include grid-columns(5);
            }
            .index-hero__expo-content {
                padding-right: 0;
                @include grid-columns(10);
            }
        }
    }

    .questions-section {
        --bg-color: #{$white};
    }
}
.expo-section-wrapper {
    display: flex;
    min-height: 100vh;
    min-height: 100svh;
    z-index: 4;
    --padding: 120px;
    padding-bottom: var(--padding);
    justify-content: space-between;
    position: relative;

    @include tablet {
        --padding: 112px;
        flex-direction: column;
    }

    @include mobile {
        --padding: 80px;
    }

    .second-section-wrapper {
        margin-top: 0;
    }

    .hero-btn {
        display: none;
    }
}

.expo-page {
    .partners-section {
        background-color: #fff;
    }

    .partners-top {
        padding-left: var(--offset-x);
        padding-bottom: 167px;

        @include tablet {
            padding-bottom: 60px;
        }

        @include mobile {
            padding-bottom: 30px;
        }
    }
    .partners-title {
        color: $dark;
        width: 25%;

        @include tablet {
            width: 35%;
        }

        @include mobile {
            width: 100%;
        }
    }

    .partners-description-block {
        display: none; //потом добавить проверку на дескрипшн чтобы не выводился блок
    }

    .constructor-section {
        z-index: 5;
    }
    .constructor-section,
    .how-it-was-section {
        background-color: $light;
    }
    .how-it-was-section {
        color: $dark;
        .dots {
            height: 15px;
        }
        .slider-prev-btn,
        .slider-next-btn {
            border-color: $dark;

            svg path {
                fill: $dark;
            }
        }
    }

    .faq-section {
        background-color: $light-blue;
    }
    .gradient-section-top {
        left: 0;
        right: auto;
        padding-inline: var(--offset-x);
    }

    .reviews-section {
        background-color: $light-blue;

        .reviews-slider-img-wrapper {
            background: linear-gradient(180deg, #05164a, #082e74 78.81%);
        }
    }
    .partners-section {
        min-height: auto;
    }
}

.draft-section {
    min-height: 100vh;
    min-height: 100svh;
    z-index: 4;
    position: relative;

    display: grid;
    grid-template-columns: repeat(var(--grid-columns), 1fr);
    gap: 15px;

    @include tablet {
        display: flex;
        flex-direction: column;
        margin-top: 0;
    }

    @include mobile {
        padding-bottom: 80px;
        padding-top: 0;
    }
}

.draft__descr-wrapper {
    grid-column: 1 / 5;
    position: relative;

    @include tablet {
        max-width: 50%;
    }

    @include mobile {
        max-width: 100%;
    }
}

.draft__descr {
    color: #fff;
    max-width: 90%;
    padding-top: 5vh;
    padding-bottom: 10vh;
    position: sticky;
    top: 30%;

    @include tablet {
        padding-block: 0;
        letter-spacing: -0.42px;
        line-height: 18px;
    }
}

.draft__list-container {
    grid-column: 5 / -1;
    padding-top: 75vh;
    position: sticky;
    bottom: 0;

    @include tablet {
        padding-top: 30vh;
        min-height: 100vh;
        min-height: 100svh;
        position: relative;
    }

    @include mobile {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding-top: 150px;
    }
}

.draft__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    .draft__item:nth-child(odd) {
        transform: translateY(-120px);
    }

    @include mobile {
        display: flex;
        flex-direction: column;
        .draft__item:nth-child(odd) {
            transform: translateY(0px);
        }
    }
}

.expo-video__wrapper {
    width: 100%;
    position: relative;

    .showreel-video-wrapper {
        transition: opacity 0.9s ease-in-out;
        &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            background-color: rgba(0, 0, 0, 0.3);
        }
    }
}

.expo-video__title {
    color: #fff;
    z-index: 2;
    padding-left: var(--offset-x);
    position: absolute;
    bottom: 60px;

    @include tablet {
        bottom: 100px;
    }

    @include mobile {
        bottom: 50px;
        width: 70%;
    }
}

.draft-section-wrapper {
    min-height: 200vh;
    width: 100%;
}

.expo-first__section {
    min-height: 100vh;
    min-height: 100svh;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include tablet {
        align-items: flex-start;
        padding-bottom: 60px;
    }

    @include mobile {
        padding-bottom: 20px;
    }
}

.index-hero__expo-content {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include grid-columns(3);

    .dropdown-content {
        @include grid-columns(4);

        @include tablet {
            @include grid-columns(6);
        }

        @include mobile {
            @include grid-columns(12);
        }
    }

    .dropdown-btn {
        &:focus-visible {
            outline: 1px solid transparent;
            outline-offset: 0px;
        }
    }

    .dropdown-content-inner {
        .pdf_svg__pdf-text {
            stroke-width: 0.5px;
            fill: $white;
        }
    }
}

.expo-docs-btn {
    path {
        fill: $white;
    }

    .dropdown--opened {
        background-color: $primary;
        color: $white;
    }
}

.expo-docs-btn.dropdown--opened {
    color: $white;

    &.middle-out:before {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .dropdown-btn path {
        fill: $white;
    }
}

.expo-docs-btn {
    width: fit-content;
    border: 1.5px solid $dark;

    .header-arrow-svg {
        transform-origin: center;
    }

    path {
        fill: $dark;
    }
}

.dropdown-list__item-wrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    margin-right: 15px;
}

.expo-menu-dropdown-link {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    svg {
        margin-left: 10px;
        flex-shrink: 0;
        path {
            fill: transparent;
            stroke: $white;
        }
    }

    .menu-dropdown-link__text {
        text-wrap: initial;
    }
}

.expo-pdf-svg {
    margin-right: 20px;
}
