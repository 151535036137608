.constructor-video {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media screen and (max-width: 1366px) {
        gap: 12px;
    }
}

.constructor-video {
    .constructor-video-text {
        opacity: 0.6;
    }
}

.constructor-video-content {
    border-radius: 18px;
}

.constructor-video-content__video {
    animation: fade-in 1s $app-default-easing forwards;
}
