.subscribe-popup {
    .menu-popup-close-btn {
        position: absolute;
        right: var(--offset-x);
        top: var(--offset-x);
    }

    .popup-container {
        right: 0;
        background-color: $white;
        height: 100%;
        width: 670px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 70px;
        gap: 60px;

        @include tablet {
            width: 100%;
        }
    }
}

.subscribe-popup-input {
    border: 1px solid #dcdcdd;
    background-color: transparent;
    border-radius: 33px;
    padding: 0px 5px 0px 25px;
    outline: none;
    color: $dark;
    height: 60px;
    width: 100%;
    box-shadow: inset 100px 100px 100px 100px $white;

    &::placeholder {
        color: #dcdcdd;
    }
}

.subscribe-popup-input-response {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 28px;
    text-align: center;
    background-color: $white;
    width: 100%;
    height: 100%;
}

.subscribe-popup-input-wrapper {
    position: relative;
    width: 100%;
}

.subscribe-popup-input-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    --size: 46px;
    width: var(--size);
    height: var(--size);
    background-color: $primary;
    top: 7px;
    right: 7px;
    position: absolute;
    border-radius: 50%;
    transition: opacity 0.3s ease;

    path {
        fill: $white;
    }
}

.subscribe-popup-input-btn.is-submitting {
    opacity: 0.5;
}

.subscribe-popup-text {
    max-width: 370px;
}

.subscribe-popup-response-description {
    color: #7a7a7b;
    width: 50%;
}
