@include configurator() {
    .close-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 10px;

        @media screen and (max-width: 1366px) {
            gap: 4px;
        }
    }

    .close-button__icon {
        --size: 40px;
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .close-button-default {
        color: $configurator-primary;

        .close-button__icon {
            border: 1px solid rgba($configurator-primary, 0.2);
            background-color: transparent;

            svg path {
                &[fill] {
                    fill: currentColor;
                }

                &[stroke] {
                    stroke: currentColor;
                }
            }
        }
    }

    .close-button-white {
        color: #fff;

        .close-button__icon {
            border: 1px solid #fff;
            background-color: #fff;

            svg path {
                &[fill] {
                    fill: $blue;
                }

                &[stroke] {
                    stroke: $blue;
                }
            }
        }
    }
}
