.organize-section {
    position: relative;
    z-index: 4;
    padding-block: var(--padding-section);
    background-color: $white;

    @include tablet {
        min-height: unset;
    }

    .organize-section-content-left {
        @include tablet {
            display: block;
            margin-bottom: 36px;
        }

        @include mobile {
            margin-bottom: 30px;
        }
    }
}

.organize-section__wrapper {
    display: flex;
    flex-direction: column;
    gap: 120px;

    @include tablet {
        gap: 90px;
        justify-content: flex-start;
    }

    @include mobile {
        gap: 64px;
    }
}

.organize-content-category-block--simple {
    padding-top: 0;
}

.organize-section-wrapper__simple {
    padding-block: var(--padding-section);
}
