.search-results__item {
    border-top: 1px solid #dcdcdd;
    transition: border-top-color $app-default-duration $app-default-easing;

    &:last-child {
        border-bottom: 1px solid #dcdcdd;
    }

    @include hover {
        border-top-color: transparent;
        transition: border-top-color $app-default-duration $app-default-easing;

        & + .search-results__item {
            border-top-color: transparent;
        }

        &:last-child {
            border-bottom-color: transparent;
        }

        .search-results__item-link {
            background-color: #deeffa;
        }
    }
}

.search-results__item-link {
    padding: 35px 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 12px;
    transition: background-color $app-default-duration $app-default-easing;

    path {
        stroke: $white;
    }

    @include hover {
        .search-results__item-link-btn {
            border-color: $primary;

            path {
                fill: $primary;
                stroke: $primary;
            }
        }
    }

    @include tablet {
        padding: 28px 20px;
    }

    @include mobile {
        padding: 24px 12px;
    }
}

.search-results__item-category,
.search-results__item-description {
    color: #7a7a7b;
}

.search-results__item-description,
.search-results__item-title {
    em {
        color: $primary;
        opacity: 1;
        font-weight: 600;
        font-style: normal;
    }
}

.search-results__item-link-btn {
    --size: 42px;
    --offset: 20px;
    min-width: var(--size);
    width: var(--size);
    height: var(--size);
    border: 1px solid $white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: border-color $app-default-duration $app-default-easing;
    cursor: pointer;
    position: absolute;
    right: var(--offset);
    top: var(--offset);

    path {
        transition:
            fill $app-default-duration $app-default-easing,
            stroke $app-default-duration $app-default-easing;
        fill: $white;
    }

    @include small-laptop {
        --size: 30px;
    }
    @include tablet {
        display: none;
    }
}
