@include configurator() {
    .configurator-banners {
        margin-top: 66px;
    }

    .banner-group {
        display: flex;
        flex-direction: column;
        gap: 20px;

        &:not(:last-child) {
            margin-bottom: 76px;
        }
    }

    .banners-list {
        display: flex;
        --gap: 10px;
        gap: var(--gap);
        flex-wrap: wrap;
    }

    .banners-list-item {
        width: calc(50% - var(--gap) / 2);

        @include tablet {
            width: 100%;
        }
    }

    .banners-item {
        width: 100%;
        height: 130px;
        border: 1px solid rgba(#c4c4c4, 0.5);
        border-radius: 16px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;
        transition:
            background-color $app-default-easing 0.4s,
            border-color $app-default-easing 0.4s;

        &.banner-item--active {
            background-color: $light-blue;
            border-color: $light-blue;
        }
    }

    .banners-item__top {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    .banners-item__slug {
        --size: 32px;
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        background-color: $blue;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: none;
    }

    .banners-item__text {
        max-width: 140px;
        opacity: 0.4;
        text-wrap: balance;
    }

    .banners-item-cross {
        opacity: 0.4;
    }
}
