@mixin configurator() {
    .constructor-popup,
    .constructor-form-popup,
    .stand-popup,
    .constructor-gallery-popup,
    .map-filters-popup,
    .map-detail-popup {
        @content;
    }
}
