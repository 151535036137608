.tariffs-info-section {
    padding-top: 188px;
    padding-bottom: 90px;

    @media screen and (max-width: 1366px) {
        padding-top: 128px;
        padding-bottom: 60px;
    }

    @include tablet {
        padding-top: 122px;
        padding-bottom: 20px;
    }

    @include mobile {
        padding-top: 120px;
        padding-bottom: 26px;
    }
}

.tariffs-info-section__top {
    display: flex;
    gap: var(--grid-gap);
    margin-bottom: 124px;

    @media screen and (max-width: 1366px) {
        margin-bottom: 84px;
    }

    @include tablet {
        margin-bottom: 54px;
    }

    @include mobile {
        margin-bottom: 50px;
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;
        gap: 20px;
    }
}

.tariffs-info-section__top-right {
    margin-left: auto;
    flex-shrink: 0;
    @include grid-columns(4);
    display: flex;
    align-items: flex-start;
    gap: 30px;

    @include tablet {
        flex-direction: column;
        gap: 34px;
        @include grid-columns(5);
    }

    @media screen and (max-width: 767px) {
        gap: 36px;
        margin-left: 0;
        width: 100%;
    }
}

.tariffs-info-section__top-text {
    max-width: 287px;

    @include tablet {
        max-width: 190px;
    }
}

.tariffs-info-section__account-btn {
    margin-left: auto;
    flex-shrink: 0;

    @include tablet {
        margin-left: 0;
    }
}

.tariffs-info-section__table-container {
    --cell-padding-x: 34px;
    --cell-padding-y: 12px;
    --table-gap: 4px;
    width: 100%;

    @media screen and (max-width: 1366px) {
        --cell-padding-y: 10px;
        --cell-padding-x: 16px;
    }

    @include tablet {
        --cell-padding-x: 30px;
    }

    @media screen and (max-width: 767px) {
        --cell-padding-x: 12px;
        --cell-padding-y: 11px;
    }

    @media screen and (max-width: 355px) {
        --cell-padding-x: 8px;
    }
}

.tariffs-info-section__table {
    contain: content;
}

.tariffs-info-section__table-head {
    display: flex;
    gap: var(--table-gap);

    @include tablet {
        flex-direction: column;
        background-color: $light-blue;
        border-radius: 10px;
    }

    &.is-hidden {
        .tariffs-info-section__column-description-el {
            display: none;
        }

        @include tablet {
            display: none;
        }
    }

    .tariffs-info-section__table-cell {
        @include tablet {
            color: inherit;
            flex-direction: row;
            align-items: center;
            align-items: last baseline;
            gap: var(--grid-gap);

            &:not(:last-child) {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%, 0);
                    width: calc(100% - var(--cell-padding-x) * 2);
                    height: 1px;
                    background-color: $gray;
                }
            }
        }

        &:empty {
            @include tablet {
                display: none;
            }
        }
    }

    @include tablet {
        margin-bottom: 40px;
    }

    .tariffs-info-section__table-cell {
        padding-top: 30px;

        &:not(:first-child) {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                width: 100%;
                height: var(--table-height, 0px);
                background-color: var(--bg-color);
                border-radius: 18px;

                @include tablet {
                    display: none;
                }
            }
        }
    }
}

.tariffs-info-section__table-head-mobile {
    display: none;
    gap: var(--table-gap);
    position: sticky;
    top: calc(var(--header-height) + 60px);

    @include tablet {
        display: flex;
    }

    .tariffs-info-section__table-cell {
        color: inherit;
        text-align: center;
        align-items: center;
        background-color: var(--bg-color);
        border-radius: 8px;
        padding-block: 20px;

        &:empty {
            display: none;
        }
    }

    .tariffs-info-section__column-title {
        @media screen and (max-width: 350px) {
            font-size: 12px;
        }
    }
}

.tariffs-info-section__table-body {
    .tariffs-info-section__table-cell {
        @include tablet {
            border-radius: 8px;
            min-height: 31px;
        }

        &:not(:first-child) {
            @include tablet {
                background-color: var(--bg-color);
            }
        }
    }
}

.tariffs-info-section__table-body__row {
    display: flex;
    gap: var(--table-gap);

    @include tablet {
        flex-wrap: wrap;
    }

    + .tariffs-info-section__table-body__row {
        @include tablet {
            margin-top: 12px;
        }
    }

    @include hover {
        .tariffs-info-section__table-cell:first-child {
            color: $primary;
        }

        &:not(:last-child):not(.tariffs-info-section__table-body__row-bottom) {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                z-index: -1;
                top: 50%;
                left: 0;
                width: 100%;
                height: calc(100% + var(--cell-padding-y) * 0.5);
                background-color: $gray;
                transform: translate(0, -50%);
            }
        }
    }

    &.tariffs-info-section__table-body__row--hide-tablet {
        @include tablet {
            display: none;
        }
    }
}

.tariffs-info-section__table-body__row-bottom,
.tariffs-info-section__table-cell--only-price {
    padding-top: 45px;

    @media screen and (max-width: 1366px) {
        padding-top: 40px;
    }
}

.tariffs-info-section__table-cell {
    --bg-color: #{$light};
    padding: var(--cell-padding-y) var(--cell-padding-x);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid transparent;

    &:nth-child(1) {
        @include grid-columns(4);
        flex-shrink: 0;

        @include tablet {
            width: 100%;
        }

        &:not(:last-child) {
            margin-right: calc(var(--grid-gap) - var(--table-gap));
        }
    }

    &:not(:nth-child(1)) {
        --columns: 4;
        flex: 1 1 calc(100% / var(--columns) - var(--table-gap) / var(--columns) * (var(--columns) - 1));
    }
}

.tariffs-info-section__table-cell__check {
    --size: 12px;
    border-radius: 50%;
    width: var(--size);
    height: var(--size);
    border: 1px solid $primary;
    margin: auto;

    &.is-checked {
        background-color: $primary;
    }

    @media screen and (max-width: 1366px) {
        --size: 8px;
    }
}

.tariffs-info-section__column-title {
    @include tablet {
        @include responsive-font-size(14px, 16px, 360px, 768px);
    }

    &:not(:last-child) {
        margin-bottom: 28px;

        @media screen and (max-width: 1366px) {
            margin-bottom: 14px;
        }

        @include tablet {
            margin-bottom: 0;
        }
    }
}

.tariffs-info-section__column-title--main {
    @include tablet {
        padding-left: var(--cell-padding-x);
        @include grid-columns(6);
    }

    @media screen and (max-width: 767px) {
        width: 200px;
        flex-shrink: 0;
    }

    @media screen and (max-width: 480px) {
        width: 115px;
    }
}

.tariffs-info-section__column-description {
    @include tablet {
        margin-left: auto;
        @include grid-columns(5);
        margin-right: calc(-1 * var(--cell-padding-x));
        padding-right: var(--cell-padding-x);
    }

    @media screen and (max-width: 767px) {
        width: 100%;
        margin-left: 0;
    }
}

.tariffs-info-section__column-description-el {
    opacity: 0.6;

    @include tablet {
        max-width: 200px;
    }

    @media screen and (max-width: 767px) {
        max-width: none;
    }
}

.tariffs-info-section__table-mobile-prices {
    display: none;

    &:not(:first-child) {
        margin-top: 30px;
    }

    @include tablet {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

.tariffs-info-section__table-mobile-price-block {
    --bg-color: #{$light};
    border-radius: 10px;
    background-color: var(--bg-color);
    display: flex;
    gap: var(--grid-gap);
    padding: 20px 0;
}

.tariffs-info-section__table-mobile-price-block__content {
    padding-right: var(--cell-padding-x);

    @include tablet {
        flex: 1 1 auto;
        display: flex;
        gap: var(--grid-gap);
        padding-right: 0;
    }

    @media screen and (max-width: 767px) {
        flex: 1 1 auto;
        margin-left: 0;
        width: 100%;
        padding-right: 0;
        display: block;
    }
}

.tariffs-info-section__table-mobile-price-block__price-el {
    --gap-y: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @include tablet {
        @include grid-columns(3, min-width);
        flex-shrink: 0;
        padding-right: 36px;
    }

    @media screen and (max-width: 767px) {
        min-width: 0;
        padding-right: 0;
    }

    + .tariffs-info-section__table-mobile-price-block__price-el {
        margin-top: var(--gap-y);
        padding-top: var(--gap-y);

        @include tablet {
            border-top: 0;
            padding-top: 0;
            margin-top: 0;
            position: relative;
            padding-right: 0;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: -36px;
                width: 1px;
                height: 80%;
                background-color: $gray;
            }
        }

        @media screen and (max-width: 767px) {
            --gap-y: 10px;
            margin-top: var(--gap-y);
            padding-top: var(--gap-y);
            border-top: 1px solid $gray;

            &::before {
                display: none;
            }
        }
    }
}

.tariffs-info-section__table-mobile-price-block__price-final {
    opacity: 0.6;
}

.tariffs-info-section__table-cell--final-price {
    opacity: 0.3;
}

.tariffs--last-row {
    padding-bottom: calc(40px - var(--cell-padding-y));
}
