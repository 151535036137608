.schema-section {
    padding-top: 120px;
    padding-bottom: 90px;
    background-color: $light-blue;

    @media screen and (max-width: 1366px) {
        padding-top: 88px;
        padding-bottom: 60px;
    }

    @include tablet {
        padding-top: 108px;
        padding-bottom: 112px;
        display: grid;
        grid-gap: 0 var(--grid-gap);
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto auto auto;
    }

    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        padding-top: 76px;
        padding-bottom: 80px;
    }
}

.schema-section-header {
    display: flex;
    align-items: flex-start;
    gap: var(--grid-gap);
    margin-bottom: 120px;

    @include tablet {
        display: contents;
    }
}

.schema-section-doc {
    margin-left: auto;

    @include tablet {
        grid-column: 2 / -1;
        grid-row: 2 / 3;
        margin-left: 0;
        justify-self: start;
    }

    @media screen and (max-width: 767px) {
        order: 2;
        align-self: flex-start;
    }

    &:not(:first-child) {
        @include tablet {
            margin-top: 36px;
        }
    }
}

.schema-section-title {
    @include grid-columns(8, max-width);
    width: 100%;

    @include tablet {
        padding-right: 60px;
        @include grid-columns(6, max-width);
    }

    @media screen and (max-width: 767px) {
        padding-right: 0;
        margin-bottom: 36px;
    }
    @include mobile {
        max-width: 100%;
    }
}

.schema-section-content {
    display: flex;
    gap: var(--grid-gap);

    @include tablet {
        display: contents;
    }
}

.schema-section-schema {
    @include grid-columns(8);
    flex-shrink: 0;

    @include tablet {
        width: 100%;
        grid-column: 1 / -1;
        grid-row: 3 / 4;
        margin-top: 60px;
    }

    @media screen and (max-width: 767px) {
        order: 3;
        margin-top: 50px;
    }
}

.schema-section-img-wrapper {
    --aspect-ratio: calc(1180 / 568);
    --object-fit: contain;
    background-color: #fff;
    border-radius: 18px;
    overflow: hidden;
    overflow: clip;

    @media screen and (max-width: 1366px) {
        border-radius: 12px;
    }
}

.schema-section-info-wrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    @include tablet {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    }
}

.schema-section-info {
    padding-left: 40px;
    margin: auto;
    letter-spacing: -0.03em;

    @include tablet {
        padding-left: 0;
        margin: 0;
    }
}

.schema-list {
    display: flex;
    background-color: $light;
    border-radius: 100px;
}

.schema-section-schema-list {
    position: absolute;
    z-index: 1;
    top: 20px;
    right: 20px;

    @media screen and (max-width: 1366px) {
        top: 14px;
        right: 14px;
    }

    @include tablet {
        top: 12px;
        right: 12px;
    }

    @include mobile {
        display: none;
    }
}

.schema-section-schema__image-btn {
    --padding-x: 16px;
    position: absolute;
    bottom: 18px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    gap: 12px;
    letter-spacing: -0.03em;

    &,
    &.btn {
        display: none;

        &::after {
            width: 6px;
            height: 6px;
        }

        @include mobile {
            display: inline-flex;
        }
    }
}

.schema-list-btn {
    &.anchor-item {
        &:last-child {
            @include tablet {
                padding-right: 12px;
            }
        }
    }
}
