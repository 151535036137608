.index-hero {
    display: flex;
    justify-content: space-between;
    --index-hero-logo-height: 110px;
    height: 100%;

    @include tablet {
        flex-direction: column;
        align-items: center;
    }
}

.index-hero__logo {
    height: var(--index-hero-logo-height);
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
        width: 85px;
        height: fit-content;
    }

    svg,
    img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
    }
}

.index-hero__left {
    display: flex;
    flex-direction: column;
    padding-bottom: var(--index-hero-logo-height);
    width: 29.375vw;
    align-items: flex-start;
    justify-content: center;
    gap: 180px;

    @include tablet {
        align-items: center;
        gap: 50px;
        width: 100%;
        padding-bottom: 100px;
    }

    @include mobile {
        padding-bottom: 64px;
    }
}

.index-hero__right {
    flex-grow: 1;
    display: flex;
    --gap: 10px;
    gap: var(--gap);

    @include grid-columns(8);
    aspect-ratio: 118 / 79;

    @include tablet {
        width: 100%;
        aspect-ratio: 181 / 161;
    }
}

.index-hero__title {
    margin-block: auto;
    text-align: left;

    @include tablet {
        text-align: center;

        &.h3,
        .constructor-section &.h3 {
            @include responsive-font-size(35px, 46px, 360px, 768px);
        }
    }

    @media screen and (max-width: 767px) {
        &.h3,
        .constructor-section &.h3 {
            @include responsive-font-size(28px, 34px, 360px, 768px);
        }
    }
}

.index-hero-block {
    border-radius: 36px;
    position: relative;
    overflow: hidden;
    overflow: clip;
    width: calc(50% - var(--gap) / 2);
    display: flex;
    text-align: left;

    @include tablet {
        border-radius: 24px;
    }

    @include mobile {
        width: 50%;
        border-radius: 16px;
    }

    &:last-child .index-hero-block__inner {
        justify-content: flex-end;
        align-items: flex-end;
        text-align: right;
    }

    @include hover {
        .index-hero-img {
            transform: scale(1.03, 1.03);
        }
    }
}

.index-hero-img {
    object-fit: cover;
    transition: transform 0.8s ease-in-out;
}

.index-hero-block__inner {
    position: relative;
    z-index: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    height: 100%;

    @include tablet {
        padding: 24px;
        gap: 20px;
    }

    @include mobile {
        padding: 16px;
        gap: 12px;
    }
}

.constructor-section {
    width: 100%;
    z-index: 4;

    padding-top: calc(var(--header-height) + 80px);
    padding-bottom: 80px;

    --padding: 112px;
    background-color: $white;

    @include tablet {
        --padding: 80px;
        min-height: unset;
        padding-block: var(--padding);
        gap: 0;
    }
}

.constructor-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: $dark;
    gap: 90px;

    @media screen and (max-width: 1366px) {
        gap: 60px;
    }

    @include tablet {
        flex-direction: column;
        gap: 0;
    }
}

.constructor-popup {
    --offset-x: 25px;
    --offset-y: 29px;

    @include tablet {
        --offset-x: unset;
    }

    .popup-container {
        width: 100%;
        background-color: #f5f5f5;
        display: flex;
        flex-direction: column;
        padding: 30px var(--offset-x);
        contain: strict;
        overscroll-behavior: none;

        @include tablet {
            padding-bottom: 0;
        }
    }

    &.popup--opened {
        .webgl-canvas.webgl-canvas--stand-picker {
            opacity: 0;
            --animation-delay: 0.1s;
            --animation-duration: 0.5s;
        }
    }
}

.street-exposition-popup {
    --offset-y: 29px;
    @include recalculate-grid(20);

    @media screen and (max-width: 1366px) {
        @include recalculate-grid(16);
    }

    @include tablet {
        @include recalculate-grid(8);
    }

    .popup-container {
        @include mobile {
            padding-bottom: 0;
        }
    }

    .page-top {
        @media screen and (max-width: 767px) {
            margin-bottom: 26px;
            justify-content: center;
        }
    }
}
