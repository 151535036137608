.breadcrumbs {
    z-index: 4;
    max-width: 100%;
}

.breadcrumbs-inner {
    overflow-x: auto;
    overflow-y: hidden;
    @include hidden-scrollbar();
}

.breadcrumbs__list {
    display: flex;
    gap: 9px;
    width: max-content;
}

.breadcrumbs__list-item {
    display: flex;
    align-items: center;
    gap: 9px;
    flex-shrink: 0;

    @include mobile {
        gap: 12px;
    }

    &:not(:last-child) {
        &::after {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: $dark;
            opacity: 0.4;
        }

        .breadcrumbs__link {
            opacity: 0.4;
        }

        a.breadcrumbs__link {
            @include hover {
                opacity: 1;
                color: $primary;
            }

            &:focus-visible {
                opacity: 1;
            }
        }
    }
}

a.breadcrumbs__link {
    transition:
        opacity $app-default-duration ease,
        color $app-default-duration ease;

    @include hover {
        opacity: 0.7;
    }

    &:focus-visible {
        opacity: 0.7;
    }
}
