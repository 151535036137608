.fullscreen-video-section-wrapper {
    height: calc(100vh - var(--header-height) - var(--anchors-height));
    height: calc(100svh - var(--header-height) - var(--anchors-height));
    width: 100%;
    position: relative;
    z-index: 4;

    .video-play-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 7;
        background: transparent;
        cursor: pointer;
        pointer-events: all;
    }
}
