/**
  * @tokens Others
  */
$btn-disabled-opacity: 0.5;

$app-default-duration: 0.25s;
/**
  * @tokens-end
  */

/**
  * @tokens Easings
  * @presenter Easing
  */
$app-default-easing: cubic-bezier(0.25, 0.25, 0, 1);
/**
  * @tokens-end
  */

/**
  * @tokens ColorPalette
  * @presenter Color
  */
$light: #f2f2f2;
$dark: #212123;
$white: #ffffff;
$primary: #0066f6;
$configurator-primary: #01154d;
$blue: #082e74;
$dark-blue: #05164a;
$light-blue: #deeffa;
$dark-gray: #7a7a7b;
$gray: #dfe1e3;
$gray-2: #f9f9f9;
$gray-3: #f5f5f5;
$danger: #da3f3f;
$border-color: #dcdcdd;
$primary-2: #0055ff;

/**
  * @tokens-end
  */

/**
  * @tokens Colors
  * @presenter Color
  */
$body-bg: #fff;
$body-color: $dark;
/**
  * @tokens-end
  */

/**
  * @tokens Fonts
  * @presenter FontFamily
  */
$font-fallback:
    system-ui,
    -apple-system,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    'Liberation Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
$font-family: 'BrownLLPan', $font-fallback;
/**
  * @tokens-end
  */
