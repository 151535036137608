.error-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 100%;
    overflow: hidden;
    overflow: clip;
    border: 1px solid $danger;
    padding: 20px;
    max-width: 600px;
    margin: auto;
}

.error-message__title {
    color: $danger;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
}

.error-message__error-message {
    color: $danger;

    &:not(:last-child) {
        margin-bottom: 40px;
    }
}

.error-message__reset {
    @include clickable();
}
