html.js [data-reveal] {
    &:not([data-reveal-type]) {
        opacity: 0;
        transform: translate3d(0, 30px, 0);
        transition:
            opacity 0.8s $app-default-easing 0.1s,
            transform 0.8s $app-default-easing 0.1s;

        &.is-revealed {
            opacity: 1;
            transform: none;
        }
    }
}
