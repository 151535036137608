@include configurator() {
    .picker-list {
        display: flex;
        flex-direction: column;
        gap: 10px;

        margin-top: 43px;
    }

    .picker-item {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        border-radius: 16px;
        text-align: left;
        border: 1px solid rgba(#c4c4c4, 0.5);
        transition:
            background-color 0.33s ease,
            border-color 0.33s ease;
        width: 100%;

        &.picker-item--active {
            background-color: $light-blue;
            border-color: transparent;
        }
    }

    .picker-hint {
        opacity: 0.5;
        width: 66%;
        min-height: 40px;
        display: flex;
        align-items: flex-end;
    }

    .group-picker-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .configurator-group {
        display: flex;
        flex-direction: column;
        gap: 20px;

        &:not(:last-child) {
            margin-bottom: 76px;
        }
    }

    .configurator-group-picker {
        margin-top: 43px;
        margin-bottom: calc(var(--y-padding) * -1);
        padding-bottom: var(--y-padding);
    }

    .picker-item__title {
        line-height: 1.2;
        min-height: 48px;
    }

    .config-list-item__title {
        line-height: 1.2;
    }
}
