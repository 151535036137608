.data-table {
    width: 100%;
    letter-spacing: -0.04em;

    td {
        padding: 16px 10px;

        @media screen and (max-width: 1366px) {
            padding: 10px;
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    tbody {
        tr {
            &:not(:last-child) {
                border-bottom: 1px solid currentColor;
            }

            &:first-child {
                td {
                    padding-top: 0;
                }
            }
        }

        td {
            &:first-child {
                @include tablet {
                    opacity: 0.8;
                }

                @include mobile {
                    opacity: 0.5;
                }
            }

            &:last-child {
                text-align: right;
            }
        }
    }
}
