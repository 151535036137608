.constructor-popup,
.constructor-section {
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: -0.04em;
    color: $dark-blue;

    .text-default {
        font-size: 16px;
        line-height: 1.25;
        letter-spacing: -0.04em;

        @include mobile {
            font-size: 14px;
        }
    }

    h1,
    .h1 {
        @include responsive-font-size(46px, 70px, 1280px, 1920px);
        line-height: 1;
        letter-spacing: -0.05em;
        font-weight: 400;

        @media screen and (max-width: 767px) {
            @include responsive-font-size(35px, 46px, 360px, 768px);
        }
    }

    h2,
    .h2 {
        @include responsive-font-size(36px, 56px, 1280px, 1920px);
        line-height: 1;
        letter-spacing: -0.05em;
        font-weight: 400;

        @media screen and (max-width: 767px) {
            @include responsive-font-size(24px, 36px, 360px, 768px);
        }
    }

    h3,
    .h3 {
        @include responsive-font-size(26px, 40px, 1280px, 1920px);
        line-height: 1.05;
        letter-spacing: -0.05em;
        font-weight: 400;

        @media screen and (max-width: 767px) {
            font-size: 23px;
        }
    }

    .text-l {
        @include responsive-font-size(16px, 24px, 1280px, 1920px);
        line-height: 1;
        letter-spacing: -0.01em;

        @media screen and (max-width: 1366px) {
            letter-spacing: -0.05em;
        }

        @media screen and (max-width: 767px) {
            font-size: 18px;
        }
    }

    .text-m {
        @include responsive-font-size(14px, 20px, 1280px, 1920px);
        line-height: 1.2;
        letter-spacing: -0.04em;

        @include tablet {
            @include responsive-font-size(12px, 14px, 360px, 768px);
        }
    }

    .text-s {
        @include responsive-font-size(12px, 16px, 1280px, 1920px);
        line-height: 1.25;
        letter-spacing: -0.04em;
    }

    .text-xs {
        @include responsive-font-size(12px, 15px, 1280px, 1920px);
        line-height: 1.33;
        letter-spacing: -0.01em;
    }

    .text-xxs {
        font-size: 12px;
        line-height: 1.25;
        letter-spacing: -0.04em;

        @include tablet {
            font-size: 15px;
        }
    }
}
