.faq-section {
    min-height: 100vh;
    min-height: 100svh;
    z-index: 4;
    padding-top: calc(var(--header-height) + 120px);
    padding-bottom: 120px;
    background-color: $white;

    @include tablet {
        min-height: unset;
    }

    @include mobile {
        padding-bottom: 80px;
        padding-top: 80px;
    }
}

.faq-section-wrapper {
    display: flex;

    justify-content: space-between;

    @include tablet {
        flex-direction: column;
        gap: 90px;
        justify-content: flex-start;
    }

    @include mobile {
        gap: 48px;
    }
}

.faq-section-content {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @include tablet {
        width: 100%;
    }
}

.faq-section-content-item {
    width: 100%;
    padding: 40px 30px;
    position: relative;
    color: $dark;
    transition:
        background-color 0.3s ease,
        border-radius 0.3s ease,
        color 0.3s ease;
    z-index: 4;
    border-bottom: 1px solid $dark;

    &:first-child {
        border-top: 1px solid $dark;
    }

    &.collapse--opened {
        border-radius: 18px;
        border-bottom: 1px solid $white;
        border-top: 1px solid $white;
        color: $white;
        background-color: $primary;

        .faq-btn {
            transform: rotate(-90deg);
            border-color: $white;

            path {
                fill: $white;
            }
        }

        &::before {
            opacity: 1;
        }

        &:active {
            .faq-btn {
                transform: scale(0.9) rotate(-90deg);
            }
        }
    }

    &:active {
        .faq-btn {
            transform: scale(0.9) rotate(90deg);
        }
    }

    &::before {
        content: '';
        position: absolute;
        background-color: $white;
        width: 100%;
        height: 2px;
        top: -2px;
        left: 0;
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    @media screen and (max-width: 1366px) {
        padding-top: 30px;
        padding-inline: 20px;
        padding-bottom: 40px;
    }

    @include mobile {
        padding-top: 24px;
        padding-inline: 12px;
        padding-bottom: 30px;
    }
}

.collapse__toggler {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    z-index: 3;
    position: relative;
    gap: 30px;
}

.collapse__content {
    z-index: 3;
    position: relative;
    height: 0;
}

.faq-section-item-title {
    text-align: left;
}

.faq-btn {
    border: 1px solid $dark;
    --size: 46px;
    --offset: 12px;
    min-width: var(--size);
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:
        background-color 0.3s ease,
        transform 0.3s ease,
        border-color 0.3s ease;
    transform: rotate(90deg);

    path {
        transition: fill 0.3s ease;
    }

    cursor: pointer;

    @media screen and (max-width: 1366px) {
        --size: 30px;
        svg {
            width: 12px;
        }
    }
}

.faq-section-answer {
    margin-top: 65px;

    @media screen and (max-width: 1366px) {
        margin-top: 43px;
    }

    @include mobile {
        margin-top: 28px;
    }

    &.wysiwyg {
        a:not([class]) {
            color: $white;
        }

        ul:not([class]) > li:before {
            background-color: $white;
        }
    }
}
