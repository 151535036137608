.header {
    position: fixed;
    padding-top: 30px;
    height: var(--header-height);
    left: 0;
    padding-bottom: 10px;
    top: 0;
    width: 100%;
    z-index: 6;
    color: $white;
    transition: background-color 0.2s ease;

    html.no-scroll & {
        padding-right: var(--scrollbar-width, 0px);

        @include mobile {
            padding-right: 0;
        }
    }

    &.header-theme--dark {
        color: $dark;

        .header-logo-svg {
            path {
                fill: $dark;
            }
        }

        .blur-bg {
            background-color: $white;
            .header-btn {
                backdrop-filter: none;
            }
        }

        .primary-header-btn {
            background-color: $primary;
        }

        .header-lines-svg {
            path {
                stroke: $dark;
            }
        }

        .header-arrow-svg {
            path {
                fill: $dark;
            }
        }
    }

    @include mobile {
        padding-top: var(--offset-x);
    }

    .text-xs {
        @include responsive-font-size(13px, 15px, 1280px, 1920px);
    }
}

.participation-page {
    .header {
        background-color: $white;
        padding-bottom: 30px;

        &.header-theme--dark,
        &.header-theme--light {
            color: $dark;

            .header-logo-svg {
                path {
                    fill: $dark;
                }
            }

            .header-lines-svg {
                path {
                    stroke: $dark;
                }
            }

            .header-btn__content {
                color: $white;
            }

            .header-arrow-svg {
                path {
                    fill: $dark;
                }
            }
        }

        &.header-theme--light-bg {
            background-color: #fff;
        }
    }
}

.header-logo-svg {
    path {
        transition:
            fill 0.3s ease,
            opacity 0.3s ease;
    }
}

.header-lines-svg {
    path {
        transition: stroke 0.3s ease;
    }
}

.header-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
}

.header-btn {
    padding: 12px 15px;
    border-radius: 100px;
    color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    z-index: 5;
    backdrop-filter: blur(6px);
    position: relative;
    transition: 0.4s ease;
    &:before,
    &:after {
        background: $primary;
        border-radius: 100px;
        transition: 0.3s ease;
        content: '';
        position: absolute;
        z-index: -1;
    }

    @include mobile {
        padding-bottom: 8px;
        padding-top: 8px;
    }
}

.primary-header-btn {
    background-color: $primary;
    color: $white;
}

.blur-bg {
    -webkit-backdrop-filter: blur(22px);
    backdrop-filter: blur(6px);
    transition: background-color 0.3s ease;

    .header-btn {
        backdrop-filter: none;
    }
}

.header-left,
.header-center,
.header-right {
    display: flex;
    align-items: center;
}

.header-search {
    @media screen and (max-width: 1366px) {
        margin-left: auto;
        margin-right: auto;
    }

    @include mobile {
        display: none;
    }
}

.header-left {
    gap: 43px;

    @include mobile {
        width: 100%;
    }
}

.header-right {
    @include mobile {
        display: none;
    }
}

.header-center {
    position: relative;
    gap: 5px;
    border-radius: 100px;
    margin-left: auto;
    margin-right: auto;

    @include tablet {
        display: none;
    }
}

.header-right {
    gap: 12px;
    margin-left: auto;
}

.header-menu {
    display: flex;
    gap: 12px;
    max-height: 42px;

    @include mobile {
        margin-left: auto;
    }
}

.header-logo {
    width: 136px;
    height: 80px;

    @media screen and (max-width: 1600px) {
        width: 85px;
        height: 50px;
    }

    @include mobile {
        width: 74px;
        height: 44px;
    }

    svg {
        width: 100%;
        height: 100%;
    }
}

.dropdown-item {
    padding: 10px 20px;
    cursor: pointer;
    color: #fff;
    white-space: nowrap;
}

.dropdown-item:hover {
    background-color: $primary;
}

.header-arrow-svg {
    flex-shrink: 0;
    margin-left: 6px;
    transition: transform 0.3s $app-default-easing;

    path {
        transition: fill 0.3s ease;
    }

    .dropdown--opened & {
        transform: rotate(180deg);
    }

    [stroke]:not([stroke='none']) {
        stroke: currentColor;
    }
}

.header-btn__content {
    border-radius: 18px;
    padding: 27px 31px;
    background-color: $primary;
    color: #fff;
    width: max-content;
    min-width: 100px;
    position: absolute;
    top: calc(100% + 12px);
    left: 0;
}

.middle-out {
    &:before {
        top: 50%;
        right: 50%;
        bottom: 50%;
        left: 50%;
    }
    @include hover {
        &:before {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        color: #fff;

        .header-arrow-svg {
            path {
                fill: $white;
            }
        }

        .header-lines-svg {
            path {
                stroke: $white;
            }
        }
    }
}

.header-arrow-svg {
    path {
        fill: $white;
    }
}

.menu-dropdown-link {
    position: relative;

    &::before {
        content: '';
        height: 5px;
        width: 5px;
        background-color: currentColor;
        border-radius: 100%;
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translate(-10px, -50%);
        opacity: 0;
        transition:
            transform 0.5s ease,
            opacity 0.5s ease;
    }

    @include hover {
        &::before {
            transform: translate(0, -50%);
            opacity: 1;
        }
    }
}

.menu-dropdown-list {
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.header-btn__toggler {
    display: flex;
    align-items: center;
}

.full-logo {
    .logo_svg__logo-letter {
        opacity: 0;
    }
}
