.news-page-top {
    width: 100%;
    background-color: $white;
    display: flex;
    flex-direction: column;
}

.news-page-breadcrumbs {
    padding-top: calc(var(--header-height) + 60px);

    @media screen and (max-width: 1366px) {
        padding-top: calc(var(--header-height) + 40px);
    }
}

.news-page-title {
    flex-shrink: 0;
    @include grid-columns(8);
    @include grid-offset(padding-right, 1);

    @include tablet {
        @include grid-columns(5);
    }

    @include mobile {
        padding-right: 0;
        margin-bottom: 30px;
    }

    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

.news-page-title-block {
    display: flex;
    align-items: center;
    gap: var(--grid-gap);
    margin-top: 80px;
    margin-bottom: 120px;

    @media screen and (max-width: 1366px) {
        margin-top: 60px;
        margin-bottom: 90px;
    }

    @include tablet {
        margin-bottom: 70px;
        @include recalculate-grid(8);
    }

    @include mobile {
        margin-top: 40px;
        gap: 0px;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 70px;
    }
}

.news-page-list {
    position: relative;
    --columns: 4;
    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr);
    gap: 20px;
    row-gap: 40px;
    padding-top: 40px;
    padding-bottom: 90px;

    &.is-loading {
        opacity: $btn-disabled-opacity;
    }

    @include tablet {
        --columns: 2;
    }

    @media screen and (max-width: 767px) {
        --columns: 1;
    }
}

.news-page-anchors {
    display: flex;
    gap: 30px;
    padding: 10px 0px;
    border-bottom: 1px solid $gray;
    margin-bottom: 40px;

    @media screen and (max-width: 1366px) {
        margin-bottom: 30px;
    }

    @include tablet {
        overflow-y: auto;
        @include hidden-scrollbar;
        margin-inline: calc(-1 * var(--offset-x));
        padding-left: var(--offset-x);
    }
}

.news-page-anchor-item {
    padding: 12px;
    border-radius: 100px;
    color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    z-index: 5;
    backdrop-filter: blur(6px);
    position: relative;
    transition: 0.4s ease;
    cursor: pointer;
    &:before,
    &:after {
        background: $primary;
        border-radius: 100px;
        transition: 0.3s ease;
        content: '';
        position: absolute;
        z-index: -1;
        top: 50%;
        right: 50%;
        bottom: 50%;
        left: 50%;
    }

    @include hover {
        &:before {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        color: $white;
    }

    &:last-child {
        @include tablet {
            padding-right: var(--offset-x);
        }
    }
}

.news-page-presentation-btn {
    display: flex;
    gap: 40px;
    padding: 17px;
    border-radius: 18px;
    border: 1px solid #dcdcdd;
    align-items: center;
    cursor: pointer;
    transition: color 0.3s ease;

    .download-svg {
        path {
            transition: stroke 0.3s ease;
        }
    }

    .pdf_svg__pdf-text {
        transition: fill 0.3s ease;
    }

    .pdf_svg__pdf-icon {
        transition: stroke 0.3s ease;
    }

    @include hover {
        color: $primary;

        .download-svg {
            path {
                stroke: $primary;
            }
        }

        .pdf_svg__pdf-text {
            fill: $primary;
        }

        .pdf_svg__pdf-icon {
            stroke: $primary;
        }
    }
}

.presentation-btn-left {
    display: flex;
    gap: 11px;
    align-items: center;
}

.news-page-description-block {
    flex: 1 1 auto;
    display: flex;
    align-items: end;
    gap: 20px;

    @include tablet {
        flex-direction: column;
        align-items: start;
    }

    @include mobile {
        gap: 30px;
    }
}

.news-page-description {
    text-wrap: balance;

    @include tablet {
        max-width: 310px;
    }
}

.news-page-list-item {
    height: fit-content;
}

.news-item {
    height: inherit;
    display: flex;
    flex-direction: column;
    position: relative;

    @include hover {
        .news-list-item-title {
            color: $primary;
        }

        .news-list-item-btn {
            opacity: 1;
        }
    }
}

.news-list-item-img-wrapper {
    position: relative;
    border-radius: 18px;
    overflow: hidden;
    --aspect-ratio: calc(430 / 290);
    background-color: $light-blue;

    @media screen and (max-width: 1366px) {
        --aspect-ratio: calc(290 / 195);
    }

    @include tablet {
        --aspect-ratio: calc(335 / 240);
    }

    @include mobile {
        --aspect-ratio: calc(230 / 155);
    }
}

.news-list-item-title {
    margin-top: 25px;
    text-align: start;
    transition: color 0.3s ease;

    @media screen and (max-width: 1366px) {
        margin-top: 16px;
    }
}

.news-list-item-date {
    margin-top: 21px;
    text-align: start;
    opacity: 0.6;
}

.news-list-item-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.news-list-item-btn {
    --size: 42px;
    --offset: 20px;
    position: absolute;
    right: var(--offset);
    top: var(--offset);
    min-width: var(--size);
    width: var(--size);
    height: var(--size);
    border: 1px solid $white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease;
    opacity: 0;
    cursor: pointer;

    @include tablet {
        --size: 30px;

        svg {
            width: 12px;
        }
    }

    @include mobile {
        --offset: 20px;
    }

    path {
        transition: fill 0.3s ease;

        fill: $white;
    }
}
.news-top-block {
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid $gray;

    @include mobile {
        flex-wrap: wrap;
        gap: 12px;
    }
}

.news-filters {
    width: 100%;
    display: flex;
    max-width: 60vw;
    flex-wrap: wrap;
    gap: 8px;
}

.news-filter {
    padding: 13.5px 24px;
    border-radius: 100px;
    background-color: $light;
    color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    z-index: 5;
    position: relative;
    transition: 0.4s ease;
    cursor: pointer;
    &:before,
    &:after {
        background: $primary;
        border-radius: 100px;
        transition: 0.3s ease;
        content: '';
        position: absolute;
        z-index: -1;
        top: 50%;
        right: 50%;
        bottom: 50%;
        left: 50%;
    }

    &.is-active {
        &:before {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        color: $white;
    }

    @include hover {
        &:before {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        color: $white;
    }

    &:last-child {
        @include tablet {
            padding-right: var(--offset-x);
        }
    }
}

.news-select-item {
    position: relative;
    text-align: left;

    &::before {
        content: '';
        height: 5px;
        width: 5px;
        background-color: $white;
        border-radius: 100%;
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translate(7px, -50%);
    }
}

.page-limit-select {
    margin-left: auto;
    margin-right: 12px;
    flex-shrink: 0;

    @include tablet {
        margin-right: auto;
        margin-left: 12px;
    }

    @include mobile {
        margin-left: 0;
    }

    .news-filter__content {
        padding: 22px;
        border-radius: 18px;
        background-color: $primary;
        color: #fff;
        width: max-content;
        min-width: 230px;
        position: absolute;
        top: 52px;
        left: 0;
        transition: color 0.3s ease;

        @media screen and (max-width: 1366px) {
            min-width: 180px;
        }
    }

    .news-select-item {
        &::before {
            content: '';
            height: 5px;
            width: 5px;
            background-color: $white;
            border-radius: 100%;
            position: absolute;
            left: -15px;
            top: 50%;
            transform: translate(-10px, -50%);
            opacity: 0;
            transition:
                transform 0.5s ease,
                opacity 0.5s ease;
        }

        @include hover {
            &::before {
                transform: translate(5px, -50%);
                opacity: 1;
            }
        }

        &.is-active {
            &::before {
                transform: translate(5px, -50%);
                opacity: 1;
            }
        }
    }
}

.news-filter__content {
    padding: 22px;
    border-radius: 18px;
    background-color: $primary;
    color: #fff;
    width: max-content;
    min-width: 100px;
    position: absolute;
    top: calc(100% + 12px);
    left: 0;
    transition: color 0.3s ease;
}

.dropdown--opened {
    .news-select-btn {
        background-color: $primary;
        color: $white;
        border-color: $primary;

        path {
            fill: $white;
        }
    }
}

.news-select-btn {
    --border-color-default: $dark;
    --color-default: $dark;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: $dark;
    height: 42px;
    border-radius: 100px;
    border: 1px solid $dark;
    padding: 10px 16px;

    transition:
        color 0.3s $app-default-easing,
        background-color 0.3s $app-default-easing;
}

.news-arrow-svg {
    flex-shrink: 0;
    margin-left: 6px;
    transition: transform 0.3s $app-default-easing;
    width: 10px;
    height: 10px;

    path {
        fill: $dark;
    }

    .dropdown--opened & {
        transform: rotate(180deg);
    }

    [stroke]:not([stroke='none']) {
        stroke: currentColor;
    }
}

.news-list-not-found {
    text-align: center;
    padding-block: 150px;
    margin: auto;
    max-width: 700px;
    text-wrap: balance;

    @include tablet {
        padding-block: 100px;
    }

    @include mobile {
        padding-block: 70px;
    }
}

.news-list-error-message {
    text-align: center;
    padding-block: 150px;
    margin: auto;
    max-width: 700px;
    text-wrap: balance;

    @include tablet {
        padding-block: 100px;
    }

    @include mobile {
        padding-block: 70px;
    }
}

.news-page-btn {
    margin-left: auto;

    @include tablet {
        margin-left: 0;
    }
}
