.transition-white-bg,
.transition-blue-bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    transform: scale(0);
    background-color: $primary;
    z-index: 10;
    transform-origin: left;
}

.transition-white-bg {
    background-color: $white;
    z-index: 11;
}
