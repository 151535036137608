:root {
    // Боковые оффсеты страницы
    --offset-x: 70px;
    --padding-section: 188px 90px;

    // Максимальная ширина .wrapper
    --wrapper-max-width: 2560px;

    // Сетка
    --grid-columns: 12;
    --grid-gap: 20px;
    --grid-column-width: calc(
        Min(var(--wrapper-max-width), (100vw - var(--scrollbar-width, 0px) - var(--offset-x) * 2)) /
            var(--grid-columns) - var(--grid-gap) * ((var(--grid-columns) - 1) / var(--grid-columns))
    );

    // Высота хедера
    --header-height: 90px;

    --anchors-height: 0px;

    @media screen and (max-width: 1600px) {
        --offset-x: 36px;
        --grid-gap: 16px;
    }

    @media screen and (max-width: 1366px) {
        --padding-section: 128px 60px;
    }

    @include tablet {
        --offset-x: 22px;
        --padding-section: 112px;
    }

    @include mobile {
        --padding-section: 80px;

        --grid-gap: 12px;
        --offset-x: 16px;
    }
}
