@include configurator() {
    --camera-view-controls-border-radius: 16px;

    @media screen and (max-width: 1366px) {
        --camera-view-controls-border-radius: 10px;
    }

    .map-filters {
        display: flex;
        gap: 8px;
        margin-inline: auto;
        margin-top: 30px;
        position: relative;
        z-index: 1;

        @include tablet {
            flex-direction: column;
            padding: 12px;
            height: auto;
            gap: 32px;
            justify-content: space-between;
            margin-top: 0;
        }
    }

    .map-filter {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        background-color: #fff;
        border-radius: 16px;

        @include laptop {
            padding: 12px;
            gap: 12px;
        }

        @include tablet {
            padding: 0;
            gap: 16px;
        }

        --camera-view-controls-border-radius: 16px;

        @media screen and (max-width: 1366px) {
            --camera-view-controls-border-radius: 10px;
        }
    }

    .tablet-filters {
        margin-top: auto;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        padding-top: 80px;

        @include mobile {
            padding-top: 20px;
        }
    }

    .map-filter-title {
        opacity: 0.5;
    }

    .map-filter-list {
        display: flex;
        gap: 4px;
        align-items: center;

        @include tablet {
            flex-wrap: wrap;
        }

        @include mobile {
            .map-filter-list-item-wrapper {
                width: calc(50% - 4px);
            }
        }
    }

    .map-filter-list-item {
        height: 60px;
        padding: 10px 16px;
        border: 1px solid rgba(#c4c4c4, 0.5);
        transition-property: border-color, background-color, color;
        transition-timing-function: ease-in-out;
        transition-duration: 0.25s;
        border-radius: var(--camera-view-controls-border-radius);
        min-width: 60px;

        @include hover {
            &:not(.map-filter-list-item-active) {
                background-color: rgba($configurator-primary, 0.5);
                border-color: transparent;
                color: #fff;
            }
        }

        &.map-filter-list-item-active {
            background-color: $configurator-primary;
            color: #fff;
            border-color: transparent;
        }

        @include laptop {
            padding: 10px 16px;
        }

        @media screen and (max-width: 1366px) {
            height: 40px;
            padding-inline: 24px;
        }

        @include tablet {
            padding-block: 16px;
            padding-inline: 20px;
            width: 160px;
            height: 56px;
        }

        @include mobile {
            min-width: 115px;
            width: 100%;
        }
    }

    .filters-confirm-btn {
        padding: 12px;

        @include tablet {
            height: 56px;
            border-radius: var(--camera-view-controls-border-radius);
        }
    }

    .map-filters-popup-top {
        display: flex;
        justify-content: space-between;
    }

    .map-filters-popup-top-title {
        font-size: 26px;

        @include mobile {
            font-size: 23px;
        }
    }

    .map-filters-popup__close-btn {
        color: $configurator-primary;
    }
}
