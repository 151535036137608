.additional-info {
    padding-top: 182px;

    @media screen and (max-width: 1366px) {
        padding-top: 126px;
    }

    @include tablet {
        padding-top: 112px;
    }
    @include mobile {
        padding-top: 80px;
    }
}

.additional-info__title {
    @include grid-columns(3);
}

.additional-info__wrapper {
    margin-top: 80px;
    display: grid;
    grid-template-columns: repeat(var(--grid-columns), 1fr);
    grid-row-gap: 50px;
    grid-column-gap: 15px;

    @include tablet {
        grid-row-gap: 50px;
    }

    @include mobile {
        margin-top: 64px;
        grid-row-gap: 50px;
        grid-column-gap: 0;
    }
}

.additional-info__wrapper--large .additional-info-block__left {
    grid-column: 1 / 4;

    @include tablet {
        grid-column: 1 / 7;
    }

    @include mobile {
        grid-column: 1 / 8;
    }

    @media screen and (max-width: 576px) {
        grid-column: 1 / 12;
    }
}
.additional-info__wrapper--large .additional-info-block__right {
    grid-column: 5 / -1;

    @include tablet {
        grid-column: 1 / -1;
    }
}

.additional-info__wrapper--medium .additional-info-block__left {
    grid-column: 1 / 4;

    @include mobile {
        grid-column: 1 / 12;
    }
}
.additional-info__wrapper--medium .additional-info-block__right {
    grid-column: 7 / -1;

    @include mobile {
        grid-column: 1 / -1;
    }

    .team__item {
        margin-bottom: 30px;

        @include mobile {
            margin-bottom: 20px;
        }
    }
}

.additional-info__list {
    --gap: 30px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include mobile {
        gap: var(--gap);
        flex-direction: column;
    }

    .team__item {
        max-width: 100%;
    }
}
