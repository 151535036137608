.constructor-form-popup {
    opacity: 0;
    visibility: hidden;
    transition:
        opacity 0.3s ease,
        visibility 0.3s ease;

    &.popup--opened {
        visibility: visible;
        opacity: 1;
    }
}
