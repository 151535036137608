.program-page {
    .questions-section {
        --bg-color: #{$light};
    }

    .program-detail-title-btns {
        height: 70px;

        @include small-laptop {
            height: 59px;
        }

        @include mobile {
            height: 53px;
        }
    }
}
.program-detail-title {
    margin-top: 32px;
    margin-bottom: 120px;
    width: 100%;

    @media screen and (max-width: 1366px) {
        margin-top: 30px;
        margin-bottom: 90px;
    }

    @include tablet {
        margin-top: 24px;
        margin-bottom: 80px;
        max-width: 380px;
    }

    @include mobile {
        margin-top: 18px;
        margin-bottom: 30px;
        max-width: 100%;
    }
}

.program-main-article {
    margin-top: 80px;
    color: $primary;

    @media screen and (max-width: 1366px) {
        margin-top: 60px;
    }
}

.program-filters {
    width: 100%;
    display: flex;
    max-width: 60vw;
    flex-wrap: wrap;
    gap: 8px;
    z-index: 4;

    @include mobile {
        max-width: unset;
    }

    .news-filter__content {
        padding: 22px;
        border-radius: 18px;
        background-color: $primary;
        color: #fff;
        width: max-content;
        min-width: 100px;
        position: absolute;
        top: 52px;
        left: 0;
        transition: color 0.3s ease;
    }

    .news-select-item {
        &::before {
            content: '';
            height: 5px;
            width: 5px;
            background-color: $white;
            border-radius: 100%;
            position: absolute;
            left: -15px;
            top: 50%;
            transform: translate(-10px, -50%);
            opacity: 0;
            cursor: pointer;
            user-select: none;
            transition:
                transform 0.5s ease,
                opacity 0.5s ease;
        }

        @include hover {
            &::before {
                transform: translate(5px, -50%);
                opacity: 1;
            }
        }

        &.is-active {
            &::before {
                transform: translate(5px, -50%);
                opacity: 1;
            }
        }
    }
}

.program-detail-title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include tablet {
        align-items: flex-end;
    }

    @include mobile {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 70px;
    }
}

.program-detail-title-block-text {
    display: flex;
    flex-direction: column;
    width: 50%;

    @include mobile {
        width: 80%;
    }
}

.program-detail-360-btn,
.program-detail-presentation-btn {
    display: flex;
    gap: 40px;
    padding: 17px;
    border-radius: 18px;
    border: 1px solid #dcdcdd;
    align-items: center;
    cursor: pointer;
    transition: color 0.3s ease;

    @include small-laptop {
        padding: 15px;
        border-radius: 12px;
        .download-svg {
            width: 14px;
            height: 14px;
        }

        .pdf-svg {
            width: 23px;
            height: 27px;
        }
    }

    @include mobile {
        padding: 12px;
        gap: 22px;

        .download-svg {
            width: 12px;
            height: 12px;
        }
    }

    .download-svg {
        path {
            transition: stroke 0.3s ease;
        }
    }

    .pdf_svg__pdf-text {
        transition: fill 0.3s ease;
    }

    .pdf_svg__pdf-icon {
        transition: stroke 0.3s ease;
    }

    @include hover {
        color: $primary;

        .download-svg {
            path {
                stroke: $primary;
            }
        }

        .pdf_svg__pdf-text {
            fill: $primary;
        }

        .pdf_svg__pdf-icon {
            stroke: $primary;
        }
    }
}

.program-detail-360-btn {
    position: relative;

    path {
        transition: stroke 0.3s ease;
    }

    @include hover {
        .round-arrow-svg {
            path {
                stroke: $primary;
            }
        }
    }
}

.round-arrow-svg {
    position: absolute;
    top: 63%;
    left: 48%;
    transform: translate(-50%, -50%);

    svg {
        width: 43px;
        height: 18px;
    }
}

.program-detail-title-btns {
    display: flex;
    gap: 12px;

    @include laptop {
        gap: 8px;
    }

    @include tablet {
        margin-bottom: 80px;
    }

    @include mobile {
        margin-bottom: 0;
    }
}
