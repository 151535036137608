.stand-popup {
    z-index: 11;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition:
        opacity 0.3s ease,
        visibility 0.3s ease;

    &.popup--opened {
        visibility: visible;
        opacity: 1;
    }

    .popup-container {
        .stand-popup-content {
            pointer-events: auto;
        }
    }

    .constructor-btn {
        @include tablet {
            border-radius: 10px;
        }
    }

    .constructor-btn__text {
        @include tablet {
            font-size: 20px;
        }

        @include mobile {
            font-size: 15px;
        }
    }

    .stand-popup-booking-state-btn {
        --padding-x: 6px;
        --padding-y: 16px;
        --border-color-default: #{rgba($configurator-primary, 0.2)};
        border-radius: 16px;

        @include tablet {
            --padding-y: 10px;
            border-radius: 10px;
        }
    }

    .popup-overlay {
        display: none;

        @include mobile {
            display: block;
        }
    }
}

.stand-popup-content {
    --y-offset: 30px;
    --x-padding: 20px;
    --y-padding: 20px;
    position: fixed;
    z-index: 1;
    bottom: var(--y-offset);
    right: 25px;
    background-color: #fff;
    border-radius: 16px;
    width: 31vw;
    height: calc(100vh - var(--y-offset) * 2);
    max-height: calc(100svh - var(--y-offset) * 2);
    overflow-y: auto;
    @include small-scrollbar();
    overflow: hidden;
    overflow: clip;
    display: flex;
    flex-direction: column;

    @include large-desktop {
        width: 620px;
    }

    @include tablet {
        --y-offset: 16px;
        --x-padding: 12px;
        --y-padding: 12px;
        width: 50%;
        z-index: 2;
        right: var(--offset-x);
    }

    @include vertical-tablet {
        width: calc(100% - var(--offset-x) * 2);
    }

    .close-button-default {
        color: $configurator-primary;

        .close-button__icon {
            --size: 50px;
            border: 1px solid rgba($configurator-primary, 0.2);

            @include tablet {
                --size: 32px;
            }
        }
    }

    .stand-popup-info__gallery-block__btn {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
        display: inline-flex;
        align-items: center;

        .text-xxs {
            line-height: 0;
        }
    }
}

.stand-popup-content-inner {
    flex: 1 1 auto;
    overflow-x: auto;
    overflow-y: auto;
    @include small-scrollbar();
    padding: var(--y-padding) var(--x-padding);
    display: flex;
    flex-direction: column;
}

.stand-popup-top {
    margin-bottom: 50px;
    display: flex;
    align-items: center;

    @include tablet {
        margin-bottom: 40px;
    }

    .close-button {
        margin-left: auto;
    }
}

.stand-popup-top__title {
    font-size: 24px;
    letter-spacing: -0.05em;

    @include tablet {
        font-size: 23px;
    }
}

.stand-popup-booking__logo {
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 90px;
}

.stand-popup-booking__logo-img {
    max-height: 100%;
    width: auto;
    height: auto;
    flex-shrink: 0;
    min-width: 0;
    max-width: 100%;
    object-fit: contain;
}

.stand-popup-booking__info {
    display: flex;
    align-items: flex-end;
    gap: 20px;
    padding-bottom: 26px;
    margin-bottom: 32px;
    border-bottom: 1px solid rgba($configurator-primary, 0.1);
    color: $configurator-primary;
}

.stand-popup-booking__info-name {
    text-transform: uppercase;
    letter-spacing: -0.04em;
}

.stand-popup-booking__info-stand {
    margin-left: auto;
    flex-shrink: 0;
}

.stand-popup-booking__description {
    opacity: 0.5;
}

.stand-popup-booking__description-text {
    &:not(:last-child) {
        margin-bottom: 52px;
    }
}

.stand-popup-info__title {
    color: $primary-2;
    margin-bottom: 45px;
    font-size: 24px;
    letter-spacing: -0.05em;

    @include tablet {
        font-size: 23px;
        margin-bottom: 39px;
    }
}

.stand-popup-info__table {
    tbody {
        tr:not(:last-child) {
            border-color: rgba(#000, 0.1);
        }
    }

    td {
        &:first-child {
            opacity: 0.3;
        }
    }
}

.stand-popup-pagination-wrapper {
    margin-top: auto;
    padding-top: 40px;
}

.stand-popup-pagination {
    padding-top: 20px;
    border-top: 1px solid rgba($configurator-primary, 0.1);
    justify-content: space-between;

    @include tablet {
        padding-top: 15px;
    }
}

.stand-popup-booking-states__list {
    display: flex;
    gap: 4px;
    margin-bottom: 90px;

    @media screen and (max-width: 1680px) {
        margin-bottom: 50px;
    }

    @include tablet {
        margin-bottom: 36px;
    }
}

.stand-popup-booking-states__list-item {
    flex: 1 1 100%;
}

.stand-popup-info__table {
    margin-bottom: 20px;
}

.stand-popup-info__gallery-block {
    position: relative;
    margin-top: 40px;
}

.stand-popup-info__gallery-block__image {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 72.87%;
    border-radius: 16px;
    overflow: hidden;
    background-color: $light;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
