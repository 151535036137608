.privacy {
    padding-top: calc(var(--header-height) + 50px);

    @include tablet {
        padding-top: calc(var(--header-height) + 42px);
    }
    @include mobile {
        padding-top: calc(var(--header-height) + 20px);
    }
}

.privacy-content__title {
    width: 100%;
    text-align: start;
    margin-bottom: 60px;

    @include tablet {
        margin-bottom: 30px;
    }

    @include mobile {
        margin-bottom: 20px;
    }
}

.privacy-content {
    width: 80%;
    margin-inline: auto;
    margin-block: 80px;

    @include tablet {
        width: 90%;

        margin-block: 50px;
    }
    @include mobile {
        width: 95%;

        margin-block: 30px;
    }
}

.privacy-content__text {
    h4 {
        @include responsive-font-size(14px, 24px, 1280px, 1920px);
        font-weight: 400;
        line-height: 27px;
        letter-spacing: -0.72px;
    }
}
