.feedback-btn {
    --size: 80px;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    transition: background-color 0.3s ease;

    @media screen and (max-width: 1366px) {
        --size: 50px;

        svg {
            width: 25px;
            transition: fill 0.3s ease;
        }
    }
}

.feedback-btn.is-active {
    background-color: $primary;
}

.feedback-btn-wrapper {
    --offset: 40px;
    position: fixed;
    bottom: var(--offset);
    right: var(--offset);

    @media screen and (max-width: 1366px) {
        --offset: 24px;
    }

    @include mobile {
        display: none;
    }

    html.no-scroll & {
        right: calc(var(--offset) + var(--scrollbar-width, 0px));
    }

    &.feedback-theme--dark {
        .feedback-btn {
            background-color: $primary;
        }

        .feedback-btn_svg__feedback-cloud {
            fill: $white;
        }

        .feedback-btn_svg__feedback-dot {
            fill: $primary;
        }

        .feedback-link-btn.btn {
            color: $white;
            border-color: $primary;
            background-color: $primary;

            svg {
                path {
                    fill: $primary;
                }
            }

            &::after {
                background-color: $white;
            }
        }
    }

    &.feedback-theme--light {
        .feedback-btn {
            background-color: $white;
        }

        .cross_svg__feedback-cross {
            stroke: $primary;
        }

        .feedback-link-btn.btn {
            color: $primary;
            border-color: $white;
            background-color: $white;

            svg {
                path {
                    fill: $white;
                }
            }

            &::after {
                background-color: $primary;
            }
        }
    }
}

.feedback-links {
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-end;
    bottom: 140px;
    right: 40px;
    overflow: hidden;

    @media screen and (max-width: 1366px) {
        bottom: 86px;
        right: 24px;
    }
}
