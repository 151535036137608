.hotel-image {
    --height: 722px;

    width: 100%;
    border-radius: 18px;
    overflow: hidden;
    --aspect-ratio: calc(1030 / 600);
    position: relative;
    height: var(--height);
    transition: transform 0.25s ease-in-out;

    @include hover {
        img {
            transform: scale(1.05);
        }
    }

    img {
        transition: transform 0.25s ease-in-out; // Ensure smooth scaling back
    }

    @media screen and (max-width: 1366px) {
        --height: 487px;
        border-radius: 12px;
    }

    @include tablet {
        --height: 440px;
    }

    @include mobile {
        --height: 320px;
    }
}

.hotel-image__stars {
    --rate-btn-size: 42px;
    --offset: 20px;

    position: absolute;
    top: var(--offset);
    right: var(--offset);
    left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--rate-btn-size);
    height: var(--rate-btn-size);
    border-radius: 50%;
    background-color: #fff;
    font-family: $font-family;
    color: $primary;

    & svg {
        margin-top: -5px;
    }

    @media screen and (max-width: 1366px) {
        --rate-btn-size: 32px;
        --offset: 16px;
    }

    @include tablet {
        --offset: 12px;
    }

    @include mobile {
        --rate-btn-size: 26px;
        --offset: 10px;
    }
}

.hotel-image__content {
    --offset: 35px;

    position: absolute;
    left: var(--offset);
    bottom: var(--offset);
    top: auto;
    display: flex;
    flex-direction: column;
    color: $white;

    @media screen and (max-width: 1366px) {
        --offset: 25px;
    }

    @include tablet {
        --offset: 20px;
    }
}

.hotel-image__title {
    margin-bottom: 24px;

    @media screen and (max-width: 1366px) {
        margin-bottom: 15px;
    }

    @include mobile {
        margin-bottom: 14px;
    }
}
