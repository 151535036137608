.registration {
    --padding-top: 182px;
    --offset-block: 80px;
    --gap: 15px;
    --grid-gap: 20px;
    @include small-laptop {
        --padding-top: 122px;
    }
    @include tablet {
        --offset-block: 60px;
        --padding-top: 122px;
        --grid-columns: 8;
    }
    @include mobile {
        --padding-top: 100px;
        --offset-block: 40px;
        --grid-columns: 4;
    }

    .questions-section {
        --bg-color: #{$light-blue};
    }
}

.registration-section-wrapper {
    display: flex;
    z-index: 4;

    justify-content: space-between;
}

.registration-wrapper {
    width: 100%;
}

.registration-welcome {
    width: 100%;
    margin-block: var(--offset-block);
    display: flex;
    gap: var(--gap);

    @include tablet {
        display: flex;
        justify-content: space-between;
    }

    @include mobile {
        display: flex;
        flex-direction: column;
        gap: 64px;
        align-items: flex-start;
    }
}

.registration__title {
    grid-column: 1 / 6;

    @include tablet {
        grid-column: 1 / 4;
    }
}

.registration-lc__block {
    display: flex;
    gap: 50px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;

    @include grid-columns(4);

    @include small-laptop {
        gap: 35px;
    }

    @include tablet {
        @include recalculate-grid(8);
        @include grid-columns(4);

        justify-content: flex-end;
        gap: 12px;
    }

    @include mobile {
        @include recalculate-grid(4);
        align-items: flex-start;
        flex-direction: column;
        gap: 25px;
        margin-left: 0;
        justify-content: space-between;
    }
}

.registration-lc__item {
    width: fit-content;

    @include mobile {
        display: flex;
        @include grid-columns(3);
    }
}

.registration-categories__list {
    display: flex;
    gap: 15px;
    justify-content: center;

    margin-bottom: var(--offset-block);

    @include tablet {
        flex-wrap: wrap;
    }
}

.category-item {
    --offset-around: 40px;
    --categiry-item-width: calc(100% / 4 - var(--gap));
    width: var(--categiry-item-width);
    @include grid-columns(3);
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--offset-around);
    border-radius: 18px;
    background-color: $light;
    aspect-ratio: 30 / 29;

    transition:
        background-color 0.3s ease,
        color 0.3s ease;

    & .btn.btn-outline-dark {
        border-color: $primary;
        color: $primary;

        &::after {
            background-color: $primary;
        }
    }

    .category-item__link {
        color: #fff;
    }

    @include hover {
        background-color: $primary;
        color: #fff;

        .btn.btn-outline-dark {
            color: #fff;
            border: 1px solid #fff;

            svg path {
                fill: $primary;
            }
        }

        .category-item__link {
            opacity: 1;
        }

        .btn.category-item__btn {
            color: $primary;
            background-color: $white;

            svg path {
                fill: $white;
            }

            &::after {
                background-color: $primary;
            }
        }
    }

    @include small-laptop {
        --offset-around: 26px;
    }

    @include tablet {
        width: calc(100% / 2 - var(--gap));
        height: auto;
        gap: 60px;
        --offset-around: 30px;
        aspect-ratio: 185 / 178;

        .category-item__link {
            color: $primary;
        }
    }
    @include mobile {
        aspect-ratio: auto;
        width: 100%;
        gap: 40px;

        --offset-around: 22px;
    }
}

.category-item__text-block {
    display: flex;
    flex-direction: column;
    gap: calc(var(--gap) * 2);

    margin-bottom: var(--offset-around);
}

.category-item__title {
    max-width: 60%;

    @include mobile {
        max-width: 50%;
    }
}

.category-item__desc {
    max-width: 90%;
    opacity: 0.6;

    @include tablet {
        max-width: 75%;
    }
}

.category-item__btn-block {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--gap);

    @include tablet {
        color: $primary;

        .btn-outline-dark {
            color: $primary;
            border: 1px solid $primary;

            &::after {
                background-color: $primary;
            }
        }
    }
}

.category-item__link {
    display: flex;
    width: fit-content;
    min-height: 42px;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;

    @include tablet {
        opacity: 1;
    }
}
