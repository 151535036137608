.car-list-card__item {
    position: relative;
    border-radius: 18px;
    overflow: hidden;

    &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: $primary;
        opacity: 0;
        transition: opacity $app-default-duration $app-default-easing;
    }

    @include hover {
        &::before {
            opacity: 1;
        }
    }

    @media screen and (max-width: 1366px) {
        border-radius: 12px;
    }
}

.car-list-card {
    --height: 480px;
    width: 100%;
    overflow: hidden;
    --aspect-ratio: calc(1030 / 600);
    position: relative;
    height: var(--height);

    @media screen and (max-width: 1366px) {
        --height: 326px;
    }

    @include tablet {
        --height: 370px;
    }

    @media screen and (max-width: 575px) {
        --height: 223px;
    }
}

.car-list-card__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.car-list-card__content {
    --offset: 28px;
    position: absolute;
    z-index: 2;
    left: var(--offset);
    bottom: var(--offset);
    top: auto;
    display: flex;
    flex-direction: column;
    color: #ffffff;

    @media screen and (max-width: 575px) {
        --offset: 22px;
    }
}

.car-list-card__title {
    margin-bottom: 30px;
    @include grid-columns(1);

    @media screen and (max-width: 575px) {
        margin-bottom: 20px;
    }
}

.car-list-card__description {
    opacity: 0.6;
}
