.search-popup-content {
    display: flex;
    flex-direction: column;
}

.search-popup-form {
    position: relative;
    display: flex;
    margin-top: 64px;
    height: 58px;

    @include small-laptop {
        height: 42px;
        margin-top: 48px;
    }

    @include tablet {
        margin-top: 88px;
    }

    @include mobile {
        margin-top: 50px;
    }
}

.search-popup-form__input {
    --bg-color: #fff;
    --color: $body-color;
    --border-color: #{rgba($body-color, 0.15)};
    --padding-x: 30px;
    --border-radius: 0px;

    position: relative;
    bottom: 0;
    appearance: none;
    width: 100%;
    padding: 0;
    margin-top: auto;
    background-color: var(--bg-color);
    color: var(--color);
    border: 0;
    border-bottom: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    transition: border-color $app-default-duration $app-default-easing;
    padding: 10px 180px 10px 0;
    font-size: 24px;

    &:focus {
        outline: none;
        box-shadow: none;
        color: var(--color);
        border-color: currentColor;
    }

    &:-webkit-autofill {
        transition: none;
        caret-color: var(--color);
        border-color: var(--color);

        &,
        &:focus,
        &:active,
        &:hover {
            transition: background-color 9999s ease; // суперкостыль, чтобы пофиксить autofill в iOS
            color: var(--color) !important;
            -webkit-text-fill-color: var(--color) !important;
            box-shadow: inset 0 0 0 1000px var(--bg-color) !important;
        }
    }

    &::-webkit-credentials-auto-fill-button {
        visibility: hidden;
    }

    &::placeholder {
        opacity: 0.45;
        color: currentColor;
    }

    &:disabled {
        opacity: $btn-disabled-opacity;
        cursor: not-allowed;
        border-color: currentColor;

        ~ * {
            opacity: $btn-disabled-opacity;
        }
    }

    @include small-laptop {
        padding: 8px 180px 8px 0;

        font-size: 16px;
    }

    @include mobile {
        font-size: 18px;
    }
}

.search-popup-form-wrapper .search-popup-form__message.is-visible {
    font-size: 12px;
    margin-top: 5px;
}

.search-popup-form__message {
    color: $danger;
    position: absolute;
    transition:
        opacity 0.3s ease,
        transform 0.3s $app-default-easing;
    opacity: 0;
    transform: translateY(5px);

    &.is-visible {
        margin-top: 10px;
        opacity: 1;
        transform: none;
    }

    @include small-laptop {
        font-size: 14px;
    }
}

.search-popup-form__btns {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    gap: 10px;
}

.search-popup-form__btns .menu-popup-close-btn {
    width: 42px;
    height: 42px;

    @include small-laptop {
        width: 30px;
        height: 30px;
    }
}

.search-popup-form__btns .search-popup-form__btn {
    height: 42px;

    @include small-laptop {
        height: 30px;
        padding: 11px 16px;
        line-height: 1;
        min-height: auto;
        gap: 8px;
        &.btn.btn-outline-dark:not(:disabled):hover::after {
            width: 22px;
            height: 22px;
        }

        &.btn.btn-outline-dark:not(:disabled):hover .btn__arrow {
            height: 12px;
            transform: translate(-65%, -50%);
        }
    }
}

.search-popup-form__quick-search {
    margin-top: 100px;

    @include small-laptop {
        margin-top: 60px;
    }

    @include tablet {
        margin-top: 140px;
    }

    @include mobile {
        margin-top: 60px;
    }
}

.search-popup-form__quick-search-item {
    display: block;
    margin-bottom: 36px;
    color: rgba(33, 33, 35, 0.6);
    // cursor: pointer;

    em {
        color: $body-color;
        font-style: normal;
    }

    @include small-laptop {
        margin-bottom: 28px;
    }
}
