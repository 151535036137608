.dropzone-wrapper {
    overflow: hidden;
    border-radius: 12px;
    opacity: 0.5;

    &.is-active {
        opacity: 1;
    }
}

.dropzone {
    width: 100%;
    border-radius: 12px;
    padding: 21px;
    padding-right: 55px;
    border: 1px solid $border-color;
    outline: none;
    box-shadow: inset 100px 100px 100px 100px $white;
    resize: none;
    display: flex;
    position: relative;
    max-height: 100px;
    overflow: auto;

    @media screen and (max-width: 1600px) {
        padding: 16px;
        padding-right: 55px;
    }

    &::placeholder {
        color: $border-color;
    }

    transition: border-color 0.3s ease;

    @include hover {
        border-color: $dark;
    }

    &:focus {
        border-color: $dark;
    }

    &.is-error {
        border-color: $danger;
    }

    .dropzone-file-icon {
        position: absolute;
        right: 24px;
        top: 13px;
        width: 31px;
        height: 35px;

        @media screen and (max-width: 1600px) {
            width: 25px;
            height: 28px;
            right: 16px;
            top: 10px;
        }
    }
    @include small-scrollbar();
}

.dropzone-delete-icon {
    width: 24px;
    height: 24px;

    @media screen and (max-width: 1600px) {
        width: 19px;
        height: 19px;
    }
    path {
        stroke: #000;
    }
}

.dropzone-file-item {
    display: flex;
    gap: 14px;
    align-items: center;
    justify-content: space-between;
}

.dropzone-file-name {
    white-space: nowrap;
    width: 200px;
    max-width: 200px;
    text-overflow: ellipsis;

    @media screen and (max-width: 1600px) {
        width: 170px;
    }

    @include small-laptop {
        width: 150px;
    }
}

.dropzone-file-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
