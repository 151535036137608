.search-popup {
    .menu-popup-close-btn {
        --size: 42px;
        width: var(--size);
        height: var(--size);
        align-self: flex-end;
    }

    .popup-container {
        right: 0;
        background-color: $white;
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: column;
        padding: 50px 70px;
        gap: 60px;

        @include small-laptop {
            padding: 32px 36px;
        }

        @include tablet {
            padding: 22px;
            width: 100%;
        }

        @include mobile {
            padding: 16px;
        }
    }
}

.search-popup.popup {
    z-index: 25;
}

.organize-form-popup-title {
    color: $primary;
}

.organize-input-block {
    display: flex;
    flex-direction: column;
    gap: 18px;
    position: relative;

    .app-message {
        right: 0;
        top: 0;
        left: auto;
        color: #da3f3f;
    }
}

.organize-form-popup-input {
    width: 100%;
    border-radius: 12px;
    padding: 24px;
    border: 1px solid #dcdcdd;
    outline: none;
    box-shadow: inset 100px 100px 100px 100px $white;

    &::placeholder {
        color: #dcdcdd;
    }

    transition: border-color 0.3s ease;

    @include hover {
        border-color: $dark;
    }

    &:focus {
        border-color: $dark;
    }

    &.is-error {
        border-color: #da3f3f;
    }
}

.organize-form-popup-form {
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;

    .checkbox {
        @include mobile {
            padding-right: 0;
        }
    }
}
