.free-events__section {
    min-height: 100vh;
    min-height: 100svh;
    width: 100%;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $dark;
    padding-top: calc(var(--header-height) + 80px);
    padding-bottom: 80px;
    gap: 90px;
    --padding: 112px;
    background-color: $light-blue;
    position: relative;

    .swiper-wrapper {
        transition-timing-function: easeInOutCubic;
    }

    @media screen and (max-width: 1366px) {
        gap: 60px;
    }

    @include tablet {
        min-height: unset;
        gap: 80px;
        padding-bottom: var(--padding);
        padding-top: var(--padding);
    }

    @include mobile {
        --padding: 80px;
    }
}

.free-events-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include mobile {
        flex-direction: column;
        gap: 24px;
    }
}

.free-events-title {
    @include grid-columns(4);
    max-width: 520px;

    @media screen and (max-width: 1600px) {
        @include grid-columns(3.5);
    }

    @include small-laptop {
        @include grid-columns(4);
        padding-right: 20px;
    }

    @include mobile {
        width: 100%;
    }
}

.free-events-description-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    gap: 30px;
    @include grid-columns(4);

    @include tablet {
        width: 50%;

        flex-direction: column;
        gap: 40px;
        @include grid-offset(padding-right, 2);
        align-items: flex-start;
    }

    @include mobile {
        gap: 30px;
        width: 100%;
        padding-right: 0;
    }
}

.free-events-section-btn {
    width: fit-content;
}

.free-events-slider {
    width: 100%;
    cursor: grab;
    margin-bottom: 80px;

    @include small-laptop {
        margin-bottom: 40px;
    }

    @include tablet {
        margin-bottom: 80px;
    }

    @include mobile {
        margin-bottom: 32px;
    }
}

.free-event-card__slide.swiper-slide {
    height: auto;
}

.free-event-card__wrapper {
    display: flex;
    flex-direction: row;
    gap: 32px;
    padding: 60px 50px;
    background-color: $white;
    border-radius: 18px;
    overflow: hidden;
    height: 100%;

    @include small-laptop {
        padding: 40px 32px;
    }

    @include mobile {
        padding: 20px;
    }
}

.free-event-card__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
}

.free-event-card__content-block--top {
    @include mobile {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }
}

.free-event-card__content-wrapper {
    display: flex;
    flex-direction: column;
}

.free-event-card__image-wrapper {
    border-radius: 14px;
    width: 104px;
    overflow: hidden;
    --aspect-ratio: calc(230 / 330);

    height: auto;

    min-width: 260px;

    @media screen and (max-width: 1366px) {
        min-width: 164px;
        --aspect-ratio: calc(154 / 220);
    }

    @include tablet {
        border-radius: 10px;

        min-width: 206px;
    }

    @include mobile {
        --aspect-ratio: 1 / 1;

        min-width: 104px;
        display: flex;
        --aspect-ratio: 1;
        position: relative;
        border-radius: 8px;
    }
}

.free-event-card__date {
    color: $primary;
    margin-bottom: 27px;

    @include small-laptop {
        margin-bottom: 20px;
    }

    @include mobile {
        margin-bottom: 16px;
    }
}
