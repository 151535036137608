.about__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding-block: 80px;

    @include tablet {
        padding-block: 112px;
    }

    @include mobile {
        padding-block: 80px;
        flex-direction: column;
    }
}

.about__title {
    @include grid-columns(3);

    @include small-laptop {
        @include grid-columns(4);
    }

    @include mobile {
        width: 100%;
        margin-bottom: 48px;
    }
}

.about__description {
    width: 50%;
    font-weight: 400;
    letter-spacing: -0.63px;

    @include mobile {
        width: 100%;
    }
}
