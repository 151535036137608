.awords__section {
    padding-block: var(--padding-section);
    overflow: hidden;
    background-color: $light;
    position: relative;
    z-index: 4;
}

.awords__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.awords__docs {
    .section-docs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.awords__cards {
    margin-top: 82px;
    display: flex;
    flex-direction: column;

    .collapse {
        --collapse-duration: 0.65s;

        @include mobile {
            --collapse-duration: 0.5s;
        }
    }
}

.awords__card {
    padding-block: 30px;
    width: 100%;
    text-align: start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include hover {
        .aword__card-title {
            opacity: 1;
            color: $primary;
        }

        .aword__card-description {
            opacity: 1;
        }
    }

    @include tablet {
        text-align: start;
    }
}

.aword__card-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    position: relative;
    width: 100%;

    @include tablet {
        gap: 60px;
    }

    @include mobile {
        flex-direction: column;
        gap: 22px;
        width: 100%;
        align-items: flex-start;
    }
}

.aword__card-title {
    height: fit-content;
    opacity: 0.4;
    transition: opacity 0.3s ease-in-out;
    margin-top: auto;

    &.text-xl {
        line-height: 1;
        @include small-laptop {
            line-height: 1.05;
        }
        @include tablet {
            line-height: 1.1;
        }
    }

    @include grid-columns(4);

    @include tablet {
        text-align: start;
        @include grid-columns(6);
    }

    @include mobile {
        @include grid-columns(10);
    }
}

.aword__card-description {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    @include grid-columns(5);
    @include grid-offset(padding-right, 1);

    @include tablet {
        @include grid-offset(padding-right, 0.5);

        text-align: left;
    }

    @include mobile {
        padding-right: 0;

        @include grid-columns(11);
    }
}

.awords__cards {
    width: 100%;

    .collapse__toggler {
        width: 100%;
        align-items: start;
        gap: 0;
    }

    .faq-btn {
        opacity: 0.4;
    }

    .collapse {
        border-top: 2px solid $gray;

        &.collapse--opened {
            .aword__card-title {
                opacity: 1;
                color: $primary;
            }
            .aword__card-description {
                opacity: 1;
            }

            .faq-btn {
                transform: rotate(-90deg);
                opacity: 1;
                border-color: $primary;
                svg path {
                    fill: $primary;
                }
            }
        }
    }

    &:last-child {
        border-bottom: 2px solid $gray;
    }
}

.aword__card-btn__wrapper {
    height: 100%;
    align-items: flex-start;
    display: flex;
}
