.news-detail-page-top {
    .blockquote-img-wrapper {
        background: linear-gradient(180deg, #05164a, #082e74 78.81%);
    }
}

.news-detail-content-wrapper {
    overflow: hidden;
    overflow: clip;
}

.news-detail-content {
    padding-top: 120px;
    padding-bottom: 50px;
    display: flex;
    gap: var(--grid-gap);

    @include tablet {
        padding-top: 90px;
        padding-bottom: 80px;
        display: block;
    }

    @include mobile {
        padding-top: 73px;
        padding-bottom: 50px;
    }
}

.news-detail-content__left {
    flex-shrink: 0;
    @include grid-columns(2);
    padding-right: 50px;

    @include tablet {
        width: 100%;
        padding-right: 0;
        margin-bottom: 50px;
    }
}

.news-detail-content__left-content {
    --gap: 12.5vw;
    position: sticky;
    top: calc(var(--header-height) + 30px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: Min(var(--gap), 25vh);

    @media screen and (min-width: 1921px) {
        --gap: 240px;
    }

    @media screen and (max-width: 1366px) {
        --gap: 280px;
    }

    @include tablet {
        position: static;
        flex-direction: row;
        gap: 20px;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
    }
}

.news-detail-web-share {
    @include tablet {
        margin-left: auto;
        flex-direction: row;
        display: flex;
        align-items: center;
        gap: 12px;
    }

    @media screen and (max-width: 576px) {
        margin-left: 0;
    }
}

.news-detail-content__right {
    flex: 1 1 auto;
    width: 100%;
    min-width: 0;
    @include grid-offset(padding-right, 2);

    @include tablet {
        padding-right: 0;
    }

    .constructor-gallery__embla-wrapper {
        --add-x: calc(var(--grid-column-width) + var(--grid-gap) + var(--offset-x));

        @media screen and (max-width: 767px) {
            --add-x: var(--offset-x);
        }
    }

    .constructor-gallery__slider-slide {
        --slide-offset: 0px;

        @include tablet {
            --slide-offset: calc(var(--grid-column-width) + var(--offset-x) + var(--grid-gap));
        }

        @include mobile {
            --slide-offset: var(--offset-x);
        }

        &:first-child:not(:last-child) {
            padding-left: var(--slide-offset);
            width: calc(var(--slide-size) + var(--slide-offset));
        }

        &:last-child:not(:first-child) {
            padding-right: var(--slide-offset);
            width: calc(var(--slide-size) + var(--slide-offset));

            @media screen and (min-width: 1200px) {
                --slide-offset: calc(var(--grid-column-width) + var(--offset-x) + var(--grid-gap));
            }

            @include tablet {
                padding-right: 0;
                width: var(--slide-size);
            }
        }
    }
}

.news-detail-content__top {
    display: flex;
    flex-direction: column;
    opacity: 0.6;
}

.news-detail-content__subscribe-block {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .btn {
        width: fit-content;
    }

    @media screen and (max-width: 1366px) {
        gap: 24px;
    }

    @include tablet {
        display: none;
    }
}

.news-detail-content__bottom {
    display: flex;
    flex-direction: column;
    gap: 80px;
}

.news-detail-content-title,
.news-detail-content-description {
    margin-bottom: 90px;

    @media screen and (max-width: 1400px) {
        margin-bottom: 70px;
    }

    @include mobile {
        margin-bottom: 40px;
    }
}

.news-detail-wrapper {
    @include tablet {
        width: calc(100% - 40px * 2);
        transform: translateX(40px);
    }

    @include mobile {
        width: 100%;
        transform: none;
    }
}
