.services-content {
    display: grid;
    grid-template-columns: repeat(var(--grid-columns), 1fr);
    gap: 15px;
    margin-bottom: 90px;

    @media screen and (max-width: 1366px) {
        margin-bottom: 60px;
    }

    @include tablet {
        margin-bottom: 112px;
    }

    @media screen and (max-width: 767px) {
        grid-row-gap: 64px;
        grid-template-columns: 1fr;
        margin-bottom: 80px;
    }
}

.services-content__title {
    grid-column: 1 / 6;

    @media screen and (max-width: 767px) {
        grid-column: 1 / -1;
    }
}
.services-content__btn-text {
    max-width: 160px;
    text-wrap: initial;
}
.services-content__content {
    grid-column: 7 / -1;

    @media screen and (max-width: 767px) {
        grid-column: 1 / -1;

        & * {
            text-wrap: auto;
        }

        & .download-button {
            width: fit-content;
        }
    }

    @media screen and (min-width: 768px) {
        @include grid-columns(5);
        padding-right: 20px;
    }

    &:has(.download-button) .services-content__content-inner {
        margin-bottom: 80px;

        @include tablet {
            margin-bottom: 50px;
        }

        @include mobile {
            margin-bottom: 40px;
        }
    }
}

.services-content__content-inner {
    & div > *:not(p) {
        margin-bottom: 60px;
        text-wrap: balance;

        @media screen and (max-width: 1366px) {
            margin-bottom: 40px;
        }

        @media screen and (max-width: 767px) {
            margin-bottom: 30px;
        }
    }

    p:not(:last-child) {
        margin-bottom: 60px;

        &:has(+ ul) {
            margin-bottom: 30px;

            @media screen and (max-width: 1366px) {
                margin-bottom: 24px;
            }
        }

        @media screen and (max-width: 1366px) {
            margin-bottom: 40px;
        }

        @media screen and (max-width: 767px) {
            margin-bottom: 30px;
        }
    }

    & ul {
        padding-left: 20px;
        & li {
            padding-left: 10px;
            margin-bottom: 16px;
            &::marker {
                color: $primary;
            }
        }
    }
}
