@include configurator() {
    .pavilion-bg,
    .pavilion-bg-filter {
        transition: opacity 0.5s ease-in-out;
        will-change: opacity;
        opacity: 0;
    }

    .pavilion-bg {
        fill: $primary;
    }

    .pavilion-bg-filter {
        fill: $light-blue;
    }

    .pavilion-name,
    .pavilion-name--selected,
    .pavilion-space,
    .pavilion-space--selected {
        transition: opacity 0.5s ease-in-out;
    }
    .pavilion-name--selected,
    .pavilion-space--selected {
        opacity: 0;
    }

    .pavilion-space {
        opacity: 0.3;
    }

    .map-svg-wrapper {
        flex-shrink: 0;
        margin: auto;
        width: 100%;
        max-width: 2560px;

        @include tablet {
            margin: 0;
            overflow-y: auto;
            @include hidden-scrollbar;
            width: calc(100% + var(--offset-x) * 2);
            margin-left: calc(-1 * var(--offset-x));
        }

        @include mobile {
            height: 50vh;
            height: 50svh;
        }
    }

    .props-btn {
        width: 100%;
        --padding-y: 23px;

        @include laptop {
            --padding-y: 15px;
        }

        @include mobile {
            --padding-y: 23px;
        }
    }

    .pavilion-map {
        width: 100%;
        pointer-events: none;

        @include tablet {
            height: 100%;
            width: auto;
            padding-inline: var(--offset-x);
        }

        @include horizontal-mobile {
            width: 100%;
        }

        &.current-space-9 {
            .s-9 .pavilion-bg-filter {
                opacity: 1;
            }

            .pavilion:not(.s-9) .pavilion-bg-filter {
                opacity: 0;
            }
        }
        &.current-space-12 {
            .s-12 .pavilion-bg-filter {
                opacity: 1;
            }

            .pavilion:not(.s-12) .pavilion-bg-filter {
                opacity: 0;
            }
        }
        &.current-space-18 {
            .s-18 .pavilion-bg-filter {
                opacity: 1;
            }

            .pavilion:not(.s-18) .pavilion-bg-filter {
                opacity: 0;
            }
        }
        &.current-space-30 {
            .s-30 .pavilion-bg-filter {
                opacity: 1;
            }

            .pavilion:not(.s-30) .pavilion-bg-filter {
                opacity: 0;
            }
        }
        &.current-space-40 {
            .s-40 .pavilion-bg-filter {
                opacity: 1;
            }

            .pavilion:not(.s-40) .pavilion-bg-filter {
                opacity: 0;
            }
        }

        &.current-type-standart {
            .type-s .pavilion-bg-filter {
                opacity: 1;
            }

            .pavilion:not(.type-s) .pavilion-bg-filter {
                opacity: 0;
            }
        }
        &.current-type-individual {
            .type-i .pavilion-bg-filter {
                opacity: 1;
            }

            .pavilion:not(.type-i) .pavilion-bg-filter {
                opacity: 0;
            }
        }
        &.current-type-premium {
            .type-p .pavilion-bg-filter {
                opacity: 1;
            }

            .pavilion:not(.type-p) .pavilion-bg-filter {
                opacity: 0;
            }
        }
    }

    .pavilion {
        cursor: pointer;
        pointer-events: all;
        &.is-active {
            .pavilion-name {
                opacity: 0;
            }

            .pavilion-name--selected {
                opacity: 1;
            }

            .pavilion-space {
                opacity: 0;
            }

            .pavilion-space--selected {
                opacity: 0.3;
            }

            .pavilion-bg {
                opacity: 1;
            }
        }
    }

    &.map-filters-popup {
        .popup-container {
            width: 360px;
            height: fit-content;
            top: unset;
            left: unset;
            right: var(--offset-x);
            bottom: var(--offset-x);
            background-color: #fff;
            border-radius: 8px;

            @include mobile {
                width: calc(100% - var(--offset-x) * 2);
            }
        }
    }

    &.map-detail-popup {
        .popup-container {
            width: calc(100% - var(--offset-x) * 2);
            height: fit-content;
            top: unset;
            left: unset;
            right: var(--offset-x);
            bottom: var(--offset-x);
            background-color: #fff;
            border-radius: 8px;
        }

        .close-button-default {
            color: $dark-blue;

            .close-button__icon {
                border: 1px solid rgba($dark-blue, 0.2);
            }
        }

        .map-popup-inner {
            flex-direction: column;
        }

        .map-detail-popup-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .close-button__icon {
            --size: 32px;
        }

        .mobile-props-btn {
            order: 2;
        }

        .props-btn {
            --padding-y: 16px;
        }
    }

    .pavilion-hint {
        opacity: 0.5;
    }

    .pavilion-tour-btn {
        align-self: flex-start;
        margin-top: auto;
        position: absolute;
        bottom: 30px;

        @include tablet {
            bottom: 16px;
        }

        @include mobile {
            --size: 40px;
            transform: none;
            position: relative;
            bottom: auto;
            padding-block: 16px;
            width: var(--size);
            height: var(--size);
            padding: 0;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            border-radius: 8px;

            &,
            &.constructor-btn {
                min-height: 0;
            }
        }

        &.constructor-btn {
            @extend .text-s;
        }

        .constructor-btn__icon {
            @include mobile {
                &:not(:last-child) {
                    margin-right: 0;
                }

                position: relative;
                left: 1px;
            }
        }

        .constructor-btn__text {
            @include mobile {
                display: none;
            }
        }
    }
}

.inhouse-map-bottom {
    display: flex;
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 30px;

    @include tablet {
        padding-bottom: 16px;
    }
}

.map-camera-view-controls {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 16px;

    @include laptop {
        padding: 12px;
        gap: 12px;
    }

    @include tablet {
        padding: 0;
    }
}

.inhouse-map-camera-view-controls {
    --camera-view-controls-border-radius: 16px;
    display: flex;
    background-color: #fff;
    border-radius: var(--camera-view-controls-border-radius);
    overflow: hidden;
    overflow: clip;
    gap: 4px;

    @media screen and (max-width: 1366px) {
        --camera-view-controls-border-radius: 10px;
    }

    @include tablet {
        bottom: 16px;
    }
}

.inhouse-map-camera-view-control {
    border-radius: var(--camera-view-controls-border-radius);
    border: 1px solid rgba(#c4c4c4, 0.5);
    height: 60px;
    padding-inline: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $configurator-primary;
    background-color: #fff;
    @include responsive-font-size(12px, 16px, 1280px, 1920px);
    letter-spacing: -0.04em;
    line-height: 1.33;
    transition:
        color 0.3s ease,
        background-color 0.3s ease;

    @media screen and (max-width: 1366px) {
        height: 40px;
        padding-inline: 24px;
    }

    &:not(.is-active) {
        @include hover {
            background-color: rgba($configurator-primary, 0.5);
            border-color: transparent;
            color: #fff;
        }

        &:active {
            color: rgba($configurator-primary, 0.4);
        }
    }

    &.is-active {
        color: #fff;
        background-color: $configurator-primary;

        @include hover {
            background-color: darken($configurator-primary, 5%);
        }

        &:active {
            background-color: darken($configurator-primary, 8%);
        }
    }
}
