.participants-section {
    min-height: 100vh;
    min-height: 100svh;
    width: 100%;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $white;
    padding-top: calc(var(--header-height) + 40px);
    padding-bottom: 80px;
    gap: 120px;
    --padding: 112px;
    position: relative;

    @include tablet {
        min-height: unset;
        background-color: $blue;
        padding-bottom: var(--padding);
        padding-top: var(--padding);
        gap: 80px;
    }

    @include mobile {
        --padding: 80px;
        gap: 64px;
    }

    .swiper-wrapper {
        transition-timing-function: easeInOutCubic;
    }
}

.participants-top {
    display: flex;
    justify-content: space-between;
}

.participants-bottom {
    display: flex;
    flex-direction: column;
    gap: 60px;

    @include mobile {
        gap: 32px;
    }
}

.participants-title {
    @include grid-columns(2, max-width);
}

.participants-description-block {
    display: flex;
    align-items: end;
    gap: 85px;
}

.participants-description {
    max-width: 350px;
}

.participants-slider {
    width: 100%;
    min-height: 490px;
    cursor: grab;

    @include mobile {
        min-height: unset;
    }

    &:active {
        cursor: grabbing;
    }
}

.participants-slider__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 83px;

    @include tablet {
        gap: 12px;
    }
}

.participants-slider-slide {
    max-width: 216px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
}

.participants-section[data-gradient-bg='light'] {
    .participants-slider-img-wrapper {
        background-image: linear-gradient(180deg, #dfe1e3 21.59%, #f2f2f2 100%);
    }
}

.participants-slider-img-wrapper {
    position: relative;
    border-radius: 18px;
    overflow: hidden;
    --aspect-ratio: calc(216 / 290);
    background: linear-gradient(180deg, #05164a 0%, #082e74 78.81%);
}

.participant-slide-name {
    margin-top: 35px;
    text-align: start;
    width: 50%;

    @include mobile {
        font-size: 14px;
        margin-top: 24px;
    }
}

.participant-slide-job {
    margin-top: 21px;
    text-align: start;
    opacity: 0.6;

    @include mobile {
        margin-top: 8px;
    }
}

.participants-slider-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.participants-slider-next-btn,
.participants-slider-prev-btn {
    --size: 48px;
    width: var(--size);
    height: var(--size);
    border: 1px solid $white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:
        background-color 0.3s ease,
        transform 0.3s ease;
    cursor: pointer;

    @include tablet {
        --size: 30px;

        svg {
            width: 12px;
        }
    }

    @include hover {
        background-color: $white;

        path {
            fill: $dark-blue;
        }

        &:active {
            transform: scale(0.95);
        }
    }

    path {
        transition: fill 0.3s ease;

        fill: $white;
    }
}

.participants-slider-prev-btn {
    svg {
        transform: rotate(180deg);
    }
}
