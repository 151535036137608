.partners-section {
    min-height: 100vh;
    min-height: 100svh;
    width: 100%;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $white;
    padding-top: calc(var(--header-height) + 140px);
    padding-bottom: 80px;
    --padding: 112px;

    @media screen and (max-width: 1366px) {
        padding-top: calc(var(--header-height) + 80px);
    }

    @include tablet {
        padding-bottom: var(--padding);
        padding-top: var(--padding);
    }

    @include mobile {
        --padding: 80px;
    }
}

.partners-top {
    display: flex;
    justify-content: space-between;
    padding-left: 50vw;
    gap: 50px;

    @include tablet {
        flex-direction: column;
        gap: 40px;
    }

    @include mobile {
        padding-left: 100px;
    }
}

.partners-center {
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.partners-title {
    @include grid-columns(3.2, max-width);

    @media screen and (max-width: 1366px) {
        max-width: 100%;
    }
}
.partners-description-block {
    display: flex;
    align-items: end;
    gap: 85px;
}

.partners-description {
    max-width: 350px;
}

.partners-card {
    width: 280px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: 1366px) {
        width: 186px;
        height: 108px;
    }

    path {
        transition: fill 0.3s ease;
    }

    @include hover {
        path {
            fill: $primary;
        }
    }
}

.partners-card__img {
    min-width: 53px;
    max-width: 95%;
    min-height: 56px;
    max-height: 80%;
    width: 100%;
    height: auto;
    object-fit: contain;
}

.partners-cards {
    display: flex;
    width: 100%;
    height: 160px;
    gap: 20px;
}

.partners-bottom {
    width: 100%;
}

.partners-bottom-text {
    @include grid-columns(3, max-width);
    margin-left: calc(50vw - var(--offset-x));

    @media screen and (max-width: 1366px) {
        @include grid-columns(4, max-width);
    }

    @include mobile {
        margin-left: calc(100px - var(--offset-x));
        max-width: 100%;
    }
}

.partners-center {
    .rfm-marquee {
        min-width: fit-content;
    }
}
