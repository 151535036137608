.navigation-pages__list {
    display: flex;
    gap: 50px;
    padding: 25px 0px;
    position: relative;

    @media screen and (max-width: 1366px) {
        gap: 30px;
        padding: 20px 0px;
    }

    @include tablet {
        overflow-x: auto;
        overflow-y: hidden;
        @include hidden-scrollbar;
    }

    border-bottom: 1px solid $gray;
}

.navigation-pages__item {
    position: relative;
    transition: color 0.3s ease;
    cursor: pointer;
    padding-left: 16px;
    display: flex;
    align-items: center;
    white-space: nowrap;

    &:last-child {
        @include tablet {
            padding-right: var(--offset-x);
        }
    }

    &::before {
        content: '';
        height: 5px;
        width: 5px;
        background-color: $primary;
        border-radius: 100%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-10px, -50%);
        opacity: 0;
        transition:
            transform 0.5s ease,
            opacity 0.5s ease;
    }
}

.navigation-pages__item.is-active {
    color: $primary;

    &::before {
        transform: translate(0, -50%);
        opacity: 1;
    }
}
